<template>
    <div>
        <b-sidebar
            id="structure-sidebar-right"
            :class="['sidebar-add-workcenter-unit']"
            right
            bg-variant="white"
            width="407px"
            v-model="isOpen"
            @hidden="closeModal"
        >
            <template #header>
                <div class="header-sidebar d-flex justify-content-between">
                    <span class="sidebar-title">
                        {{ $t(modalTitle) }}
                    </span>

                    <span
                        class="close-icon"
                        @click="closeModal()"
                    >
                        <CloseIcon />
                    </span>
                </div>

                <div class="separator"></div>
            </template>
            <template>
                <b-col md="12">
                    <b-form-group
                        :label="$t('DepartmentPage.Name')"
                        label-for="name"
                        :invalid-feedback="
                            flagNameEqual
                                ? $t('StructurePage.EqualWorkcenterName')
                                : $t('RequiredField')
                        "
                    >
                        <b-form-input
                            id="name"
                            v-model="nameInput"
                            :placeholder="$t('ResourceStatus.Placeholder')"
                            :state="states.name"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group
                        :label="$t('ResourceStatus.Code')"
                        label-for="code"
                        :invalid-feedback="
                            flagCodeEqual ? $t('CodeAlreadyExists') : $t('RequiredField')
                        "
                    >
                        <b-form-textarea
                            id="code"
                            v-model="code"
                            :placeholder="$t('ResourceStatus.Placeholder')"
                            :state="states.code"
                            no-resize
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    md="12"
                    class="form-group"
                >
                    <label for="functions-select">
                        {{ $t("StructurePage.Function") }}
                    </label>
                    <SingleSelect
                        id="functions-select"
                        :placeholder="$t('Select')"
                        :optionSelected="functions"
                        :options="
                            rolesList.map((item) => ({
                                value: item.id,
                                text: this.rolesList.find((f) => f.id === item.id).name
                            }))
                        "
                        :searchable="false"
                        :translate="true"
                        :state="states.functions"
                        ref="multiSelectFunctions"
                        :multiselect="true"
                        class="chip-functions"
                        @input="(value) => (functions = value)"
                    >
                        {{ $t("RequiredField") }}
                    </SingleSelect>
                </b-col>
                <div v-if="currentCustomFields.length && isOpen">
                    <div class="topic-title col-md-12">{{ $t("StructurePage.CustomFields") }}</div>
                    <div
                        v-for="field in displayImportantFirst"
                        :key="field.id"
                    >
                        <custom-fields
                            :field="field"
                            :hasError="states.customFieldsSitesForceError"
                            @input="
                                (value) => {
                                    field.value = value ? value : null;
                                }
                            "
                        />
                    </div>
                </div>
                <div v-if="settingsFields.length && isOpen">
                    <div class="topic-title col-md-12">{{ $t("StructurePage.Settings") }}</div>
                    <div
                        v-for="field in displayImportantFirstSettings"
                        :key="field.id"
                        class="check-button"
                    >
                        <custom-fields
                            :field="field"
                            :hasError="states.customFieldsSettingsForceError"
                            @input="
                                (value) => {
                                    field.value = value ? value : null;
                                }
                            "
                        />
                    </div>
                </div>
                <b-col
                    md="12"
                    class="form-group"
                >
                    <div class="topic-title">{{ $t("Breadcrumbs.OrganizationalStructure") }}</div>
                    <label for="unit-select">
                        {{ $t("StructurePage.Unit") }}
                    </label>
                    <SingleSelect
                        id="unit-select"
                        :placeholder="$t('Select')"
                        :optionSelected="unitList.find((item) => item.value == unit)"
                        :options="unitList"
                        :searchable="false"
                        :translate="true"
                        :state="states.unit"
                        @input="(value) => (unit = value ? value.value : value)"
                    >
                        {{ $t("RequiredField") }}
                    </SingleSelect>
                    <label
                        for="group-select"
                        class="group-label"
                    >
                        {{ $t("StructurePage.Group") }}
                    </label>
                    <SingleSelect
                        id="group-select"
                        :placeholder="$t('Select')"
                        :optionSelected="groupList.find((item) => item.value == group)"
                        :options="groupList"
                        :searchable="false"
                        :translate="true"
                        :state="states.group"
                        @input="(value) => (group = value ? value.value : value)"
                    >
                        {{ $t("RequiredField") }}
                    </SingleSelect>
                </b-col>
            </template>

            <template #footer>
                <b-col
                    md="12"
                    class="footer-sidebar"
                >
                    <div class="d-flex">
                        <b-button
                            class="btn-cancel btn-footer"
                            @click="closeModal()"
                        >
                            {{ $t("RefuseStatus.Cancel") }}
                        </b-button>
                        <b-button
                            class="btn-save btn-footer"
                            @click="addWorkCenter()"
                        >
                            {{ $t("RefuseStatus.Save") }}
                        </b-button>
                    </div>
                </b-col>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import {
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BButton,
        BRow,
        BCol,
        BFormCheckbox,
        BSidebar
    } from "bootstrap-vue";
    import { mapState, mapMutations } from "vuex";
    import CustomFields from "@core/components/customFields/index.vue";
    import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
    import { handleCreateParametersForPayload, handleFillCustomFields } from "@/utils/customFields";

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BButton,
            BRow,
            BCol,
            BFormCheckbox,
            BSidebar,
            CustomFields,
            CloseIcon: () => import("@core/assets/icons/close-icon.svg"),
            SingleSelect: () => import("@core/components/multiselect/SingleSelect.vue")
        },
        props: {
            currentId: {
                type: Number,
                default: null
            },
            modalTitle: {
                type: String,
                default: ""
            },
            customFields: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                isOpen: false,
                nameInput: "",
                code: "",
                unit: 0,
                group: 0,
                functions: [],
                unitList: [],
                groupList: [],
                settingsFields: [],
                currentCustomFields: [],
                prefix: localStorage.getItem("prefix"),
                flagNameEqual: false,
                flagCodeEqual: false,
                states: {
                    name: null,
                    code: null,
                    functions: null,
                    unit: null,
                    group: null,
                    customFieldsSitesForceError: null,
                    customFieldsSettingsForceError: null
                }
            };
        },
        mounted() {
            this.UPDATE_MODAL_WORKCENTER_TOGGLE(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("structures", ["UPDATE_MODAL_WORKCENTER_TOGGLE"]),
            activeCarousel() {
                const vm = this;
                $(function () {
                    document
                        .getElementsByClassName("chip-functions")[0]
                        .setAttribute("tabIndex", "-1");

                    const slider = document.querySelector(".multiselect__tags");
                    let mouseDown = false;
                    let startX, scrollLeft;

                    let startDragging = function (e) {
                        mouseDown = true;
                        startX = e.pageX - slider.offsetLeft;
                        scrollLeft = slider.scrollLeft;
                    };
                    let stopDragging = function () {
                        mouseDown = false;
                    };

                    slider.addEventListener("mousemove", (e) => {
                        e.preventDefault();
                        if (!mouseDown) {
                            return;
                        }
                        const x = e.pageX - slider.offsetLeft;
                        const scroll = x - startX;
                        slider.scrollLeft = scrollLeft - scroll;
                    });

                    slider.addEventListener("mousedown", startDragging, false);
                    slider.addEventListener("mouseup", stopDragging, false);
                    slider.addEventListener("mouseleave", stopDragging, false);

                    $(".multiselect__tags").blur((event) => {
                        let input = vm.$refs.multiSelectResponsible;
                        input.addTag(event.target.value);
                    });
                });
            },
            separeBySystem(arr) {
                let settings = [];
                let customFields = [];
                arr.map((e) => {
                    if (e.system) {
                        settings.push(e);
                    } else {
                        customFields.push(e);
                    }
                });
                this.settingsFields = settings;
                this.currentCustomFields = customFields;
            },
            closeModal() {
                this.UPDATE_MODAL_WORKCENTER_TOGGLE(false);
            },
            clearFields() {
                this.nameInput = "";
                this.code = "";
                this.functions = [];
                this.unit = 0;
                this.group = 0;
                this.customFields.map((e) => (e.value = null));
            },
            clearValidations() {
                this.states.name = null;
                this.states.code = null;
                this.states.functions = null;
                this.states.unit = null;
                this.states.group = null;
                this.states.customFieldsSitesForceError = null;
                this.states.customFieldsSettingsForceError = null;
            },
            fieldsValidation() {
                this.clearValidations();
                const validName = (this.states.name =
                    this.nameInput && this.flagNameEqual === false ? true : false);

                const validCode = (this.states.code =
                    this.code && this.flagCodeEqual === false ? true : false);

                const validFunctions = (this.states.functions = this.functions.length > 0);

                const validUnit = (this.states.unit =
                    this.unit != "" && this.unit != 0 && this.unit !== null);

                const validGroup = (this.states.group =
                    this.group != "" && this.group != 0 && this.group !== null);

                const requiredFields = this.customFields.filter((e) => e.required);
                const isCustomFieldsValid = requiredFields.map((e) => {
                    if (e.required) {
                        if (e.system && e.industrial_struct_parameter_type === "switch") {
                            return true;
                        } else {
                            return e.value != null && e.value != 0;
                        }
                    }
                });
                const hasInvalidFields = isCustomFieldsValid.includes(false);
                hasInvalidFields
                    ? (this.states.customFieldsSitesForceError = true)
                    : (this.states.customFieldsSitesForceError = false);

                return (
                    !hasInvalidFields &&
                    validName &&
                    validFunctions &&
                    validCode &&
                    validUnit &&
                    validGroup
                );
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            },
            getGroupList(list, id) {
                const typeOfModal = this.verifyTypeOfModal();
                if (typeOfModal === "new") this.group = this.currentId;
                this.groupList = [];
                list.map((e) => {
                    if (e.id === id) {
                        e.child.map((f) => {
                            this.groupList.push({
                                value: f.id,
                                text: f.name
                            });
                        });
                    }
                });
            },
            getUnitList(list) {
                const typeOfModal = this.verifyTypeOfModal();
                if (typeOfModal === "new") this.unit = this.findParentId(list, this.currentId);

                this.unitList = [];
                list.map((e) => {
                    this.unitList.push({
                        value: e.id,
                        text: e.name
                    });
                });
            },
            findParentId(array, targetId) {
                for (const obj of array) {
                    if (!obj.isWorkcenter && obj.child.some((child) => child.id === targetId)) {
                        return obj.id;
                    }
                }
                return null;
            },
            findGroupParent(data, targetId) {
                if (!data || data.length === 0) {
                    return null;
                }

                for (const item of data) {
                    if (item.child && item.child.some((child) => child.id === targetId)) {
                        return item;
                    }

                    const parentNode = this.findGroupParent(item.child, targetId, item);
                    if (parentNode) {
                        return parentNode;
                    }
                }

                return null;
            },
            getInfoEdit(item) {
                const typeOfModal = this.verifyTypeOfModal();

                if (typeOfModal === "edit") {
                    const workCenterParent = this.findGroupParent(item, this.currentId);
                    if (workCenterParent) {
                        this.group = workCenterParent.id;
                        this.unit = this.findParentId(this.dragList, workCenterParent.id);
                        this.getGroupList(this.dragList, this.unit);
                        this.getUnitList(this.dragList);
                    }
                    item.map((e) => {
                        if (e.isWorkcenter && e.id === this.currentId) {
                            this.nameInput = e.name;
                            this.code = e.code;
                            this.functions = e.functions.map((item) => ({
                                value: item,
                                text: this.rolesList.find((f) => f.id === item)?.name
                            }));
                            handleFillCustomFields(this.customFields, e.industrialParameters);

                            this.separeBySystem(this.customFields);
                        }
                        if (e.child.length > 0) {
                            this.getInfoEdit(e.child);
                        }
                    });
                } else if (typeOfModal === "new") {
                    this.getUnitList(item);
                }
            },
            verifyTypeOfModal() {
                return this.modalTitle.includes("New") || this.modalTitle.includes("Novo")
                    ? "new"
                    : "edit";
            },
            async addWorkCenter() {
                this.flagCodeEqual = false;
                this.flagNameEqual = false;
                const isValid = this.fieldsValidation();
                if (!isValid) return;

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                const typeOfModal = this.verifyTypeOfModal();

                const parameters = handleCreateParametersForPayload(this.customFields);

                this.customFields.map((e) => {
                    return {
                        id: e.id,
                        value: e.value
                            ? e.value
                            : e.industrialStructParameterType === "switch"
                              ? false
                              : null
                    };
                });

                if (typeOfModal === "new") {
                    const payload = {
                        name: this.nameInput,
                        code: this.code,
                        functions_ids: this.functions.map((e) => e.value),
                        industrial_parameter: parameters,
                        parent_id: this.group
                    };
                    await this.$http
                        .post(`/api/${this.prefix}/myconfig/workcenter`, payload)
                        .then((response) => {
                            this.showToast(
                                this.$t("IntegrationPage.success"),
                                "SmileIcon",
                                this.$t("StructurePage.WorkcenterCreated"),
                                "success"
                            );
                            this.UPDATE_MODAL_WORKCENTER_TOGGLE(false);
                            this.clearValidations();
                            this.$emit("addedItem");
                        })
                        .catch((error) => {
                            error.response.data.errors.map((e) => {
                                if (e.code === 3) {
                                    this.flagNameEqual = true;
                                } else if (e.code === 4) {
                                    this.flagCodeEqual = true;
                                } else {
                                    this.showToast(
                                        this.$t("Error"),
                                        "FrownIcon",
                                        error.response.data.message,
                                        "danger"
                                    );
                                }
                            });
                            this.fieldsValidation();
                        })
                        .finally(() => {
                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        });
                } else if (typeOfModal === "edit") {
                    const payload = {
                        name: this.nameInput,
                        code: this.code,
                        functionsIds: this.functions.map((e) => e.value),
                        industrialParameter: parameters,
                        parentId: this.group
                    };
                    await this.$http2
                        .put(`/api/myconfig/organizational-structure/workcenter/${this.currentId}`, payload)
                        .then((response) => {
                            this.showToast(
                                this.$t("IntegrationPage.success"),
                                "SmileIcon",
                                this.$t("StructurePage.SavedChanges"),
                                "success"
                            );
                            this.UPDATE_MODAL_WORKCENTER_TOGGLE(false);
                            this.clearValidations();
                            this.$emit("addedItem");
                        })
                        .catch((error) => {
                            error.response.data.errors.map((e) => {
                                if (e.code === 3) {
                                    this.flagNameEqual = true;
                                } else if (e.code === 4) {
                                    this.flagCodeEqual = true;
                                } else {
                                    this.showToast(
                                        this.$t("Error"),
                                        "FrownIcon",
                                        error.response.data.message,
                                        "danger"
                                    );
                                }
                            });
                            this.fieldsValidation();
                        })
                        .finally(() => {
                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        });
                }
            }
        },
        computed: {
            ...mapState("structures", {
                isModalWorkCenterOpen: "isModalWorkCenterOpen",
                dragList: "dragList",
                rolesList: "rolesList"
            }),
            displayImportantFirst() {
                return this.currentCustomFields.sort((a, b) => {
                    if (a.important && !b.important) {
                        return -1;
                    }
                    if (!a.important && b.important) {
                        return 1;
                    }
                    return 0;
                });
            },
            displayImportantFirstSettings() {
                return this.settingsFields.sort((a, b) => {
                    if (a.important && !b.important) {
                        return -1;
                    }
                    if (!a.important && b.important) {
                        return 1;
                    }
                    return 0;
                });
            }
        },
        watch: {
            isModalWorkCenterOpen(v) {
                this.isOpen = v;
                this.clearFields();
                this.clearValidations();
                if (v) {
                    this.getInfoEdit(this.dragList);
                    this.activeCarousel();
                }
            },
            unit(v) {
                if (v) this.getGroupList(this.dragList, v);
            },
            customFields(v) {
                this.separeBySystem(v);
            }
        }
    };
</script>
<style lang="scss">
    .sidebar-add-workcenter-unit {
        &.--role-openned {
            .b-sidebar.b-sidebar-right {
                right: 407px;
                transition: all 0.5s ease-out;
                @media (max-width: 814px) {
                    left: 0;
                    width: 50vw !important;
                }
            }
        }
        &.--role-closed {
            .b-sidebar.b-sidebar-right {
                right: 0;
                transition: all 0.5s ease-out;
            }
        }

        .custom-select {
            cursor: pointer;
            padding: 4px 34px 4px 8px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
            &[disabled] {
                border-radius: 5px;
                border: 1px solid #7e7570;
                background-color: #eee;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
                color: #7e7570 !important;
            }
        }

        .b-sidebar-header {
            display: contents;

            .header-sidebar {
                padding: 16px;

                .sidebar-title {
                    display: flex;
                    align-items: center;
                    color: #4c4541;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                }
            }

            .close-icon {
                border: 1px solid #974900;
                border-radius: 5px;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    width: 12px;
                    height: 12px;
                    path {
                        fill: #974900;
                    }
                }

                &:hover {
                    background-color: #ffede2 !important;
                }

                &:active {
                    background-color: #ffdbc4 !important;
                }
            }

            .separator {
                width: 100%;
                height: 1px;
                background: #cfc4be;
            }
        }

        .b-sidebar {
            height: 100% !important;

            .b-sidebar-body {
                padding: 16px 0;

                .form-group {
                    .form-control.is-invalid,
                    .form-control.is-valid {
                        background-image: none !important;
                    }

                    .single-select {
                        &.chip-functions {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            width: 100% !important;
                            border: none;

                            .multiselect-invalid-feedback {
                                margin-top: 3.5px;
                            }

                            .multiselect-component-single {
                                .multiselect__tags {
                                    width: 100% !important;
                                    height: 100% !important;
                                    white-space: nowrap !important;
                                    display: flex !important;
                                    align-items: center !important;
                                    overflow-x: scroll;
                                    overflow-y: hidden;
                                    margin-top: 0;
                                    height: 100% !important;
                                    cursor: grabbing;
                                    cursor: -webkit-grabbing;

                                    .multiselect__input {
                                        margin-bottom: 10px;
                                    }

                                    .multiselect__placeholder {
                                        padding-bottom: 8px;
                                        color: #cfc4be;
                                    }

                                    .multiselect__tag {
                                        background-color: #974900;
                                        margin-right: 0.6rem;
                                        font-size: 13px;

                                        .multiselect__tag-icon {
                                            line-height: 21px;

                                            &:hover {
                                                background-color: #974900;
                                                text-decoration: none;
                                                opacity: 0.75;

                                                &:after {
                                                    color: #000;
                                                }
                                            }

                                            &:after {
                                                color: #fff;
                                            }
                                        }
                                    }
                                    &::-webkit-scrollbar {
                                        display: none !important;
                                    }
                                }

                                .multiselect__select {
                                    height: 36px;
                                    background-color: #fff;
                                    z-index: 1;
                                }
                            }

                            &.focus {
                                box-shadow: none;
                            }
                        }
                    }

                    .group-label {
                        padding-top: 16px;
                    }

                    .form-control {
                        &::placeholder {
                            color: #cfc4be;
                        }
                        &:focus {
                            border-color: #974900;
                        }
                    }
                }

                .topic-title {
                    padding-block: 8px;
                    color: #4c4541;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 26px;
                }

                &::-webkit-scrollbar {
                    display: none !important;
                }
            }
        }
        .footer-sidebar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 16px;

            .btn-footer {
                box-shadow: none;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                border-radius: 5px;

                &:hover {
                    box-shadow: none;
                }

                &.btn-reset {
                    background-color: transparent !important;
                    color: #974900 !important;
                    font-size: 12px !important;
                    border: 1px solid transparent !important;
                    padding: 4px 14px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-cancel {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    margin-right: 8px;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-save {
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    color: #ffff !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #9f5714 !important;
                    }

                    &:active {
                        background-color: #a45f1f !important;
                    }
                }
            }
        }
    }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{class:[
        'sys-button',
        !!_vm.scheme && _vm.scheme,
        !!_vm.scheme && !!_vm.status && (_vm.scheme + "-" + _vm.status),
        !!_vm.scheme && !!_vm.icon && (_vm.scheme + "-icon"),
        !!_vm.scheme && !_vm.icon && (_vm.scheme + "-text-icon"),
        !!_vm.scheme && !!_vm.variant && (_vm.scheme + "-" + _vm.variant),
        !!_vm.scheme && !!_vm.variant && !!_vm.size && (_vm.scheme + "-" + _vm.variant + "-" + _vm.size),
        {
            full: _vm.full,
            noBorderLeft: _vm.noBorderLeft,
            noBorderRight: _vm.noBorderRight,
            noShadow: _vm.noShadow
        }
    ],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)}
var staticRenderFns = []

export { render, staticRenderFns }
const initialState = () => ({
    context: {
        sites: []
    },
    modalAddEditReason: {
        reasonId: null,
        initialName: null,
        initialCode: null,
        fields: {
            name: "",
            code: "",
            description: "",
            site: null
        }
    },
    reasonList: []
});

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        UPDATE_CONTEXT(state, val) {
            state.context = val;
        },
        RESET_STATES(state) {
            Object.assign(state, initialState());
        },
        RESET_MODAL_ADD_EDIT_REASON(state) {
            Object.assign(state.modalAddEditReason, initialState().modalAddEditReason);
        },
        UPDATE_MODAL_ADD_EDIT_REASON(state, val) {
            state.modalAddEditReason = val;
        },
        UPDATE_MODAL_ADD_EDIT_REASON_NAME_FIELD(state, val) {
            state.modalAddEditReason.fields.name = val;
        },
        UPDATE_MODAL_ADD_EDIT_REASON_CODE_FIELD(state, val) {
            state.modalAddEditReason.fields.code = val;
        },
        UPDATE_MODAL_ADD_EDIT_REASON_DESCRIPTION_FIELD(state, val) {
            state.modalAddEditReason.fields.description = val;
        },
        UPDATE_MODAL_ADD_EDIT_REASON_SITE_FIELD(state, val) {
            state.modalAddEditReason.fields.site = val;
        },
        UPDATE_REASON_LIST(state, val) {
            state.reasonList = val;
        }
    }
};

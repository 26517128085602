<template>
    <b-modal
        id="modal-add-edit-resource"
        shadow
        bg-variant="white"
        backdrop
        hide-footer
        centered
    >
        <template #modal-header>
            <div class="d-flex align-items-center justify-content-start w-100 title-modal">
                <span class="modal-title">
                    {{
                        modalAddEditStatus.statusId != null
                            ? $t("OperationalStatusPage.EditResource")
                            : $t("OperationalStatusPage.CreateResource")
                    }}
                </span>
            </div>

            <button
                type="button"
                aria-label="Close"
                class="close"
                @click="closeModal"
            >
                <CloseModalIcon />
            </button>
        </template>

        <div class="modal-add-edit-resource__container">
            <div class="modal-add-edit-resource__container-fields-resource">
                <b-form-group
                    :label="$t('OperationalStatusPage.Name')"
                    :invalid-feedback="
                        flagNameEqual
                            ? $t('OperationalStatusPage.StatusNameAlreadyExist')
                            : $t('RequiredField')
                    "
                >
                    <b-form-input
                        type="text"
                        :value="modalAddEditStatus.fields.name"
                        @input="(value) => UPDATE_MODAL_ADD_EDIT_STATUS_NAME_FIELD(value)"
                        :state="states.name"
                        :placeholder="states.name != false ? $t('TypeHere') : ''"
                    />
                </b-form-group>

                <b-form-group
                    :label="$t('OperationalStatusPage.Description')"
                    :invalid-feedback="$t('RequiredField')"
                    class="operational-status-description"
                >
                    <b-form-textarea
                        rows="2"
                        max-rows="2"
                        no-resize
                        type="text"
                        :value="modalAddEditStatus.fields.description"
                        @input="(value) => UPDATE_MODAL_ADD_EDIT_STATUS_DESCRIPTION_FIELD(value)"
                        :state="states.description"
                        :placeholder="states.description != false ? $t('TypeHere') : ''"
                    />
                </b-form-group>

                <div class="modal-add-edit-resource__unit-field">
                    <label>
                        {{ $t("OperationalStatusPage.Unit") }}
                    </label>

                    <SingleSelect
                        :placeholder="$t('Select')"
                        :optionSelected="modalAddEditStatus.fields.site"
                        :options="context.sites"
                        :searchable="true"
                        :state="states.site"
                        @input="(value) => UPDATE_MODAL_ADD_EDIT_STATUS_SITE_FIELD(value)"
                    >
                        {{ $t("RequiredField") }}
                    </SingleSelect>
                </div>

                <div class="modal-add-edit-resource__row-fields">
                    <div class="modal-add-edit-resource__classification-field">
                        <label>
                            {{ $t("OperationalStatusPage.Stop") }}
                        </label>

                        <SingleSelect
                            :placeholder="$t('Select')"
                            :optionSelected="modalAddEditStatus.fields.statusClassification"
                            :options="context.statusClassificationLang"
                            :searchable="true"
                            :state="states.statusClassification"
                            @input="
                                (value) => UPDATE_MODAL_ADD_EDIT_STATUS_CLASSIFICATION_FIELD(value)
                            "
                            :disabled="!!modalAddEditStatus.allowEdit"
                        >
                            {{ $t("RequiredField") }}
                        </SingleSelect>
                    </div>

                    <b-form-group
                        :label="$t('OperationalStatusPage.Efficiency')"
                        :invalid-feedback="$t('RequiredField')"
                    >
                        <b-form-input
                            type="text"
                            :value="modalAddEditStatus.fields.efficiency"
                            :state="states.efficiency"
                            :placeholder="states.efficiency != false ? $t('TypeHere') : ''"
                            @input="updateEfficiency"
                            :formatter="handleEfficiencyFormat"
                            @blur="handleOnBlurEfficiency"
                        />
                    </b-form-group>

                    <div class="modal-add-edit-resource__color-field">
                        <b-form-group
                            :label="$t('OperationalStatusPage.Color')"
                            :invalid-feedback="$t('RequiredField')"
                        >
                            <the-mask
                                placeholder="FF0000"
                                class="form-control"
                                mask="FFFFFF"
                                :state="states.color"
                                :tokens="{
                                    F: {
                                        pattern: /[0-9a-fA-F]/,
                                        transform: (v) => v.toLocaleUpperCase()
                                    }
                                }"
                                id="color"
                                :value="modalAddEditStatus.fields.colorHex"
                                @input="
                                    (value) => UPDATE_MODAL_ADD_EDIT_STATUS_COLOR_HEX_FIELD(value)
                                "
                                :class="states.color == false ? 'is-invalid' : ''"
                            />
                            <span
                                v-if="states.color == false"
                                class="invalid-feedback"
                            ></span>
                        </b-form-group>
                        <div
                            class="d-flex justify-content-end align-items-center container-block-color"
                        >
                            <input
                                id="input-color"
                                :value="modalAddEditStatus.fields.color"
                                @input="
                                    (event) =>
                                        UPDATE_MODAL_ADD_EDIT_STATUS_COLOR_FIELD(event.target.value)
                                "
                                type="color"
                                class="text-center rounded d-flex align-items-center justify-content-center shadow container-color"
                                :style="`background-color: #${modalAddEditStatus.fields.color}; ${states.color == false ? 'margin-bottom: 15px;' : ''}`"
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div class="modal-add-edit-resource__footer">
                <sys-button
                    v-if="!sendingForm"
                    scheme="square"
                    variant="contained"
                    size="lg"
                    @click="modalAddEditStatus.statusId != null ? updateStatus() : createStatus()"
                >
                    {{ $t("OperationalStatusPage.Save") }}
                </sys-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import {
        BModal,
        BForm,
        BButton,
        BCol,
        BRow,
        BFormInput,
        BFormGroup,
        BFormTextarea,
        BFormSelect
    } from "bootstrap-vue";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import { TheMask } from "vue-the-mask";
    import SysButton from "@/@core/components/sys-button";
    import CloseModalIcon from "@/@core/assets/icons/close-modal-icon.svg";
    import { mapState, mapMutations } from "vuex";

    export default {
        components: {
            BModal,
            BForm,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BButton,
            BFormTextarea,
            BFormSelect,
            TheMask,
            ToastificationContent,
            SysButton,
            CloseModalIcon,
            SingleSelect: () => import("@core/components/multiselect/SingleSelect.vue")
        },
        props: ["showToast"],
        computed: {
            ...mapState("operationalStatus", ["context", "modalAddEditStatus", "helpers"])
        },
        data() {
            return {
                currentSite: this.$cookies.get("userInfo").currentSite,
                sendingForm: false,
                flagNameEqual: false,
                states: {
                    efficiency: null,
                    site: null,
                    statusClassification: null,
                    color: null,
                    name: null,
                    description: null
                }
            };
        },
        mounted() {
            this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
                if (modalId == "modal-add-edit-resource") {
                    this.resetValues();
                }
            });
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("operationalStatus", [
                "UPDATE_MODAL_ADD_EDIT_STATUS_NAME_FIELD",
                "UPDATE_MODAL_ADD_EDIT_STATUS_DESCRIPTION_FIELD",
                "UPDATE_MODAL_ADD_EDIT_STATUS_SITE_FIELD",
                "UPDATE_MODAL_ADD_EDIT_STATUS_CLASSIFICATION_FIELD",
                "UPDATE_MODAL_ADD_EDIT_STATUS_EFFICIENCY_FIELD",
                "UPDATE_MODAL_ADD_EDIT_STATUS_COLOR_FIELD",
                "UPDATE_MODAL_ADD_EDIT_STATUS_COLOR_HEX_FIELD",
                "RESET_MODAL_ADD_EDIT_STATUS"
            ]),
            handleOnBlurEfficiency() {
                let formattedValue = this.modalAddEditStatus.fields.efficiency.replace(
                    /[^0-9.]/g,
                    ""
                );

                if (formattedValue[formattedValue.length - 1] == ".") {
                    formattedValue = formattedValue.replace(".", "");
                }

                this.UPDATE_MODAL_ADD_EDIT_STATUS_EFFICIENCY_FIELD(
                    formattedValue ? formattedValue + "%" : ""
                );
            },
            closeModal() {
                this.$bvModal.hide("modal-add-edit-resource");
            },
            resetValues() {
                this.RESET_MODAL_ADD_EDIT_STATUS();

                this.states = {
                    efficiency: null,
                    site: null,
                    statusClassification: null,
                    color: null,
                    name: null,
                    description: null
                };

                this.sendingForm = false;
            },
            validateFields() {
                let nameEqual = this.helpers.statusNames.find(
                    (item) =>
                        item == this.modalAddEditStatus.fields.name &&
                        item != this.modalAddEditStatus.initialName
                );

                if (nameEqual && this.modalAddEditStatus.fields.name) {
                    this.flagNameEqual = true;
                } else {
                    this.flagNameEqual = false;
                }

                const localStates = { ...this.states };

                localStates.color = !!this.modalAddEditStatus.fields.color;
                localStates.name =
                    this.modalAddEditStatus.fields.name && this.flagNameEqual == false
                        ? true
                        : false;
                localStates.description = !!this.modalAddEditStatus.fields.description;
                localStates.statusClassification =
                    !!this.modalAddEditStatus.fields.statusClassification;
                localStates.efficiency = !!this.modalAddEditStatus.fields.efficiency;
                localStates.site = !!this.modalAddEditStatus.fields.site;

                this.states = { ...localStates };

                return Object.values(localStates).every((item) => !!item);
            },
            async createStatus() {
                const validationResult = this.validateFields();

                if (!validationResult) return;

                const CREATE_STATUS_URL = `/api/myconfig/currently-status`;

                const payload = this.handleCreatePayload();

                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                this.sendingForm = true;

                try {
                    await this.$http2.post(CREATE_STATUS_URL, payload);

                    this.showToast(
                        this.$t("OperationalStatusPage.Success"),
                        "SmileIcon",
                        this.$t("OperationalStatusPage.MessageAddSuccess"),
                        "success"
                    );

                    this.closeModal();

                    this.$emit("refresh-list");
                } catch (error) {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    console.error(error);
                } finally {
                    this.sendingForm = false;
                }
            },
            handleCreatePayload() {
                return {
                    name: this.modalAddEditStatus.fields.name,
                    description: this.modalAddEditStatus.fields.description,
                    statusClassificationId:
                        this.modalAddEditStatus.fields.statusClassification?.id ?? null,
                    efficiency: Number(
                        this.modalAddEditStatus.fields.efficiency.replace(/[^0-9.]/g, "")
                    ),
                    color: this.modalAddEditStatus.fields.color,
                    language: "pt-BR",
                    siteId: this.modalAddEditStatus.fields.site?.id ?? null
                };
            },
            async updateStatus() {
                const validationResult = this.validateFields();

                if (!validationResult) return;

                const UPDATE_STATUS_URL = `/api/myconfig/currently-status/${this.modalAddEditStatus.statusId}`;
                const payload = this.handleCreatePayload();

                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                this.sendingForm = true;

                try {
                    await this.$http2.put(UPDATE_STATUS_URL, payload);
                    this.$emit("refresh-list");
                    this.closeModal();
                } catch (error) {
                    console.error(error);
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                } finally {
                    this.sendingForm = false;
                }
            },
            formatEfficiency(value) {
                const replacedValue =
                    value === "." ? "" : value.replace(/[^0-9.]/g, "").replace(/^0(\d)/, "$1");
                const splitedValue = replacedValue.split(".");

                const formattedValue =
                    splitedValue.length > 1
                        ? splitedValue[0] + "." + splitedValue.slice(1).join("")
                        : replacedValue;

                return formattedValue;
            },
            updateEfficiency(value) {
                const formattedValue = this.formatEfficiency(value);

                this.UPDATE_MODAL_ADD_EDIT_STATUS_EFFICIENCY_FIELD(formattedValue);
            },
            handleEfficiencyFormat(value) {
                return value?.replace(/[^0-9.]/g, "") ?? value;
            }
        },
        watch: {
            "modalAddEditStatus.fields.color"(v) {
                this.UPDATE_MODAL_ADD_EDIT_STATUS_COLOR_HEX_FIELD(v.replace("#", ""));
            },
            "modalAddEditStatus.fields.colorHex"(v) {
                this.UPDATE_MODAL_ADD_EDIT_STATUS_COLOR_FIELD(v ? "#" + v : "");
            }
        }
    };
</script>

<style lang="scss">
    #modal-add-edit-resource {
        .form-control {
            &::placeholder {
                color: #cfc4be;
            }

            &:focus {
                border-color: #974900;
            }
        }

        .custom-select option:not(:checked) {
            color: #4c4541;
        }

        .form-control.is-invalid {
            background-image: none !important;
        }

        .invalid-feedback {
            margin-left: 5px !important;
            font-size: 10px;
        }

        .modal-dialog {
            width: 95%;
            max-width: none;
        }

        .modal-content {
            width: 100%;
            right: 0 !important;
        }

        .modal-header {
            position: relative !important;
            height: 66px;
            background-color: #eceff1;
            padding-top: 0;
            padding-bottom: 0;

            .title-modal {
                height: 100%;
            }

            .modal-title {
                color: #4c4541;
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
            }

            .close {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 34px;
                min-width: 34px;
                max-height: 34px;
                max-width: 34px;
                transform: translate(6px, 5px);

                svg {
                    min-height: 12px;
                    min-height: 12px;
                    min-width: 12px;
                    max-width: 12px;
                    fill: #4c4541;
                }

                &:hover {
                    transform: translate(6px, 5px);
                }
            }
        }

        .modal-body {
            padding: 0 !important;
        }
    }

    @media (min-width: 768px) {
        #modal-add-edit-resource {
            padding: 0 !important;

            .modal-content,
            .modal-dialog {
                max-width: 748px !important;
            }
        }
    }
</style>

<style lang="scss">
    .modal-add-edit-resource__container {
        display: flex;
        flex-direction: column;
        padding: 20px 16px;
        gap: 20px;

        .modal-add-edit-resource__footer {
            display: flex;
            justify-content: flex-end;
        }

        .modal-add-edit-resource__container-fields-resource {
            display: flex;
            flex-direction: column;
            gap: 16px;

            legend {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #4c4541;
                padding-bottom: 0px !important;
                margin-bottom: 4px;
            }

            label {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #4c4541;
                padding-bottom: 0px !important;
                margin-bottom: 4px;
            }

            .operational-status-description {
                textarea {
                    max-height: 59px !important;
                }
            }

            #input-color {
                -webkit-appearance: none;
                border: none;
                width: 53px;
                height: 38px;
            }

            #input-color::-webkit-color-swatch-wrapper {
                padding: 0;
            }

            #input-color::-webkit-color-swatch {
                border: none;
                border-radius: 6px;
            }

            .container-input-color {
                width: 85%;
            }

            .form-group {
                margin: 0;
            }

            .modal-add-edit-resource__unit-field {
                display: flex;
                flex-direction: column;
                flex: 1;
            }

            .modal-add-edit-resource__row-fields {
                display: flex;
                gap: 16px;
                flex-wrap: nowrap;
                align-items: flex-start;

                .form-group {
                    flex: 1;
                }

                .modal-add-edit-resource__classification-field {
                    display: flex;
                    flex-direction: column;
                    flex: 1;
                }

                .modal-add-edit-resource__color-field {
                    display: flex;
                    align-items: flex-end;
                    gap: 8px;
                    flex-wrap: nowrap;
                    flex: 1;
                }
            }
        }
    }

    @media (max-width: 570px) {
        #modal-add-edit-resource {
            padding: 0 !important;

            .modal-dialog {
                margin: 24px auto;
            }

            .modal-header {
                padding-bottom: 0;
            }

            .modal-add-edit-resource__row-fields {
                flex-direction: column !important;
            }
        }
    }
</style>

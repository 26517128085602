<template>
    <div>
        <div id="navbar">
            <div class="side">
                <div
                    class="toggle sidebar-open-button"
                    @click="toggleFullScreenMenu"
                >
                    <HamburgerIcon
                        v-show="!open"
                        class="open-sidebar-icon"
                    />
                    <CloseIcon
                        v-show="open"
                        class="open-sidebar-icon"
                    />
                </div>
                <div class="logo">
                    <Logo class="logo-icon" />
                </div>
                <Breadcrumb v-if="device === 'desktop'" />
            </div>
            <div class="profile">
                <ExtraItem
                    v-if="device === 'mobile'"
                    id="extra"
                    :items="navItems"
                    :name="$t('Menu.Items.Navigation')"
                    :title="$t('Menu.Items.Navigation')"
                    :border="false"
                    :variant="variant"
                    :hiddenTooltip="true"
                    tooltipPlacement="bottom"
                />

                <div
                    class="nav-items"
                    v-if="device !== 'mobile'"
                >
                    <NavItem
                        item-id="myind-navbar"
                        :name="$t('Menu.Items.MyInd')"
                        variant="short"
                        :icon="icons.MyIndIcon"
                        @click="() => redirect('myind')"
                        tooltipPlacement="bottom"
                        :hiddenTooltip="device !== 'desktop'"
                    />

                    <NavItem
                        :name="$t('Menu.Items.Support')"
                        variant="short"
                        strokeHover
                        :selected="currentPage.includes('/support')"
                        :icon="icons.HeadsetIcon"
                        @click="() => redirect('support')"
                        data-redirect="support"
                        tooltipPlacement="bottom"
                        :hiddenTooltip="device !== 'desktop'"
                    />
                </div>
                <div
                    id="profile_trigger_navbar"
                    tabindex="0"
                    class="picture"
                >
                    <img
                        v-if="user.photo"
                        :src="user.photo"
                        alt="User photo"
                    />
                    <AvatarPlaceholder
                        v-else
                        size="30px"
                        :text="picturePlaceholder"
                    />
                    <Tooltip
                        v-if="device == 'desktop'"
                        target="profile_trigger_navbar"
                        :text="$t('Menu.Titles.Account')"
                    />
                </div>
            </div>
            <Popover
                :items="items"
                target="profile_trigger_navbar"
                :title="$t('Menu.Titles.Account')"
                :secondTitle="$t('Menu.Languages.Title')"
                :user="user"
                isProfilePopover
                hasLanguage
                placement="bottom"
                custom-position="navbar"
                triggers="focus"
            />
            <Menu
                :user="user"
                :device="device"
                :closeDropdown="open"
                v-show="open"
                wide
                @close="closeFullscreenMenu"
                @logout="logout"
            />
        </div>
        <div id="navbar-breadcrumbs">
            <Breadcrumb
                :device="device"
                v-if="device !== 'desktop'"
            />
        </div>
    </div>
</template>

<script>
    import Logo from "@core/assets/logo-config.svg";
    import ExtraItem from "../Extra";
    import MyIndIcon from "@core/assets/icons/myind.svg";
    import HamburgerIcon from "@core/assets/icons/hamburger-icon.svg";
    import CloseIcon from "@core/assets/icons/close-icon.svg";
    import HeadsetIcon from "@core/assets/icons/headset-icon.svg";
    import ArrowDown from "@core/assets/icons/arrow-down.svg";
    import ProfileIcon from "@core/assets/icons/profile-icon-filled.svg";
    import BrazilFlag from "@core/assets/icons/brazil-flag.svg";
    import USAFlag from "@core/assets/icons/usa-flag.svg";
    import ExitIcon from "@core/assets/icons/exit-icon-filled.svg";

    import Menu from "../Sidebar/index.vue";
    import Popover from "../Popover.vue";
    import NavItem from "../NavItem.vue";
    import NavItemGroup from "../NavItemGroup.vue";
    import Tooltip from "../Tooltip.vue";
    import Breadcrumb from "../../../../../../layouts/components/Breadcrumb.vue";

    import AvatarPlaceholder from "@/@core/components/avatar-placeholder/index.vue";

    const ICONS = {
        ProfileIcon,
        ExitIcon,
        MyIndIcon,
        HeadsetIcon,
        CloseIcon,
        BrazilFlag,
        USAFlag
    };

    export default {
        name: "NavbarApp",
        props: {
            user: {
                type: Object
            },
            device: {
                type: String,
                default: "desktop"
            }
        },
        components: {
            Logo,
            MyIndIcon,
            HamburgerIcon,
            CloseIcon,
            HeadsetIcon,
            ArrowDown,
            BrazilFlag,
            USAFlag,
            ExtraItem,
            Menu,
            Tooltip,
            Popover,
            AvatarPlaceholder,
            NavItem,
            NavItemGroup,
            Breadcrumb
        },
        data() {
            var self = this;
            return {
                open: false,
                icons: ICONS,
                navItems: [
                    {
                        title: "Menu.Items.MyInd",
                        icon: ICONS.MyIndIcon,
                        click: () => (window.location.href = this.$myIndLink)
                    },
                    {
                        title: "Menu.Items.Support",
                        icon: ICONS.HeadsetIcon,
                        click: () =>
                            (window.location.href = `${this.$myIndLink}${localStorage.getItem("prefix")}/support`)
                    }
                ],
                items: [
                    {
                        title: "Menu.Items.Account",
                        icon: ICONS.ProfileIcon,
                        click: () =>
                            (window.location.href = `${this.$myIndLink}${localStorage.getItem("prefix")}/account`)
                    },
                    {
                        title: "Menu.Items.Exit",
                        icon: ICONS.ExitIcon,
                        click: () => self.logout()
                    }
                ]
            };
        },
        methods: {
            logout() {
                this.$emit("logout");
            },
            toggleFullScreenMenu() {
                this.open = !this.open;
            },
            closeFullscreenMenu() {
                this.open = false;
            },
            redirect(page) {
                if (this.$route.name === page) return;
                if (page == "myind") {
                    window.location.href = this.$myIndLink;
                    return;
                }

                if (page === "support") {
                    window.location.href = `${this.$myIndLink}${localStorage.getItem("prefix")}/support`;
                    return;
                }

                this.prefix = this.$cookies.get("company");

                this.$router.push({ name: page, params: { prefix: this.prefix } });

                if (this.wide) {
                    this.$emit("close");
                }
            },
            addVisibleNavItems() {
                const sidebar = this.$refs.sidebar;
                const navItemGroups = [this.$refs.generalGroup, this.$refs.sites];

                // Tamanho da viewport
                const viewportHeight = window.innerHeight - 100;
                // Posição do topo do sidebar em relação ao documento
                const sidebarTop = sidebar.getBoundingClientRect().top;
                // Itens visíveis e itens que ultrapassam o limite da viewport
                const visibleNavItems = [];
                const overflowNavItems = [];

                for (const group of navItemGroups) {
                    const groupTitle = group.title;
                    const navItems = group.$children;
                    for (const item of navItems) {
                        const itemRect = item.$el.getBoundingClientRect();
                        const itemTop = itemRect.top - sidebarTop;
                        if (itemTop >= 0 && itemTop + itemRect.height + 30 <= viewportHeight) {
                            // O item está visível na viewport
                            visibleNavItems.push({
                                group: groupTitle,
                                navItem: item
                            });
                        } else {
                            // O item ultrapassa o limite da viewport
                            overflowNavItems.push({
                                group: groupTitle,
                                name: item.name,
                                icon: item.icon,
                                click: () => this.redirect(item.$el.dataset.redirect)
                            });
                        }
                    }
                }

                // Atualizar a lista de itens visíveis e itens que ultrapassam a viewport
                this.visibleNavItems = visibleNavItems;
                this.overflowNavItems = overflowNavItems;
            }
        },
        computed: {
            picturePlaceholder() {
                const firstLetter = (str) => String(str)?.[0]?.toUpperCase() ?? "";
                return `${firstLetter(this.user.first_name)}${firstLetter(this.user.last_name)}`;
            },
            currentPage() {
                return this.$route.path;
            }
        }
    };
</script>

<style lang="scss" scoped>
    #navbar {
        width: 100%;
        height: 48px;
        display: flex;
        flex-direction: row;
        background: #fff;
        box-shadow: 0px 8px 30px 0px rgba(58, 46, 38, 0.1);
        align-items: center;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 100%;
            -webkit-backdrop-filter: blur(25px);
            backdrop-filter: blur(25px);
            z-index: -1;
        }
    }

    #navbar-breadcrumbs {
        padding: 0 2px;
        border-top: 1px solid #eee;
        background-color: #fff;
    }

    .nav-items {
        display: flex;
        align-items: center;
        white-space: nowrap;
    }

    .side {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1;

        .toggle {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            border-right: 1px solid #eee;

            svg {
                fill: #998f8a;
            }

            &:hover {
                background-color: #ffede1 !important;

                svg {
                    fill: #974900 !important;
                }
            }
        }

        .logo {
            display: flex;
            justify-content: center;
            align-items: center;
            width: fit-content;
            height: 100%;
            padding-left: 16px;
        }
    }

    .profile {
        cursor: pointer;
        border-left: 1px solid #eee;
        height: 100%;
        padding: 0 7px 0 0;
        display: flex;
        flex-direction: row;

        .my-ind,
        .support {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px 0;

            .myind-icon,
            .support-icon {
                width: 16px;
                height: 16px;

                svg {
                    fill: #4c4541;
                }
            }
        }

        .user {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            align-items: flex-end;
            width: 93px;

            h3,
            h2 {
                margin: 0;
                padding: 0;
                text-align: left;
            }

            h3 {
                color: #4c4541;
                font-size: 14px;
                font-weight: 600;
                line-height: 20px;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            h2 {
                color: #998f8a;
                font-size: 12px;
                font-weight: 400;
                line-height: 16px;
                white-space: nowrap;
                max-width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .picture {
            height: 100%;
            display: flex;
            align-items: center;
            padding: 9px;

            &:hover {
                background-color: #ffede1 !important;
            }
        }

        img {
            width: 30px;
            height: 30px;
            object-fit: cover;
            border-radius: 100%;
        }

        .arrow-down {
            height: 100%;
            width: 16px;
            display: flex;
            align-items: center;

            svg {
                fill: #998f8a;
            }
        }
    }

    .sidebar-open-button {
        padding: 16px;

        .open-sidebar-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 16px;
            height: 16px;
        }
    }

    .logo-icon {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .arrow-down-icon {
        width: 16px;
        height: 16px;
    }

    @media (max-width: 480px) {
        #navbar {
            .side {
                .logo {
                    padding-left: 12px;
                }
            }
        }
    }
</style>

<template>
  <div class="d-flex">
    <b-sidebar
      id="duplicate-order-sidebar-right"
      class="sidebar-duplicate-order"
      right
      bg-variant="white"
      :width="device === 'desktop' ? '407px' : device === 'tablet' ? '60vw' : '100vw'"
      v-model="isOpen"
      @hidden="closeModal"
    >
      <template #header>
        <div class="header-sidebar d-flex justify-content-between">
          <span class="sidebar-title">
            {{ $t(modalTitle) }}
          </span>

          <span
            class="close-icon"
            @click="closeModal()"
          >
            <CloseIcon />
          </span>
        </div>
        <div class="separator"></div>
      </template>
      <template>
        <b-col
          md="12"
          v-if="currentLevel === 1"
        >
          <b-form-group
            :label="$t('OrdersPage.OrderNumber')"
            label-for="orderNumber"
            :invalid-feedback="
              flagOrderNumberEqual ? $t('OrdersPage.EqualOrderNumber') : $t('RequiredField')
            "
          >
            <b-form-input
              id="orderNumber"
              v-model="orderNumber"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.orderNumber"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          v-if="currentLevel === 2"
        >
          <b-form-group
            :label="$t('DepartmentPage.Name')"
            label-for="name"
            :invalid-feedback="
              flagNameEqual ? $t('MaterialsPage.EqualMaterialName') : $t('RequiredField')
            "
          >
            <b-form-input
              id="name"
              v-model="name"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.name"
              lazy-formatter
              :formatter="
                () => {
                  states.name = name !== '';
                  return name;
                }
              "
            />
          </b-form-group>
        </b-col>
        <b-col
          md="12"
          v-if="currentLevel === 2"
        >
          <b-form-group
            :label="$t('MaterialsPage.Number')"
            label-for="number"
            :invalid-feedback="
              flagNumberEqual ? $t('MaterialsPage.EqualMaterialNumber') : $t('RequiredField')
            "
          >
            <b-form-input
              id="number"
              v-model="number"
              :placeholder="$t('ResourceStatus.Placeholder')"
              :state="states.number"
              lazy-formatter
              :formatter="
                () => {
                  states.number = number !== '';
                  return number;
                }
              "
              type="text"
              @input.native="
                (e) => {
                  const maskApplied = e.target.value.replace(/[^0-9]/g, '');
                  number = maskApplied;
                  e.target.value = maskApplied;
                }
              "
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <div class="linked-items">
            <span class="linked-items-text">{{ $t('ProfilesPage.LinkedItems') }}:</span>
            <div class="icons-modal">
              <div
                id="operation-info"
                class="icon-modal"
                v-show="currentLevel === 1"
              >
                <OperationIcon class="icon-duplicate" />
                <span>{{ operationCount }}</span>
              </div>
              <div
                id="materials-info"
                class="icon-modal"
              >
                <MaterialIcon class="icon-duplicate" />
                <span>{{ materialCount }}</span>
              </div>
              <Tooltip
                target="operation-info"
                placement="top"
                :text="$t('MaterialsPage.Operations')"
              />
              <Tooltip
                target="materials-info"
                placement="top"
                :text="$t('MaterialsPage.MaterialsUsed')"
              />
            </div>
          </div>
        </b-col>
      </template>

      <template #footer>
        <div class="separator"></div>
        <b-col
          md="12"
          class="footer-sidebar"
        >
          <div :class="['d-flex', 'buttons']">
            <b-button
              :class="['btn-cancel', 'btn-footer']"
              @click="closeModal()"
            >
              {{ $t('RefuseStatus.Cancel') }}
            </b-button>
            <b-button
              class="btn-save btn-footer"
              @click="onSave()"
            >
              {{ $t('RefuseStatus.Save') }}
            </b-button>
          </div>
        </b-col>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
  import { mapState, mapMutations } from 'vuex';
  import { BFormGroup, BFormInput, BButton, BRow, BCol, BSidebar, BCollapse } from 'bootstrap-vue';
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue';
  import Tooltip from '@/@core/layouts/layout-horizontal/components/Menu/Tooltip.vue';

  export default {
    data() {
      return {
        flagNameEqual: false,
        flagNumberEqual: false,
        flagOrderNumberEqual: false,
        isOpen: false,
        orderSelected: {},
        states: {
          name: null,
          orderNumber: null,
          number: null
        },
        name: '',
        orderNumber: '',
        number: ''
      };
    },
    components: {
      BFormGroup,
      BFormInput,
      BButton,
      BRow,
      BCol,
      BSidebar,
      BCollapse,
      Tooltip,
      CloseIcon: () => import('@core/assets/icons/close-icon.svg'),
      RouteIcon: () => import('@core/assets/icons/route-icon.svg'),
      OperationIcon: () => import('@core/assets/icons/operation-icon.svg'),
      MaterialIcon: () => import('@core/assets/icons/material-icon.svg')
    },
    props: {
      modalTitle: {
        type: String,
        default: ''
      },
      device: {
        type: String,
        default: 'desktop'
      },
      currentId: {
        type: Number,
        default: null
      },
      currentLevel: {
        type: Number,
        default: null
      },
      materialCount: {
        type: Number,
        default: 0
      },
      operationCount: {
        type: Number,
        default: 0
      },
      currentName: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      }
    },
    methods: {
      ...mapMutations('app', ['UPDATE_FLAG_SHOW_OVERLAY']),
      ...mapMutations('productionOrders', ['UPDATE_MODAL_DUPLICATE_TOGGLE']),
      closeModal() {
        this.UPDATE_MODAL_DUPLICATE_TOGGLE(false);
      },
      showToast(title, icon, text, variant) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: title,
            icon: icon,
            text: text,
            variant
          }
        });
      },
      async getLinkedItems() {
        if (this.currentLevel === 1) {
          this.orderNumber = this.currentName;
        } else {
          this.name = this.currentName;
        }
        // await this.$http2
        //   .get(`api/myconfig/material/${this.currentId}/${this.currentLevel}/linked-items`)
        //   .then((response) => {
        //     this.orderSelected = response.data.data;

        //     this.UPDATE_FLAG_SHOW_OVERLAY(false);
        //   })
        //   .catch((error) => console.error(error));
      },
      clearFields() {
        this.name = '';
        this.orderNumber = '';
        this.number = '';
      },
      clearValidations() {
        this.states.name = null;
        this.states.orderNumber = null;
        this.states.number = null;
      },
      fieldsValidate() {
        this.clearValidations();

        const validName = (this.states.name =
          this.name && this.flagNameEqual === false ? true : false);

        let validNumber = true;
        let validOrderNumber = true;

        if (this.currentLevel === 3) {
          validNumber = this.states.number =
            this.number && this.flagNumberEqual === false ? true : false;
        }

        if (this.currentLevel === 1) {
          validOrderNumber = this.states.orderNumber =
            this.orderNumber && this.flagOrderNumberEqual === false ? true : false;
        }

        return validName && validNumber && validOrderNumber;
      },
      async onSave() {
        this.flagNameEqual = false;
        this.flagNumberEqual = false;
        this.flagOrderNumberEqual = false;
        const isValid = this.fieldsValidate();
        if (!isValid) return;

        this.UPDATE_FLAG_SHOW_OVERLAY(true);

        if (this.currentLevel === 1) {
          const payload = {
            orderNumber: this.orderNumber
          };
          await this.$http2
            .post(`/api/myconfig/order/${this.currentId}/duplicate`, payload)
            .then((response) => {
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('OrdersPage.DuplicateOrder'),
                'success'
              );
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
              this.$emit('updateList');
              this.closeModal();
            })
            .catch((error) => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
              error.response.data.errors.map((e) => {
                const orderNumberEqualId = 124;
                if (e.id === orderNumberEqualId) {
                  this.flagOrderNumberEqual = true;
                } else {
                  this.showToast(
                    this.$t('IntegrationPage.Error'),
                    'SmileIcon',
                    e.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidate();
            });
        }

        if (this.currentLevel === 2) {
          const payload = {
            name: this.name,
            number: this.number
          };
          await this.$http2
            .post(`/api/myconfig/order/duplicate-operation/${this.currentId}`, payload)
            .then((response) => {
              this.showToast(
                this.$t('IntegrationPage.success'),
                'SmileIcon',
                this.$t('MaterialsPage.DuplicateOperation'),
                'success'
              );
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
              this.$emit('updateList');
              this.closeModal();
            })
            .catch((error) => {
              this.UPDATE_FLAG_SHOW_OVERLAY(false);
              error.response.data.errors.map((e) => {
                if (e.code === 3) {
                  this.flagNameEqual = true;
                } else {
                  this.showToast(
                    this.$t('IntegrationPage.Error'),
                    'SmileIcon',
                    e.message,
                    'danger'
                  );
                }
              });
              this.fieldsValidate();
            });
        }
      }
    },
    computed: {
      ...mapState('productionOrders', {
        isModalDuplicateOpen: 'isModalDuplicateOpen'
      })
    },
    watch: {
      isModalDuplicateOpen(v) {
        this.isOpen = v;
        this.clearValidations();
        this.clearFields();
        if (v) {
          this.getLinkedItems();
          this.name = this.currentName;
        }
      }
    }
  };
</script>

<style lang="scss">
  .sidebar-duplicate-order {
    .custom-select {
      cursor: pointer;
      padding: 4px 34px 4px 8px;
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
      &[disabled] {
        border-radius: 5px;
        border: 1px solid #7e7570;
        background-color: #eee;
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
        color: #7e7570 !important;
      }
    }

    .b-sidebar-header {
      display: contents;

      .header-sidebar {
        width: 100%;
        padding: 16px;

        .sidebar-title {
          display: flex;
          align-items: center;
          color: #4c4541;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
        }

        .close-icon {
          border: 1px solid #974900;
          border-radius: 5px;
          width: 28px;
          height: 28px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg {
            width: 12px;
            height: 12px;
            path {
              fill: #974900;
            }
          }

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }
      }

      .separator {
        width: 100%;
        height: 1px;
        background: #cfc4be;
      }
    }

    .b-sidebar {
      box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
      transition: all 0.5s ease-out;
      height: 100% !important;
      .b-sidebar-body {
        padding: 16px 0;
        .form-group {
          .form-control.is-invalid,
          .form-control.is-valid {
            background-image: none !important;
          }

          label {
            color: #4c4541;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
          }

          .form-control {
            &::placeholder {
              color: #cfc4be;
            }
            &:focus {
              border-color: #974900;
            }
          }
        }
        .input-email,
        .input-phone {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
          padding-inline: 16px;
          gap: 16px;

          .form-group {
            width: inherit;
          }
        }

        .icon {
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 11px 0px;
          margin-top: 7px;
          max-width: 38px;
          width: 100%;
          border-radius: 5px;
          border: 1px solid #974900;

          &.btn-phone,
          &.btn-email {
            background-color: transparent !important;
            color: #974900 !important;
            border: 1px solid #974900 !important;

            &:hover {
              background-color: #ffede2 !important;
            }

            &:active {
              background-color: #ffdbc4 !important;
            }
          }
        }

        &::-webkit-scrollbar {
          display: none !important;
        }
      }
    }

    .step-action {
      padding: 16px 0;

      .step-collapse {
        padding-left: 16px;
      }

      .step-collapse,
      .text {
        display: flex;
        gap: 8px;
        color: #4c4541;
        font-size: 16px;
        font-weight: 600;
        line-height: 26px;
        .icon {
          svg {
            width: 16px;
            height: 16px;
            fill: #974900;
          }
        }

        &.not-collapsed {
          svg {
            transform: rotate(-180deg);
            transition: all 0.3s;
          }
        }

        &.collapsed {
          svg {
            transform: rotate(0);
            transition: all 0.3s;
          }
        }
      }
      .text {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        padding-right: 16px;
      }

      .step-progress {
        display: flex;
        gap: 4px;
        padding: 4px 16px 0;
        .first-step,
        .second-step {
          width: 50%;
          height: 4px;
          border-radius: 4px;
          background: #f4eee8;

          &.step-active {
            background: #974900;
          }

          &.next-step {
            background: #66bb6a;
          }
        }
      }
    }

    .progress-block {
      padding-top: 16px;

      .detail-block {
        padding: 16px 16px 0;

        .info-block,
        .login-block {
          display: flex;
          align-items: center;
          gap: 8px;
          .icon {
            display: flex;
            align-items: center;
            width: 30px;
            height: 30px;
            padding: 7px;
            border-radius: 50%;
            border: 1px solid #cfc4be;

            svg {
              width: 14px;
              height: 14px;
              fill: #cfc4be;
            }
          }

          .text {
            overflow: hidden;
            color: #cfc4be;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 16px;
            font-weight: 600;
            line-height: 26px;
          }

          &.step-active {
            .icon {
              border-color: #974900;
              svg {
                fill: #974900;
              }
            }

            .text {
              color: #974900;
            }
          }

          &.next-step {
            .icon {
              border-color: #66bb6a;
              background: #66bb6a;
              svg {
                fill: #fff;
              }
            }

            .text {
              color: #66bb6a;
            }
          }
        }

        .vertical-separator {
          margin-left: 14px;
          width: 1px;
          height: 16px;
          background: #cfc4be;

          &.step-active {
            background: #974900;
          }

          &.next-step {
            background: #66bb6a;
          }
        }
      }
    }

    .separator {
      width: 100%;
      height: 1px;
      background: #cfc4be;
    }

    .separator-progress {
      width: 100%;
      height: 1px;
      background: #eee;
    }

    .footer-sidebar {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 16px;

      .buttons,
      .edit-buttons {
        gap: 8px;
      }

      @media (max-width: 480px) {
        justify-content: center;
        .buttons {
          width: 100%;

          .btn {
            width: 50%;
          }
        }

        .btn-footer {
          font-size: 12px !important;
          padding: 3px 21px !important;
        }
      }

      .btn-footer {
        box-shadow: none;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        border-radius: 5px;

        &:hover {
          box-shadow: none;
        }

        &.btn-cancel {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-previous,
        &.btn-cancel.btn-cancel-outline {
          background-color: transparent !important;
          color: #974900 !important;
          border: 1px solid #974900 !important;
          padding: 8px 21px;

          &:hover {
            background-color: #ffede2 !important;
          }

          &:active {
            background-color: #ffdbc4 !important;
          }
        }

        &.btn-save {
          border: 1px solid #974900 !important;
          background-color: #974900 !important;
          color: #ffff !important;
          padding: 8px 21px;

          &:hover {
            background-color: #9f5714 !important;
          }

          &:active {
            background-color: #a45f1f !important;
          }
        }
      }
    }
  }

  .linked-items {
    display: flex;
    align-items: center;
    gap: 16px;
    .linked-items-text {
      color: #998f8a;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px;
      font-family: Raleway;
    }

    .icons-modal {
      display: flex;
      align-items: center;
      color: #974900;
      gap: 16px;

      .icon-modal {
        cursor: pointer;
        padding: 7px 8px;
        display: flex;
        align-items: center;
        border-radius: 6px;
        gap: 5px;

        .icon-duplicate {
          width: 14px;
          height: 14px;
          fill: #974900 !important;
          svg {
            path {
              fill: #974900;
            }
          }
        }

        span {
          font-size: 10px;
          font-weight: 600;
          line-height: 14px;
        }

        &:hover {
          background: #ffede2;
        }
        &:active {
          background: #ffdbc4;
        }
      }
    }
  }
</style>

<template>
    <div>
        <modal-add-edit-reason
            :showToast="showToast"
            @refresh-list="() => handleGetRejectionReasonsList()"
        />

        <div id="card-refuse-status">
            <div>
                <div class="d-flex align-items-center justify-content-start c-header">
                    <div
                        class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between c-header-content"
                    >
                        <span class="c-title">{{ $t("RefuseStatus.Title") }}</span>
                        <b-button
                            class="btn-new-reason"
                            @click="handleOpenAddReasonModal"
                        >
                            <span class="text-nowrap">{{ $t("RefuseStatus.Add") }}</span>
                        </b-button>
                    </div>
                </div>
                <div class="mt-1 table-reasons">
                    <table-default
                        :rows="reasonList"
                        :fields="fields"
                        :stickyHeader="false"
                        :striped="true"
                        :filter="filter"
                        id="table-reasons"
                        @updatePageNumber="updatePageNumber"
                        @updatepageSize="updatepageSize"
                        currentPageOff
                    >
                        <template v-slot:cell(action)="data">
                            <span>
                                <b-dropdown
                                    variant="link"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    class="d-action-reasons"
                                >
                                    <template v-slot:button-content>
                                        <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="d-color align-middle mr-25"
                                        />
                                    </template>
                                    <b-dropdown-item
                                        @click="handleOpenEditReasonModal(data.item.id)"
                                    >
                                        <EditIcon />
                                        <span class="d-itens m-1">
                                            {{ $t("RefuseStatus.Edit") }}
                                        </span>
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        @click="
                                            handleOpenRemoveReasonModal(
                                                data.item.id,
                                                data.item.hasOperationProgress
                                            )
                                        "
                                    >
                                        <DeleteIcon />
                                        <span class="d-itens ml-1">
                                            {{ $t("RefuseStatus.Delete") }}
                                        </span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </span>
                        </template>
                        <template v-slot:cell(code)="data">
                            <span :id="`tooltip-reason-code-${data.index}`">
                                {{ data.item.code }}
                            </span>
                            <b-tooltip
                                custom-class="tooltip-refuse-status"
                                triggers="hover blur"
                                :target="`tooltip-reason-code-${data.index}`"
                                positioning="top"
                                placement="top"
                                boundary-padding="0"
                            >
                                <div class="info-tooltip-refuse-status">
                                    {{ data.item.code }}
                                </div>
                            </b-tooltip>
                        </template>
                        <template v-slot:cell(name)="data">
                            <span :id="`tooltip-reason-name-${data.index}`">
                                {{ data.item.name }}
                            </span>
                            <b-tooltip
                                custom-class="tooltip-refuse-status"
                                triggers="hover blur"
                                :target="`tooltip-reason-name-${data.index}`"
                                positioning="top"
                                placement="top"
                                boundary-padding="0"
                            >
                                <div class="info-tooltip-refuse-status">
                                    {{ data.item.name }}
                                </div>
                            </b-tooltip>
                        </template>
                        <template v-slot:cell(description)="data">
                            <span :id="`tooltip-reason-description-${data.index}`">
                                {{ data.item.description }}
                            </span>
                            <b-tooltip
                                custom-class="tooltip-refuse-status"
                                triggers="hover blur"
                                :target="`tooltip-reason-description-${data.index}`"
                                positioning="top"
                                placement="top"
                                boundary-padding="0"
                            >
                                <div class="info-tooltip-refuse-status">
                                    {{ data.item.description }}
                                </div>
                            </b-tooltip>
                        </template>
                        <template v-slot:cell(site)="data">
                            <span :id="`tooltip-reason-site-${data.index}`">
                                {{ data.item.site }}
                            </span>
                            <b-tooltip
                                custom-class="tooltip-refuse-status"
                                triggers="hover blur"
                                :target="`tooltip-reason-site-${data.index}`"
                                positioning="top"
                                placement="top"
                                boundary-padding="0"
                            >
                                <div class="info-tooltip-refuse-status">
                                    {{ data.item.site }}
                                </div>
                            </b-tooltip>
                        </template>
                    </table-default>
                </div>
                <div class="demo-spacing-0">
                    <b-alert
                        variant="primary"
                        :show="flagShowOverlay == false && reasonList.length == 0 ? true : false"
                    >
                        <div class="alert-body">
                            <span>Nenhum resultado encontrado.</span>
                        </div>
                    </b-alert>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        BRow,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BAlert,
        BDropdown,
        BDropdownItem,
        BCard,
        BTooltip,
        VBToggle,
        BButton
    } from "bootstrap-vue";
    import { VueGoodTable } from "vue-good-table";
    import Ripple from "vue-ripple-directive";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import TableDefault from "@core/components/table-default/TableDefault.vue";
    import DeleteIcon from "@/assets/images/pages/trash-icon-grey.svg";
    import EditIcon from "@/assets/images/pages/edit-icon-grey.svg";
    import ModalAddEditReason from "./components/ModalAddEditReason.vue";
    import { formatSelectOption } from "@/utils/format";

    import { mapMutations, mapGetters, mapState } from "vuex";
    export default {
        components: {
            BRow,
            VueGoodTable,
            BAvatar,
            BBadge,
            BPagination,
            BFormGroup,
            BFormInput,
            BFormSelect,
            BAlert,
            BDropdown,
            BDropdownItem,
            BCard,
            ToastificationContent,
            BButton,
            BTooltip,
            TableDefault,
            EditIcon,
            DeleteIcon,
            ModalAddEditReason
        },
        directives: {
            "b-toggle": VBToggle,
            Ripple
        },
        data() {
            return {
                currentSite: this.$cookies.get("userInfo").currentSite,
                fields: [
                    {
                        label: "RefuseStatus.Action",
                        key: "action",
                        thClass: "th col-action",
                        tdClass: "td"
                    },
                    {
                        label: "RefuseStatus.Code",
                        key: "code",
                        sortable: true,
                        thClass: "th col-code",
                        tdClass: "td"
                    },
                    {
                        label: "RefuseStatus.Name",
                        key: "name",
                        sortable: true,
                        thClass: "th col-name",
                        tdClass: "td"
                    },
                    {
                        label: "RefuseStatus.Description",
                        key: "description",
                        sortable: true,
                        thClass: "th col-description",
                        tdClass: "td"
                    },
                    {
                        label: "RefuseStatus.Unit",
                        key: "site",
                        sortable: true,
                        thClass: "th col-unit",
                        tdClass: "td"
                    }
                ],
                filter: {
                    pageNumber: 1,
                    pageSize: 10,
                    totalItems: 0
                },
                rows: []
            };
        },
        methods: {
            ...mapMutations("rejectionReasons", [
                "RESET_STATES",
                "UPDATE_CONTEXT",
                "UPDATE_REASON_LIST",
                "UPDATE_MODAL_ADD_EDIT_REASON"
            ]),
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            updatepageSize(value) {
                this.filter.pageSize = value;
            },
            updatePageNumber(value) {
                this.filter.pageNumber = value;
            },
            handleOpenAddReasonModal() {
                this.$root.$emit("bv::show::modal", "modal-add-edit-reason");
            },
            async handleOpenEditReasonModal(reasonId) {
                if (!reasonId?.toString()) return;

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    await this.getRejectionReasonsContext(false);
                    await this.getRejectionReasonById(reasonId, false);
                    this.$root.$emit("bv::show::modal", "modal-add-edit-reason");
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            handleOpenRemoveReasonModal(reasonId, hasOperationProgress) {
                if (!reasonId?.toString()) return;

                if (!!hasOperationProgress) {
                    this.showToast(
                        this.$t("RefuseStatus.Error"),
                        "FrownIcon",
                        this.$t("RefuseStatus.MessageDeleteError"),
                        "danger"
                    );
                    return;
                }

                this.$swal({
                    title: this.$t("RefuseStatus.Attention"),
                    showCloseButton: true,
                    showCancelButton: true,
                    cancelButtonText: this.$t("RefuseStatus.Cancel"),
                    confirmButtonText: this.$t("RefuseStatus.Delete"),
                    text: this.$t("RefuseStatus.TextAttention"),
                    customClass: {
                        container: "swal-default",
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                }).then(async (result) => {
                    if (!result.isConfirmed) return;

                    await this.handleDeleteRejectionReason(reasonId);
                });
            },
            async handleDeleteRejectionReason(reasonId) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    await this.deleteRejectionReason(reasonId, false);
                    await this.getRejectionReasonsContext(false);
                    await this.getRejectionReasonsList(false);
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async getRejectionReasonsContext(useLoading = true) {
                const GET_REJECTION_REASONS_CONTEXT_URL = `/api/myconfig/refuse-reason/context`;

                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_REJECTION_REASONS_CONTEXT_URL);

                    if (!response.data.data) return;

                    const { sites } = response.data.data;

                    const formattedContexts = {
                        sites: sites ? [...sites?.map(formatSelectOption)] : []
                    };

                    this.UPDATE_CONTEXT({ ...formattedContexts });

                    return Promise.resolve(response.data.data);
                } catch (error) {
                    return Promise.reject(error);
                } finally {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async getRejectionReasonsList(useLoading = true) {
                const GET_REJECTION_REASONS_LIST_URL = `/api/myconfig/refuse-reason`;

                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_REJECTION_REASONS_LIST_URL);

                    if (!response.data.data) return;

                    const formattedRows =
                        response.data.data?.map((i) => {
                            const site =
                                this.context.sites?.find((j) => j.id == i.siteId)?.text ?? null;

                            const { siteId, ...restOfReason } = i;

                            return {
                                ...restOfReason,
                                site
                            };
                        }) || [];

                    this.filter = {
                        pageNumber: 1,
                        pageSize: 10,
                        totalItems: formattedRows.length
                    };

                    this.UPDATE_REASON_LIST([...formattedRows]);

                    return Promise.resolve(response.data.data);
                } catch (error) {
                    return Promise.reject(error);
                } finally {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async getRejectionReasonById(reasonId, useLoading = true) {
                if (!reasonId?.toString()) return;

                const GET_REJECTION_REASON_BY_ID_URL = `/api/myconfig/refuse-reason/${reasonId}`;
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_REJECTION_REASON_BY_ID_URL);

                    const { data } = response.data;

                    if (!data) return;

                    const site = this.context.sites.find((i) => i.id == data?.siteId) ?? null;

                    this.UPDATE_MODAL_ADD_EDIT_REASON({
                        ...this.modalAddEditReason,
                        reasonId,
                        initialName: data?.name ?? null,
                        initialCode: data?.code ?? null,
                        fields: {
                            name: data?.name ?? "",
                            code: data?.code ?? "",
                            description: data?.description ?? "",
                            site
                        }
                    });

                    return Promise.resolve(response);
                } catch (error) {
                    return Promise.reject(error);
                } finally {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async handleGetRejectionReasonsList(useLoading = true) {
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                try {
                    await this.getRejectionReasonsContext(false);
                    await this.getRejectionReasonsList(false);
                } finally {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async handleInitialRouteCalls() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                try {
                    await this.handleGetRejectionReasonsList(false);
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async deleteRejectionReason(reasonId, useLoading = true) {
                const DELETE_REJECTION_REASON_URL = `/api/myconfig/refuse-reason/${reasonId}`;
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                try {
                    await this.$http2.delete(DELETE_REJECTION_REASON_URL);

                    this.showToast(
                        this.$t("RefuseStatus.Success"),
                        "SmileIcon",
                        this.$t("RefuseStatus.MessageDeleteSuccess"),
                        "success"
                    );

                    return Promise.resolve(null);
                } catch (error) {
                    return Promise.reject(null);
                } finally {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            }
        },
        computed: {
            ...mapGetters("app", ["flagShowOverlay"]),
            ...mapState("rejectionReasons", ["reasonList", "context", "modalAddEditReason"])
        },
        mounted() {
            this.handleInitialRouteCalls();
        },
        beforeDestroy() {
            this.RESET_STATES();
        }
    };
</script>

<style lang="scss">
    @import "~@core/scss/vue/libs/vue-good-table.scss";
    @import "~@core/scss/vue/libs/vue-sweetalert.scss";

    .card-body {
        padding: 16px;
    }

    .tooltip-refuse-status {
        //não adicione padding aqui e só estilize as classes especificas se possível
        margin: 0;

        .tooltip-inner {
            padding: 0;
            max-width: 100%;
            min-width: 1%;
            border-radius: 6px;
            overflow: hidden;

            .info-tooltip-refuse-status {
                max-width: 339px;
                padding: 8px 8px;
                text-align: left;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
                letter-spacing: 0.16px;
            }
        }

        .arrow {
            &::before {
                border-top-color: #323232;
                border-bottom-color: #323232;
            }
        }
    }

    .overlay-mensage {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        flex-direction: column;
    }

    @media (max-width: 575px) {
        .tooltip-refuse-status {
            .tooltip-inner {
                .info-tooltip-refuse-status {
                    max-width: 288px !important;
                }
            }
        }

        #card-refuse-status {
            .c-header {
                margin-bottom: 0 !important;
                .btn-new-reason {
                    width: 100%;
                    margin-top: 16px;
                }
            }
        }
    }

    #card-refuse-status {
        padding: 16px;
        width: 100%;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

        @media (max-width: 768px) {
            margin-top: 24px;
        }

        .form-control {
            &::placeholder {
                color: #cfc4be;
            }

            &:focus {
                border-color: #974900;
            }
        }

        .c-header {
            width: 100%;
            display: flex;
            margin-bottom: 7px;

            .c-header-content {
                width: 100%;
            }

            .c-title {
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                color: #4c4541 !important;
            }

            .btn-new-reason {
                background: #974900 !important;
                border: none;
                padding: 4px 14px !important;

                span {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.0125em;
                }
            }
        }

        .table-reasons {
            overflow-x: hidden;
            margin-top: 0px !important;
            padding-top: 8px !important;

            .custom-search {
                padding: 0;
                margin-bottom: 8px;
            }

            .row {
                margin: 0;
            }
            table {
                thead th {
                    padding: 10.5px 9px !important;
                }

                th {
                    padding: 11px 9px !important;
                }

                td {
                    padding: 13.5px 10px !important;
                    height: 38px !important;
                    letter-spacing: 0;
                    border: 1px solid #e0e0e0 !important;
                    border-bottom: none !important;
                    border-left: none !important;
                    border-right: none !important;
                }

                tbody tr td,
                thead tr th {
                    outline: none !important;
                    border-left: none !important;
                    border-right: none !important;
                }

                .col-action {
                    padding-left: 0;
                    min-width: 57px !important;
                    max-width: 57px !important;
                    width: 1px !important;
                }

                .col-code {
                    min-width: 96px;
                    max-width: 96px;
                    width: 96px;
                }

                .col-name {
                    min-width: 320px !important;
                    max-width: 320px !important;
                    width: 320px !important;
                }

                .col-description {
                    min-width: 360px !important;
                    max-width: 100%;
                    width: 100%;
                }

                .col-unit {
                    min-width: 201px;
                    max-width: 201px;
                    width: 201px;
                }

                thead tr th {
                    background-color: rgba(151, 73, 0, 0.05) !important;
                }

                thead tr th div {
                    text-transform: uppercase;
                    font-size: 12px;
                }

                .d-action-reasons {
                    button {
                        max-width: 28px;
                        max-height: 28px;
                        min-width: 28px;
                        min-height: 28px;
                        padding: 0;

                        svg circle {
                            color: #974900 !important;
                        }
                    }

                    .dropdown-item {
                        &:hover {
                            background-color: #fbeee8;

                            svg path {
                                fill: #974900 !important;
                            }

                            span {
                                color: #974900 !important;
                            }
                        }
                    }

                    button:hover {
                        background-color: #ffdbc4 !important;
                    }

                    svg {
                        margin: 0 !important;
                    }
                }
            }
        }
    }
</style>

<style lang="scss" scoped>
    @import "~@core/scss/base/bootstrap-extended/include";
    @import "~@core/scss/base/components/variables-dark";

    .dark-layout {
        div ::v-deep {
            .b-overlay {
                .bg-light {
                    background-color: $theme-dark-body-bg !important;
                }
            }
        }
    }
</style>

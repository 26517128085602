<template>
    <draggable
        :class="['dragArea', isChild ? 'child-drag' : '', `level-${level}`]"
        tag="ul"
        :list="list"
        group="universalGroup"
        @start="saveComponentOnDragging"
        @end="onEnd"
        :disabled="isDisabled || isOrder ? true : disableDrag || device === 'mobile'"
        draggable=".child-item"
    >
        <li
            :id="el.id"
            v-for="el in list"
            :key="`${el.name}-${el.id}`"
            :data-key="el.parentId ? el.parentId : ''"
            :class="[
                'item-row',
                isChild || (isDataFields && el.type === 'field') ? 'child-item' : '',
                el.checked ? 'checked-row' : ''
            ]"
        >
            <div class="item">
                <div
                    v-if="el.child.length"
                    :class="[
                        'toggle-icon',
                        el.open == null || el.open ? '--isOpen' : '--isClosed',
                        '__no-action-default'
                    ]"
                    @click="toggleItem(el)"
                >
                    <ArrowDown />
                </div>
                <div
                    v-else
                    class="child-row"
                ></div>
                <div
                    :class="[
                        'info-block',
                        verifyIsOpen(el) ? 'openned-block' : '',
                        `level-${el.level}`,
                        el.name.replaceAll(' ', '')
                    ]"
                >
                    <div class="first-column">
                        <div
                            v-if="el.checked == null || !el.checked"
                            class="icons-and-checkbox"
                        >
                            <div
                                v-if="el.fieldType"
                                class="fields-icons"
                            >
                                <component :is="getIconByType(el.fieldType)" />
                            </div>
                            <div v-else>
                                <div
                                    v-for="icon in levelIcons"
                                    :key="icon.techName"
                                >
                                    <div
                                        v-if="icon.level == level"
                                        :class="[
                                            'icon',
                                            icon.techName === 'Department' ||
                                            icon.techName === 'Group'
                                                ? 'rounded-icon'
                                                : '',
                                            icon.techName === 'Material' ? 'material-stroke' : ''
                                        ]"
                                    >
                                        <component :is="icon.icon" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            :class="[
                                'checkbox-container',
                                el.checked ? 'checked' : '',
                                '__no-action-default'
                            ]"
                            @click="toggleCheckbox(el)"
                        >
                            <span class="icon-checkbox"><CheckIcon /></span>
                        </div>
                        <div class="text-block">
                            <p class="title">
                                {{
                                    (isMaterials && (level === 1 || level === 4)) ||
                                    (isOrder && el.level === 3)
                                        ? `${el.code} • ${el.name}`
                                        : (isMaterials && level === 3) ||
                                            (isOrder && el.level === 2)
                                          ? `${el.number} • ${el.name}`
                                          : el.name
                                }}
                            </p>
                            <p
                                v-if="
                                    (el.child.length || el.isMaterials) && !isDataFields && !isOrder
                                "
                                class="subtitle"
                            >
                                {{
                                    el.isMaterials
                                        ? `${$t("MaterialsPage.Required")} ${el.required ? formatCurrencyWithoutSymbol(el.required) : "0,00"}`
                                        : isOrder && el.level === 1
                                          ? `${el.materialCode} • ${el.materialName} ${$t("and")} ${el.routeName}`
                                          : `${el.child.length}  ${subtitlePlural(el)}`
                                }}
                            </p>

                            <p
                                v-if="isOrder"
                                class="subtitle"
                            >
                                {{ handleOrderSubtitle(el) }}
                            </p>
                        </div>
                        <div
                            v-if="el.default"
                            class="d-flex align-items-center"
                        >
                            <DefaultRouteIcon />
                        </div>
                    </div>
                    <div
                        class="second-column"
                        :ref="`optionRef-${el.id}`"
                    >
                        <div
                            v-for="item in optionIcons"
                            :key="item.title"
                            :class="[
                                !item.showOnLast || item.showOnLast === level ? '' : 'd-none',
                                showOptionIcons(item, el) ? '' : 'd-none'
                            ]"
                        >
                            <div
                                :id="
                                    el.parentId
                                        ? `${item.title}-${el.id}-${el.parentId}-level-${level}`
                                        : `${item.title}-${el.name}-${el.id}-level-${level}`
                                "
                                :class="[
                                    !item.showOnLast || item.showOnLast === level
                                        ? 'option-button'
                                        : '',
                                    item.removeOnMobile ? 'remove-on-mobile' : '',
                                    '__no-action-default'
                                ]"
                                @click="item.click(el)"
                                tabindex="0"
                            >
                                <component
                                    v-if="showOptionIcons(item, el)"
                                    :is="item.icon"
                                />
                                <div
                                    v-if="
                                        (!item.showOnLast || item.showOnLast === level) &&
                                        (el.users || el.quantityUsers !== null) &&
                                        item.title.includes('People')
                                    "
                                    class="total-amount"
                                >
                                    {{ el.users ? el.users.length : el.quantityUsers }}
                                </div>
                                <div
                                    v-if="
                                        (!item.showOnLast || item.showOnLast === level) &&
                                        el.quantityResources !== null &&
                                        item.title.includes('Resources')
                                    "
                                    class="total-amount"
                                >
                                    {{ el.quantityResources }}
                                </div>
                                <div
                                    v-if="el.child.length > 0 && item.title.includes('DataFields')"
                                    class="total-amount"
                                >
                                    {{ el.child.length }}
                                </div>
                                <Tooltip
                                    v-if="
                                        (!item.showOnLast || item.showOnLast === level) &&
                                        !item.title.includes('Info')
                                    "
                                    :target="`${item.title}-${el.name}-${el.id}-level-${level}`"
                                    :text="$t(item.title)"
                                    placement="top"
                                />
                                <Tooltip
                                    v-if="item.title.includes('DataFields')"
                                    :target="`${item.title}-${el.name}-${el.id}-level-${level}`"
                                    placement="top"
                                    >{{ $t("DataFieldsPage.DataFields") }}
                                </Tooltip>
                                <Tooltip
                                    v-if="item.title.includes('Info')"
                                    :target="
                                        el.parentId
                                            ? `${item.title}-${el.id}-${el.parentId}-level-${level}`
                                            : `${item.title}-${el.name}-${el.id}-level-${level}`
                                    "
                                    placement="top"
                                >
                                    <div
                                        v-if="!isDataFields"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label>
                                            {{
                                                $t(levelIcons.find((e) => e.level === level).title)
                                            }}
                                        </label>
                                        <span>
                                            {{ el.name }}
                                        </span>
                                    </div>
                                    <div
                                        v-else
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label>
                                            {{ $t("IntegrationPage.NameField") }}
                                        </label>
                                        <span>
                                            {{ el.name }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.number"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("MaterialsPage.Number") }} </label>
                                        <span>
                                            {{ el.number }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.guid"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> Guid </label>
                                        <span>
                                            {{ el.guid }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="
                                            levelIcons.find((e) => e.level === level).techName ===
                                                'WorkCenter' ||
                                            levelIcons.find((e) => e.level === level).techName ===
                                                'Material' ||
                                            levelIcons.find((e) => e.level === level).techName ===
                                                'UsedMaterial'
                                        "
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("ResourceStatus.Code") }} </label>
                                        <span>
                                            {{ el.code }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.serial_number || el.serialNumber"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("MaterialsPage.SerialNumber") }} </label>
                                        <span>
                                            {{
                                                el.serialNumber ? el.serialNumber : el.serial_number
                                            }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.functions"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("DepartmentPage.Function2") }} </label>
                                        <div class="d-flex">
                                            <span
                                                v-for="(func, index) in el.functions"
                                                :key="func"
                                            >
                                                <div class="functions-name">
                                                    {{ getFunctionsName(rolesList, func) }}
                                                    <span
                                                        class="comma"
                                                        v-if="index != el.functions.length - 1"
                                                        >,</span
                                                    >
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        v-if="
                                            levelIcons.find((e) => e.level === level).techName !==
                                                'WorkCenter' && el.description
                                        "
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("DepartmentPage.Description") }} </label>
                                        <span>
                                            {{ el.description }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.required === false || el.required === true"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("DataFieldsPage.MandatoryField") }} </label>
                                        <span>
                                            {{ el.required ? $t("Yes") : $t("No") }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.important === false || el.important === true"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("DataFieldsPage.ImportantField") }} </label>
                                        <span>
                                            {{ el.important ? $t("Yes") : $t("No") }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.fieldType"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("DataFieldsPage.Formatting") }} </label>
                                        <span>
                                            {{ $t(`DataFieldsPage.${el.fieldType}`) }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.exhibitionLocation && el.exhibitionLocation.length"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("DataFieldsPage.WhereToShow") }} </label>
                                        <div class="text-left">
                                            <span
                                                v-for="(item, index) of el.exhibitionLocation"
                                                :key="item"
                                            >
                                                {{
                                                    $t(
                                                        `DataFieldsPage.${
                                                            exhibitionLocationList.find(
                                                                (e) => e.id == item
                                                            ).name
                                                        }`
                                                    )
                                                }}<span
                                                    v-if="
                                                        index !== el.exhibitionLocation.length - 1
                                                    "
                                                    >,
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                    <div
                                        v-if="el.responsibleUsers"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label>{{ $t("DepartmentPage.Responsible") }}</label>
                                        <span
                                            v-for="responsible in el.responsibleUsers"
                                            :key="responsible"
                                        >
                                            {{ getResponsibleName(responsibleList, responsible) }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.priority"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("OrdersPage.Priority") }} </label>
                                        <span>
                                            {{ el.priority }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.quantity"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("OrdersPage.Quantity") }} </label>
                                        <span>
                                            {{ el.quantity }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.deliveryDate"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("OrdersPage.DeliveryDate") }} </label>
                                        <span>
                                            {{ dateFormatter(el.deliveryDate) }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.tags && el.tags.length"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> Tags </label>
                                        <div class="d-flex">
                                            <div class="tag-name">
                                                {{ el.tags.map((e) => e.name).join(" • ") }}
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        v-if="el.setupStart"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("OrdersPage.SetupStart") }} </label>
                                        <span>
                                            {{ dateFormatter(el.setupStart) }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.startTime"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("OrdersPage.OperationStart") }} </label>
                                        <span>
                                            {{ dateFormatter(el.startTime) }}
                                        </span>
                                    </div>
                                    <div
                                        v-if="el.endTime"
                                        class="tooltip-block d-flex flex-column align-items-start"
                                    >
                                        <label> {{ $t("OrdersPage.OperationEnd") }} </label>
                                        <span>
                                            {{ dateFormatter(el.endTime) }}
                                        </span>
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    v-if="item.title.includes('WorkCenter')"
                                    :target="
                                        el.parentId
                                            ? `${item.title}-${el.id}-${el.parentId}-level-${level}`
                                            : `${item.title}-${el.name}-${el.id}-level-${level}`
                                    "
                                    placement="top"
                                >
                                    <div class="text-left">
                                        <div>{{ $t("StructurePage.WorkCenter") }}:</div>
                                        <div>{{ el.workcenter }}</div>
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    v-if="item.title.includes('Actions')"
                                    :target="
                                        el.parentId
                                            ? `${item.title}-${el.id}-${el.parentId}-level-${level}`
                                            : `${item.title}-${el.name}-${el.id}-level-${level}`
                                    "
                                    placement="top"
                                >
                                    <div class="text-left">
                                        <div>{{ $t(item.title) }}</div>
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    v-if="item.title.includes('OpenMaterial')"
                                    :target="
                                        el.parentId
                                            ? `${item.title}-${el.id}-${el.parentId}-level-${level}`
                                            : `${item.title}-${el.name}-${el.id}-level-${level}`
                                    "
                                    placement="top"
                                >
                                    <div class="text-left">
                                        <div>{{ $t("MaterialsPage.OpenMaterial") }}</div>
                                    </div>
                                </Tooltip>
                                <Popover
                                    v-if="item.title.includes('Actions')"
                                    :items="removeItemsByLevel(item.actions, el)"
                                    :target="
                                        el.parentId
                                            ? `${item.title}-${el.id}-${el.parentId}-level-${level}`
                                            : `${item.title}-${el.name}-${el.id}-level-${level}`
                                    "
                                    placement="bottom"
                                    :levelIcons="levelIcons"
                                    :level="level + 1"
                                    :customStyle="isMaterials || isOrder"
                                    :isDataFields="isDataFields"
                                    :customPosition="
                                        isMaterials || isOrder
                                            ? '--max-content'
                                            : item.actions.length > 2
                                              ? '--max-content'
                                              : 'action-button'
                                    "
                                    triggers="focus"
                                    @onShow="onShow(el.id, el.type, el.parentId)"
                                    @onHidden="onHidden(el.id)"
                                    @click="editInfos(el.id, level, el.type, el.parentId)"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <nested-draggable
                v-if="forceRenderTreeDraggable"
                v-show="el.open == null || el.open"
                :list="el.child"
                :optionIcons="optionIcons"
                :levelIcons="levelIcons"
                :isChild="true"
                :level="level + 1"
                :componentParentId="el.id"
                :dragList="dragList"
                :dragListStart="dragListStart"
                :rolesList="rolesList"
                :responsibleList="responsibleList"
                :shouldUpdate="shouldUpdate"
                :isStructure="isStructure"
                :isMaterials="isMaterials"
                :isDataFields="isDataFields"
                :isOrder="isOrder"
                :isDisabled="isDisabled"
                :exhibitionLocationList="exhibitionLocationList"
                group="childGroup"
                @undoChangesTree="undoChangesTree"
                @forceRenderTreeComponentsToggle="forceRenderTreeComponentsToggle"
                @checksCounter="emitChecksCounter"
                @isAllChildrenChecked="isAllChildrenChecked"
                @disableDrag="setDisableDrag"
                @allChecksToFalse="allChecksToFalse"
                @updatedList="
                    (list, id, destinyId, idLevel) => updatedList(list, id, destinyId, idLevel)
                "
                @getEditInfos="(id, lvl, type, parentId) => editInfos(id, lvl, type, parentId)"
            />
        </li>
    </draggable>
</template>

<script>
    import draggable from "vuedraggable";
    import Tooltip from "@/@core/layouts/layout-horizontal/components/Menu/Tooltip.vue";
    import Popover from "@/@core/layouts/layout-horizontal/components/Menu/Popover.vue";
    import ArrowDown from "@core/assets/icons/arrow-down.svg";
    import CheckIcon from "@core/assets/icons/check-icon.svg";
    import TextOptionIcon from "@core/assets/icons/text-option-icon.svg";
    import TextAreaOptionIcon from "@core/assets/icons/text-area-option-icon.svg";
    import NumberOptionIcon from "@core/assets/icons/number-option-icon.svg";
    import SingleSelectOptionIcon from "@core/assets/icons/select-option-icon.svg";
    import MultiSelectOptionIcon from "@core/assets/icons/multi-select-option-icon.svg";
    import SingleDateOptionIcon from "@core/assets/icons/single-date-option-icon.svg";
    import RangeDateOptionIcon from "@core/assets/icons/range-date-option-icon.svg";
    import TimeOptionIcon from "@core/assets/icons/time-option-icon.svg";
    import CheckboxOptionIcon from "@core/assets/icons/checkbox-option-icon.svg";
    import LocationOptionIcon from "@core/assets/icons/location-option-icon.svg";
    import PhotoOptionIcon from "@core/assets/icons/picture-option-icon.svg";
    import DefaultRouteIcon from "@core/assets/icons/default-route-icon.svg";
    import ToastificationContentVue from "../toastification/ToastificationContent.vue";
    import { formatCurrencyWithoutSymbol } from "@/utils/format";

    import moment from "moment";

    const ICONS = {
        TextOptionIcon,
        TextAreaOptionIcon,
        NumberOptionIcon,
        SingleSelectOptionIcon,
        MultiSelectOptionIcon,
        SingleDateOptionIcon,
        RangeDateOptionIcon,
        TimeOptionIcon,
        CheckboxOptionIcon,
        LocationOptionIcon,
        PhotoOptionIcon
    };

    export default {
        name: "nested-draggable",
        props: {
            list: {
                required: true,
                type: Array
            },
            optionIcons: {
                type: Array
            },
            exhibitionLocationList: {
                type: Array
            },
            rolesList: {
                type: Array
            },
            responsibleList: {
                type: Array
            },
            levelIcons: {
                type: Array
            },
            dragList: {
                type: Array
            },
            dragListStart: {
                type: Array
            },
            componentParentId: {
                type: Number
            },
            isChild: {
                type: Boolean,
                default: false
            },
            shouldUpdate: {
                type: Boolean,
                default: false
            },
            device: {
                type: String,
                default: "desktop"
            },
            level: {
                type: Number,
                default: 1
            },
            isStructure: {
                type: Boolean,
                default: false
            },
            isMaterials: {
                type: Boolean,
                default: false
            },
            isDataFields: {
                type: Boolean,
                default: false
            },
            isOrder: {
                type: Boolean,
                default: false
            },
            isDisabled: {
                type: Boolean,
                default: false
            }
        },
        components: {
            draggable,
            Tooltip,
            Popover,
            ArrowDown,
            CheckIcon,
            DefaultRouteIcon
        },
        data() {
            return {
                formatCurrencyWithoutSymbol: formatCurrencyWithoutSymbol,
                forceRenderTreeDraggable: true,
                idDragged: null,
                parentIdDragged: null,
                parentIdDragged: null,
                allCheckedItems: [],
                everyCheckedItem: [],
                allLevel2: [],
                allLevel3: [],
                disableDrag: false
            };
        },
        methods: {
            handleOrderSubtitle(el) {
                if (el.isMaterials)
                    return `${this.$t("MaterialsPage.Required")} ${el.required ? formatCurrencyWithoutSymbol(el.required) : "0,00"}`;
                if (el.level === 1)
                    return `${el.materialCode} • ${el.materialName} ${this.$t("and")} ${el.routeName}`;
                if (el.child?.length) return `${el.child.length} ${this.subtitlePlural(el)}`;
                return "";
            },
            saveComponentOnDragging(e) {
                if (e) {
                    this.idDragged = e.item.id;
                    this.parentIdDragged = e.item.getAttribute("data-key");
                    this.isDraggingCheckedItem();
                }
                let depCheckedList = [];
                this.dragList.map((e) => {
                    depCheckedList.push(e.checked);
                });
                this.getAllCheckedItems(this.dragList);
                this.disableDrag = depCheckedList.includes(true);
                if (this.isDataFields) {
                    const checkedItems = this.allCheckedItems;
                    const types = new Set(checkedItems.map((item) => item.type));
                    this.disableDrag = types.size > 1;
                }
                if (this.isStructure) {
                    this.disableDrag = this.validateLevel3Children(
                        this.dragList,
                        this.everyCheckedItem
                    );
                }
                if (this.isMaterials) {
                    this.disableDrag =
                        this.validateLevel3Children(this.dragList, this.everyCheckedItem) ||
                        this.validateLevel4Children(this.dragList, this.everyCheckedItem);

                    const hasLevel1Checked = this.allCheckedItems.find((e) => e.level === 1);

                    if (hasLevel1Checked) {
                        this.disableDrag = true;
                    }
                }
                this.$emit("disableDrag", this.disableDrag);
                this.$emit("saveActualTree");
            },
            onEnd(event) {
                let eventIds = event.item.id;
                const destinyId = event.item.id;
                this.allCheckedItems = [];
                this.everyCheckedItem = [];
                this.getAllCheckedItems(this.dragList, event.item.id);
                const removedCheckedItems = this.removeItems(this.dragList, this.allCheckedItems);
                const updatedList = this.addItems(
                    removedCheckedItems,
                    event.item.id,
                    this.allCheckedItems
                );
                if (this.isStructure) {
                    eventIds = this.getIdsToUpdateList(event.item.id);
                }
                if (this.isMaterials) {
                    eventIds = this.getIdsToUpdateListMaterials(event.item.id);
                }
                this.updatedList(updatedList, eventIds, destinyId, this.level);
                this.verifyUndoChanges(event);
                this.forceRenderTreeComponentsToggle();
            },
            showOptionIcons(item, elem) {
                if (this.isDataFields) {
                    if (item.title.includes("DataFields") && elem.type === "field") {
                        return null;
                    }
                    if (item.title.includes("Info") && elem.type === "group") {
                        return null;
                    }
                    return item;
                }
                return !item.showOnLast || item.showOnLast === this.level;
            },
            getIdsToUpdateList(id) {
                if (this.allLevel2.length !== 0) {
                    let arr = [];
                    this.everyCheckedItem.map((e) => {
                        if (e.level === 2) {
                            arr.push(e.id);
                        }
                    });
                    return arr;
                } else {
                    let allIds = this.allCheckedItems.map((e) => e.id);
                    allIds.push(Number(id));
                    return allIds;
                }
            },
            getIdsToUpdateListMaterials(id) {
                if (this.allLevel2.length !== 0) {
                    let arr = [];
                    this.everyCheckedItem.map((e) => {
                        if (e.level === 2) {
                            arr.push({ id: e.id });
                        }
                    });
                    return arr;
                } else if (this.allLevel3.length !== 0) {
                    let arr = [];
                    this.everyCheckedItem.map((e) => {
                        if (e.level === 3) {
                            arr.push({ id: e.id });
                        }
                    });
                    return arr;
                } else {
                    let allIds = this.allCheckedItems.map((e) => {
                        return {
                            id: e.id,
                            originId: e.parentId
                        };
                    });
                    allIds.push({
                        id: Number(id),
                        originId: this.parentIdDragged ? Number(this.parentIdDragged) : null
                    });
                    return allIds;
                }
            },
            validateLevel3Children(data, validationArray) {
                let arr = [];
                validationArray.map((obj) => {
                    if (obj.level === 3) {
                        arr.push(this.isLevel2Child(data, obj.id));
                    }
                });
                return arr.includes(false) ? true : false;
            },
            isLevel2Child(data, level3ObjectId) {
                this.allLevel2 = [];
                this.getAllLevel2(data);

                let arr = [];

                if (this.allLevel2.length === 0) return true;

                this.allLevel2.filter((e) => {
                    if (e.child.length) {
                        const isChild = e.child.find((f) => f.id === level3ObjectId);
                        if (isChild) {
                            arr.push(true);
                        }
                    }
                });
                return arr.length === 0 ? false : true;
            },
            validateLevel4Children(data, validationArray) {
                let arr = [];
                validationArray.map((obj) => {
                    if (obj.level === 4) {
                        arr.push(this.isLevel3Child(data, obj.id, obj.parentId));
                    }
                });
                return arr.includes(false) ? true : false;
            },
            isLevel3Child(data, level4ObjectId, level4ObjectParentId) {
                this.allLevel3 = [];
                this.getAllLevel3(data);

                let arr = [];

                if (this.allLevel3.length === 0) return true;

                this.allLevel3.filter((e) => {
                    if (e.child.length) {
                        const isChild = e.child.find(
                            (f) => f.id === level4ObjectId && f.parentId === level4ObjectParentId
                        );

                        if (isChild) {
                            arr.push(true);
                        }
                    }
                });
                return arr.length === 0 ? false : true;
            },
            getAllLevel2(arr) {
                arr.map((e) => {
                    if (e.level === 2 && e.checked) {
                        this.allLevel2.push(e);
                    }
                    if (e.child) this.getAllLevel2(e.child);
                });
            },
            getAllLevel3(arr) {
                arr.map((e) => {
                    if (e.level === 3 && e.checked) {
                        this.allLevel3.push(e);
                    }
                    if (e.child) this.getAllLevel3(e.child);
                });
            },
            updatedList(list, id, destinyId, level) {
                this.$emit("updatedList", list, id, destinyId, level);
            },
            dateFormatter(value) {
                return moment(value).format("DD/MM/YYYY HH:mm");
            },
            subtitlePlural(el) {
                let title = this.$t(this.levelIcons.find((e) => e.level === this.level + 1).title);
                const currentLanguage = this.$cookies.get("userInfo").language;

                if (currentLanguage === "en-US") {
                    return `${title}${el.child.length > 1 ? "s" : ""}`;
                }

                if (currentLanguage === "pt-BR" && el.child.length > 1) {
                    if (title.endsWith("ão")) {
                        title = title.replace(/ão$/g, "ões");
                    } else if (title.endsWith("al")) {
                        title = title.replace(/al$/g, "ais");
                    }

                    if (
                        title.endsWith("a") ||
                        title.endsWith("e") ||
                        title.endsWith("i") ||
                        title.endsWith("o") ||
                        title.endsWith("u")
                    ) {
                        title += "s";
                    }
                }
                return title;
            },
            getLastItem(arr, str) {
                return arr.split(str).slice(-1)[0];
            },
            getResponsibleName(list, id) {
                if (!list || list.length === 0) {
                    return null;
                }

                for (const item of list) {
                    if (item.value === id) {
                        return item.text;
                    }

                    if (item.child && item.child.length > 0) {
                        const foundItem = this.getResponsibleName(item.child, id);
                        if (foundItem) {
                            return foundItem;
                        }
                    }
                }

                return null;
            },
            getFunctionsName(list, id) {
                if (!list || list.length === 0) {
                    return null;
                }

                for (const item of list) {
                    if (item.id == id) {
                        return item.name;
                    }

                    if (item.child && item.child.length > 0) {
                        const foundItem = this.getFunctionsName(item.child, id);
                        if (foundItem) {
                            return foundItem;
                        }
                    }
                }

                return null;
            },
            isDraggingCheckedItem() {
                const object = this.getObjectById(
                    this.dragList,
                    this.idDragged,
                    this.level,
                    this.parentIdDragged
                );
                if (object.checked == null || !object.checked) {
                    this.allChecksToFalse(this.dragList);
                } else {
                    document.querySelectorAll(".item-row.checked-row").forEach((element) => {
                        if (this.isMaterials && this.idDragged === element.id) {
                            this.parentIdDragged = element.getAttribute("data-key");
                        }
                        if (this.idDragged !== element.id) element.style.display = "none";
                    });
                }
            },
            addItems(arr, id, newItems) {
                return arr.reduce((acc, item) => {
                    if (item.id == id) {
                        return [...acc, item, ...newItems];
                    } else if (item.child.length > 0) {
                        return [
                            ...acc,
                            { ...item, child: this.addItems(item.child, id, newItems) }
                        ];
                    } else {
                        return [...acc, item];
                    }
                }, []);
            },
            removeItemsByLevel(items, el) {
                if (this.isOrder) {
                    return items.map((e) => {
                        if (e.title.includes("New") && this.level !== 1) {
                            return null;
                        }
                        if (e.title.includes("LinkMaterial") && this.level !== 2) {
                            return null;
                        }
                        if (e.title.includes("UnlinkMaterial") && this.level !== 3) {
                            return null;
                        }
                        if (e.title.includes("LinkOperation") && this.level !== 1) {
                            return null;
                        }
                        if (
                            e.title.includes("UnlinkOperation") &&
                            (el.canBeDeleted || el.level !== 2)
                        ) {
                            return null;
                        }
                        if (
                            el.level === 2 &&
                            !el.canBeDeleted &&
                            (e.title.includes("Duplicate") || e.title.includes("Delete"))
                        ) {
                            return null;
                        }
                        if (
                            e.title.includes("Duplicate") &&
                            this.level == this.getLastLevel(this.levelIcons)
                        ) {
                            return null;
                        }
                        if (
                            e.title.includes("Delete") &&
                            this.level == this.getLastLevel(this.levelIcons)
                        ) {
                            return null;
                        }

                        return e;
                    });
                }

                if (this.isMaterials) {
                    return items.map((e) => {
                        if (e.title.includes("New") && (this.level === 3 || this.level === 4)) {
                            return null;
                        }
                        if (e.title.includes("Link") && this.level !== 3) {
                            return null;
                        }
                        if (e.title.includes("Unlink") && this.level !== 4) {
                            return null;
                        }
                        if (
                            e.title.includes("Duplicate") &&
                            this.level == this.getLastLevel(this.levelIcons)
                        ) {
                            return null;
                        }
                        if (
                            e.title.includes("Delete") &&
                            this.level == this.getLastLevel(this.levelIcons)
                        ) {
                            return null;
                        }
                        if (e.title.includes("New") && this.level === 2) {
                            return { ...e, title: "DepartmentPage.Newa" };
                        }
                        return e;
                    });
                }
                return items.map((e) => {
                    if (
                        e.title.includes("New") &&
                        this.level === this.getLastLevel(this.levelIcons)
                    ) {
                        return null;
                    }

                    return e;
                });
            },
            getLastLevel(data) {
                let lastLevel = -Infinity;
                for (const item of data) {
                    const level = typeof item.level === "number" ? item.level : -Infinity;
                    lastLevel = Math.max(lastLevel, level);
                }

                return lastLevel;
            },
            verifyUndoChanges(event) {
                const toClasses = event.to.classList;
                const toSeparatedClasses = this.getLastItem(toClasses.value, " ");
                const toLastSeparatedClass = this.getLastItem(toSeparatedClasses, "-");
                const fromClasses = event.from.classList;
                const fromSeparatedClasses = this.getLastItem(fromClasses.value, " ");
                const fromLastSeparatedClass = this.getLastItem(fromSeparatedClasses, "-");
                const hasLevel2Checked = this.allLevel2.length > 0;

                if (this.isStructure) {
                    if (hasLevel2Checked && toLastSeparatedClass === "3") {
                        this.undoChangesTree();
                    } else if (
                        !hasLevel2Checked &&
                        toLastSeparatedClass !== fromLastSeparatedClass
                    ) {
                        this.undoChangesTree();
                    } else if (toLastSeparatedClass === "1") {
                        this.undoChangesTree();
                    }
                } else if (this.isDataFields && toLastSeparatedClass === "3") {
                    this.undoChangesTree();
                } else if (toLastSeparatedClass !== fromLastSeparatedClass) {
                    this.undoChangesTree();
                }
            },
            getAllCheckedItems(arr, id) {
                arr.map((e) => {
                    if (e.checked && e.level > 1) {
                        this.everyCheckedItem.push(e);
                    }
                    if (e.checked && e.id != id) {
                        this.allCheckedItems.push(e);
                    }
                    if (e.child) this.getAllCheckedItems(e.child, id);
                });
            },
            removeItems(arr, itemsToRemove) {
                return arr
                    .filter(
                        (item) => !itemsToRemove.some((removeItem) => removeItem.id === item.id)
                    )
                    .map((item) => ({
                        ...item,
                        child: this.removeItems(item.child, itemsToRemove)
                    }));
            },
            getIconByType(type) {
                if (type === "input_text") {
                    return ICONS.TextOptionIcon;
                }
                if (type === "input_numeric") {
                    return ICONS.NumberOptionIcon;
                }
                if (type === "select_unique") {
                    return ICONS.SingleSelectOptionIcon;
                }
                if (type === "select_multiple") {
                    return ICONS.MultiSelectOptionIcon;
                }
                if (type === "input_text_area") {
                    return ICONS.TextAreaOptionIcon;
                }
                if (type === "datepicker") {
                    return ICONS.SingleDateOptionIcon;
                }
                if (type === "range_dates") {
                    return ICONS.RangeDateOptionIcon;
                }
                if (type === "input_hour") {
                    return ICONS.TimeOptionIcon;
                }
                if (type === "checkbox") {
                    return ICONS.CheckboxOptionIcon;
                }
                if (type === "location") {
                    return ICONS.LocationOptionIcon;
                }
                if (type === "photo") {
                    return ICONS.PhotoOptionIcon;
                }
                return null;
            },
            getObjectById(arr, id, level, parentId) {
                for (const obj of arr) {
                    if (
                        obj.isMaterials &&
                        obj.id == id &&
                        obj.level === level &&
                        obj.parentId == parentId
                    ) {
                        return obj;
                    }
                    if (
                        (obj.level > 1 || this.isDataFields) &&
                        obj.id == id &&
                        obj.level === level
                    ) {
                        return obj;
                    }

                    if (obj.child && obj.child.length > 0) {
                        const object = this.getObjectById(obj.child, id, level, parentId);
                        if (object) {
                            return object;
                        }
                    }
                }

                return null;
            },
            allChecksToFalse(arr) {
                const elements = document.querySelectorAll(".checkbox-container");
                const icons = document.querySelectorAll(".icons-and-checkbox");
                for (const element of elements) {
                    element.style.display = "none";
                }
                for (const icon of icons) {
                    icon.style.display = "block";
                }
                this.disableDrag = false;
                this.$emit("allChecksToFalse", arr);
            },
            verifyDiffChildsIsChecked(list) {
                let childCheckeds = 0;

                for (const item of list) {
                    if (this.verifyDiffChildsIsChecked(item)) {
                        childCheckeds++;
                    }
                }

                return childCheckeds <= 1;
            },
            recursiveDiffChildsChekced(list) {
                if (list.checked) {
                    return true;
                }

                for (const item of list.child) {
                    if (this.recursiveDiffChildsChekced(item)) {
                        return true;
                    }
                }

                return false;
            },
            emitChecksCounter() {
                this.$emit("checksCounter");
            },
            isAllChildrenChecked() {
                this.$emit("isAllChildrenChecked");
            },
            setDisableDrag() {
                this.$emit("disableDrag", this.disableDrag);
            },
            onShow(id, type, parentId) {
                const optionRef = `optionRef-${id}`;
                this.$refs[optionRef][0].classList.add("popover-openned");
                this.editInfos(id, this.level, type, parentId);
            },
            onHidden(id) {
                const optionRef = `optionRef-${id}`;
                this.$refs[optionRef][0].classList.remove("popover-openned");
            },
            editInfos(id, level, type, parentId) {
                this.$emit("getEditInfos", id, level, type, parentId);
            },
            verifyIsOpen(elem) {
                const isOpen = elem.open == null || elem.open;
                if (this.isChild) {
                    return isOpen && elem.child.length > 0;
                } else {
                    return isOpen && elem.child.length > 0;
                }
            },
            undoChangesTree() {
                this.$emit("undoChangesTree");
            },
            toggleItem(elem) {
                if (typeof elem.open == "undefined" || elem.open == true) {
                    elem["open"] = false;
                } else {
                    elem["open"] = true;
                }
                this.$emit("forceRenderTreeComponentsToggle");
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContentVue,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            },
            toggleCheckbox(elem) {
                this.getAllCheckedItems(this.dragList);
                const hasOrderChecked = this.allCheckedItems.find(
                    (e) => e.level === 1 && e.checked
                );
                const hasOtherLevelsChecked = this.allCheckedItems.find(
                    (e) => e.level !== 1 && e.checked
                );

                if (
                    (this.isOrder && hasOrderChecked && elem.level !== 1) ||
                    (this.isOrder && hasOtherLevelsChecked && elem.level === 1)
                ) {
                    this.showToast(
                        this.$t("Error"),
                        "FrownIcon",
                        this.$t("OrdersPage.CheckError"),
                        "danger"
                    );
                    return;
                }

                if (!elem.checked) {
                    elem["checked"] = true;
                    if (!this.isOrder || (this.isOrder && elem.level !== 1)) {
                        this.checkChildren(elem);
                    }
                } else {
                    elem["checked"] = false;
                }
                if (!this.isOrder || (this.isOrder && elem.level !== 1))
                    this.isAllChildrenChecked();
                this.$emit("forceRenderTreeComponentsToggle");
                this.emitChecksCounter();
            },
            checkChildren(item) {
                if (item.child) {
                    item.child.forEach((child) => {
                        child["checked"] = true;
                        this.checkChildren(child);
                    });
                }
            },
            forceRenderTreeComponentsToggle() {
                this.forceRenderTreeDraggable = false;
                this.$nextTick().then(() => {
                    this.emitChecksCounter();
                    this.forceRenderTreeDraggable = true;
                });
            }
        },
        beforeMount() {
            this.saveComponentOnDragging();
        },
        watch: {
            shouldUpdate() {
                this.allChecksToFalse(this.dragList);
                this.forceRenderTreeComponentsToggle();
                this.saveComponentOnDragging();
            },
            device(v) {
                if (v === "mobile") {
                    this.allChecksToFalse(this.dragList);
                } else {
                    this.forceRenderTreeComponentsToggle();
                }
            },
            disableDrag(v) {
                this.$emit("disableDrag", v);
            }
        }
    };
</script>

<style lang="scss">
    .tooltip-inner:has(.tooltip-block) {
        max-width: 300px !important;
        min-width: 300px !important;
        width: 100% !important;
    }
    .tooltip-block {
        display: flex;
        flex-direction: column;
        align-items: start;
        text-align: left;
        padding-bottom: 10px;
        font-size: 14px;
        line-height: 20px;

        label {
            color: #fff;
            margin: 0;
            font-weight: 600;
        }

        span {
            font-weight: 400;
            text-align: left;
        }

        .functions-name,
        .tag-name {
            display: flex;
            flex-wrap: wrap;

            .comma {
                padding-right: 4px;
            }
        }

        &:last-child {
            padding-bottom: 0;
        }
    }
</style>

<style scoped lang="scss">
    .dragArea {
        list-style-type: none;
        padding-left: 16px;

        &.child-drag {
            .item-row {
                border-left: 1px solid #974900;
                padding-left: 16px;
                margin-left: -8px;
            }
        }

        .item-row {
            .item {
                display: flex;
                align-items: center;
                gap: 8px;

                .toggle-icon {
                    display: flex;
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                    border: 1px solid #cfc4be;
                    cursor: pointer;

                    &.--isOpen {
                        transform: rotate(180deg);
                    }
                    &.--isOpen {
                        transform: rotate(-180deg);
                    }

                    svg {
                        fill: #4c4541;
                    }

                    &:hover {
                        border-color: #974900;
                        background-color: #ffede2;

                        svg {
                            fill: #974900;
                        }
                    }

                    &:active {
                        border-color: #974900;
                        background-color: #ffdbc4;

                        svg {
                            fill: #974900;
                        }
                    }
                }

                .info-block {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    padding: 4px 8px;
                    border-radius: 4px;
                    cursor: grabbing;

                    &.normal-cursor {
                        cursor: auto;
                    }

                    &:has(.first-column:active) {
                        background-color: #ffdbc4;
                    }

                    .first-column {
                        display: flex;
                        min-height: 28px;
                        gap: 8px;
                        width: 100%;
                        align-items: center;

                        .fields-icons {
                            svg {
                                fill: #4c4541;
                                width: 18px;
                                height: 18px;
                            }
                        }
                    }
                    .second-column {
                        display: flex;
                        opacity: 0;
                        max-height: 28px;
                        &.popover-openned {
                            display: flex;
                            gap: 8px;
                        }
                        .option-button {
                            display: flex;
                            align-items: center;
                            gap: 8px;
                            cursor: pointer;
                            padding: 8px;
                            border-radius: 6px;
                            svg {
                                width: 12px;
                                height: 12px;
                                fill: #974900;
                            }

                            .total-amount {
                                color: #974900;
                                font-variant-numeric: lining-nums proportional-nums;
                                font-size: 10px;
                                font-weight: 600;
                                line-height: 14px;
                            }

                            &:hover {
                                background: #ffede2;
                            }
                            &:active {
                                background: #ffdbc4;
                            }
                        }
                    }

                    &.openned-block {
                        .icon {
                            &.rounded-icon {
                                border-color: #974900;
                            }
                            &.material-stroke {
                                svg {
                                    stroke: #974900;
                                }
                            }
                            svg {
                                fill: #974900;
                            }
                        }
                        .text-block {
                            .title,
                            .subtitle {
                                color: #974900;
                            }
                        }
                    }
                    .icon {
                        display: flex;
                        width: 18px;
                        height: 18px;
                        border-radius: 50%;
                        &.rounded-icon {
                            border: 1px solid #4c4541;
                        }
                        svg {
                            fill: #4c4541;
                        }
                    }

                    .checkbox-container {
                        display: none;
                        background-color: #fff;
                        border: 1px solid #cfc4be;
                        border-radius: 3px;
                        cursor: pointer;
                        width: 18px;
                        height: 18px;

                        .icon-checkbox {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 2px;
                            svg {
                                width: 12px;
                                height: 12px;
                            }
                        }
                        &.checked {
                            background-color: #974900;
                            display: block;
                        }
                    }

                    .text-block {
                        .title,
                        .subtitle {
                            overflow: hidden;
                            font-variant-numeric: lining-nums proportional-nums;
                            text-overflow: ellipsis;
                            margin: 0;
                        }

                        .title {
                            color: #4c4541;
                            font-size: 14px;
                            font-weight: 600;
                            line-height: 20px;
                        }

                        .subtitle {
                            color: #7e7570;
                            font-size: 12px;
                            font-weight: 400;
                            line-height: 16px;
                        }
                    }

                    &:hover,
                    &:has(.popover-openned) {
                        background-color: #ffede2;

                        .first-column {
                            .icons-and-checkbox {
                                display: none;
                            }
                        }

                        .second-column {
                            opacity: 1;
                            gap: 8px;
                        }
                        .icon {
                            display: none;
                        }

                        .checkbox-container {
                            display: block;
                        }

                        @media (max-width: 480px) {
                            .text-block {
                                max-width: 85px;
                            }
                        }
                        .text-block {
                            .title,
                            .subtitle {
                                white-space: nowrap;
                                color: #974900;
                            }
                        }
                    }

                    &:active {
                        .text-block {
                            .title,
                            .subtitle {
                                color: #974900;
                            }
                        }
                    }
                }

                .child-row {
                    width: 16px;
                }
            }
        }
    }

    @media (max-width: 480px) {
        .dragArea {
            .item-row {
                .item {
                    .info-block {
                        .first-column {
                            .checkbox-container {
                                display: none !important;
                            }
                            .icons-and-checkbox {
                                display: flex !important;
                            }
                        }
                        &:hover {
                            .first-column {
                                .checkbox-container {
                                    display: none !important;
                                }
                                .icons-and-checkbox {
                                    display: flex !important;
                                }
                            }
                            .icon {
                                display: flex !important;
                                &.rounded-icon {
                                    border-color: #974900;
                                }
                                svg {
                                    fill: #974900;
                                }
                            }
                        }
                        .second-column {
                            .remove-on-mobile {
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
</style>

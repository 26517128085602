<template>
    <div class="d-flex">
        <modal-step-by-step
            v-if="device !== 'mobile' && !modalTitle.includes('Edit')"
            :modalTitle="modalTitle"
            :isOpen="isOpen"
            :stepPosition="currentStep"
        />
        <b-sidebar
            id="add-edit-operation-sidebar-right"
            :class="['sidebar-add-edit-operation', modalTitle.includes('Edit') ? 'is-edit' : '']"
            right
            bg-variant="white"
            :width="device === 'desktop' ? '407px' : device === 'tablet' ? '60vw' : '100vw'"
            v-model="isOpen"
            @hidden="closeModal"
        >
            <template #header>
                <div class="header-sidebar">
                    <div class="sidebar-title">
                        {{
                            modalTitle.includes("Edit")
                                ? $t(modalTitle)
                                : $t(getModalTitle(currentStep))
                        }}
                    </div>
                    <span
                        v-if="modalTitle.includes('Edit')"
                        class="close-icon"
                        @click="closeModal()"
                    >
                        <CloseIcon />
                    </span>
                </div>
                <div class="separator"></div>
                <div
                    v-if="modalTitle.includes('Edit')"
                    class="modal-navbar"
                >
                    <div
                        :class="['info', currentTab === 'info' ? 'active' : '']"
                        @click="currentTab = 'info'"
                    >
                        {{ $t("Informations") }}
                    </div>
                    <div
                        :class="['times', currentTab === 'times' ? 'active' : '']"
                        @click="currentTab = 'times'"
                    >
                        {{ $t("MaterialsPage.Times") }}
                    </div>
                    <div
                        :class="['attachments', currentTab === 'attachments' ? 'active' : '']"
                        @click="currentTab = 'attachments'"
                    >
                        {{ $t("MaterialsPage.Attachments") }}
                    </div>
                </div>
            </template>
            <template>
                <div v-if="currentTab === 'info'">
                    <b-col md="12">
                        <b-form-group
                            :label="$t('DepartmentPage.Name')"
                            label-for="name"
                            :invalid-feedback="
                                flagNameEqual
                                    ? this.$t('MaterialsPage.EqualOperationName')
                                    : this.$t('RequiredField')
                            "
                        >
                            <b-form-input
                                id="name"
                                v-model="name"
                                :placeholder="$t('ResourceStatus.Placeholder')"
                                :state="states.name"
                                lazy-formatter
                                :formatter="
                                    () => {
                                        states.name = name !== '';
                                        return name;
                                    }
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="$t('MaterialsPage.Number')"
                            label-for="number"
                            :invalid-feedback="$t('RequiredField')"
                        >
                            <b-form-input
                                id="number"
                                v-model="number"
                                :placeholder="$t('ResourceStatus.Placeholder')"
                                :state="states.number"
                                lazy-formatter
                                type="text"
                                :formatter="
                                    () => {
                                        states.number = number !== '';
                                        return number;
                                    }
                                "
                                @input.native="
                                    (e) => {
                                        const maskApplied = e.target.value.replace(/[^0-9]/g, '');
                                        number = maskApplied;
                                        e.target.value = maskApplied;
                                    }
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="$t('DepartmentPage.Description')"
                            label-for="description"
                            :invalid-feedback="$t('RequiredField')"
                        >
                            <b-form-textarea
                                id="description"
                                v-model="description"
                                :placeholder="$t('ResourceStatus.Placeholder')"
                                :state="states.description"
                                no-resize
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="12"
                        class="form-group"
                    >
                        <Multiselect
                            label="Tags"
                            :fieldPlaceholder="$t('Select')"
                            :status="states.tags"
                            :feedback="$t('RequiredField')"
                            :datalist="tagsList"
                            :notFoundMessage="$t('NoResultsFound')"
                            :selectedItems.sync="tags"
                            :searchPlaceholder="$t('message.SearchPlaceholderDefault')"
                        />
                    </b-col>
                    <b-col
                        md="12"
                        class="form-group"
                    >
                        <label for="workcenter-select">
                            {{ $t("Workcenters") }}
                        </label>
                        <SingleSelect
                            id="workcenter-select"
                            :placeholder="$t('Select')"
                            :optionSelected="
                                workcentersList.find((item) => item.value == workcenter)
                            "
                            :options="workcentersList"
                            :searchable="true"
                            :translate="true"
                            :state="states.workcenter"
                            @input="(value) => (workcenter = value ? value.value : value)"
                        >
                            {{ $t("RequiredField") }}
                        </SingleSelect>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="$t('MaterialsPage.Note')"
                            label-for="note"
                            :invalid-feedback="$t('RequiredField')"
                        >
                            <b-form-textarea
                                id="note"
                                v-model="note"
                                :placeholder="$t('ResourceStatus.Placeholder')"
                                :state="states.note"
                            />
                        </b-form-group>
                    </b-col>
                    <div v-if="customFields.length && forceRenderIndustrialParametersList">
                        <div
                            v-for="field in displayImportantFirst"
                            :key="field.id"
                        >
                            <custom-fields
                                :field="field"
                                :hasError="states.customFieldsForceError"
                                @input="
                                    (value) => {
                                        field.value = value ? value : null;
                                    }
                                "
                            />
                        </div>
                    </div>
                </div>
                <div v-show="currentTab === 'times'">
                    <div class="custom-switch-button">
                        <div class="d-flex align-items-center">
                            <b-form-checkbox
                                v-model="cycleSpecificTime"
                                name="default-time-switch"
                                switch
                                class="check-button p-0"
                                @change="(e) => (cycleSpecificTime = e)"
                            />
                            <div class="text-allow">{{ $t("MaterialsPage.TimesText") }}</div>
                        </div>
                    </div>
                    <b-col md="12">
                        <b-form-group
                            :label="$t('MaterialsPage.Cycle')"
                            label-for="cycle"
                            :invalid-feedback="$t('RequiredField')"
                        >
                            <b-form-input
                                id="cycle"
                                v-model="cycle"
                                :placeholder="$t('MaterialsPage.TimesPlaceholder')"
                                :state="states.cycle"
                                type="text"
                                :disabled="cycleSpecificTime"
                                @input.native="
                                    (e) => {
                                        const maskApplied = e.target.value.replace(/[^0-9]/g, '');
                                        cycle = maskApplied;
                                        e.target.value = maskApplied;
                                    }
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="$t('MaterialsPage.ValueAggregation')"
                            label-for="valueAggregation"
                            :invalid-feedback="$t('RequiredField')"
                        >
                            <b-form-input
                                id="valueAggregation"
                                v-model="valueAggregation"
                                :placeholder="$t('MaterialsPage.TimesPlaceholder')"
                                :state="states.valueAggregation"
                                type="text"
                                :disabled="cycleSpecificTime"
                                @input.native="
                                    (e) => {
                                        const maskApplied = e.target.value.replace(/[^0-9]/g, '');
                                        valueAggregation = maskApplied;
                                        e.target.value = maskApplied;
                                    }
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <div v-if="forceRenderIndustrialParametersList">
                        <custom-fields
                            :field="{
                                id: 'setup-start-date-datepicker',
                                label: $t('OrdersPage.SetupStart'),
                                industrialStructParameterType: 'datetime',
                                required: true,
                                value: setupStart
                            }"
                            :hasError="isToValidate && (startTime !== '' || endTime !== '')"
                            :errorMessage="$t('RequiredField')"
                            @input="(value) => (setupStart = value)"
                        />
                        <custom-fields
                            :field="{
                                id: 'operation-start-date-datepicker',
                                label: $t('OrdersPage.OperationStart'),
                                industrialStructParameterType: 'datetime',
                                required: true,
                                value: startTime
                            }"
                            :hasError="isToValidate && (setupStart !== '' || endTime !== '')"
                            :errorMessage="$t('RequiredField')"
                            @input="(value) => (startTime = value)"
                        />
                        <custom-fields
                            :field="{
                                id: 'operation-end-date-datepicker',
                                label: $t('OrdersPage.OperationEnd'),
                                industrialStructParameterType: 'datetime',
                                required: true,
                                value: endTime
                            }"
                            :hasError="isToValidate && (setupStart !== '' || startTime !== '')"
                            :errorMessage="$t('RequiredField')"
                            @input="(value) => (endTime = value)"
                        />
                    </div>
                </div>
                <div
                    v-if="currentTab === 'attachments'"
                    class="h-100"
                >
                    <input
                        type="file"
                        name="editfiles"
                        multiple
                        ref="editfiles"
                        style="display: none"
                        @change="handleFileChange"
                    />
                    <div
                        v-if="attachments.length === 0"
                        class="h-100"
                    >
                        <div class="no-attachments h-100">
                            <EmptyBoxIcon class="empty-box" />
                            <p class="title">{{ $t("MaterialsPage.NoAttachmentTitle") }}</p>
                            <p class="subtitle">
                                {{ $t("MaterialsPage.NoAttachmentSubtitle") }}
                            </p>
                            <b-button
                                class="btn-new-attachments"
                                @click="addNewAttachment()"
                            >
                                <AttachmentsIcon class="add-icon" />
                                {{ $t("MaterialsPage.AttachFile") }}
                            </b-button>
                        </div>
                    </div>
                    <div v-else>
                        <b-col md="12">
                            <b-button
                                class="btn-new-attachment"
                                @click="addNewAttachment()"
                            >
                                {{ $t("MaterialsPage.Attachfile") }}
                            </b-button>
                        </b-col>
                        <div class="attached-files">
                            {{ $t("MaterialsPage.AttachedFiles") }}
                        </div>
                        <div
                            v-for="(file, index) in attachments"
                            :key="file.name + '-' + index"
                            class="attached-file"
                        >
                            <div class="icon-name">
                                <component
                                    :is="file.icon"
                                    class="icon"
                                />
                                <div class="text">{{ file.name }}</div>
                            </div>
                            <div
                                class="delete-file"
                                @click="removeFile(index)"
                            >
                                <TrashIcon />
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="separator"></div>
                <div
                    v-if="device === 'mobile' && !modalTitle.includes('Edit')"
                    class="step-action"
                >
                    <div class="d-flex justify-content-between">
                        <div
                            :class="[
                                'step-collapse',
                                isStepBlockOpenned ? 'not-collapsed' : 'collapsed'
                            ]"
                        >
                            {{ `${currentStep} ${$t("Of")} 3`
                            }}<span
                                id="step-collapse"
                                @click="openStepBlock()"
                                class="icon"
                            >
                                <ArrowDownIcon
                            /></span>
                        </div>
                        <div class="text">{{ $t(getModalTitle(currentStep)) }}</div>
                    </div>
                    <div class="step-progress">
                        <div
                            :class="[
                                'first-step',
                                currentStep === 1
                                    ? 'step-active'
                                    : currentStep > 1
                                      ? 'next-step'
                                      : ''
                            ]"
                        ></div>
                        <div
                            :class="[
                                'second-step',
                                currentStep === 2
                                    ? 'step-active'
                                    : currentStep > 2
                                      ? 'next-step'
                                      : ''
                            ]"
                        ></div>
                        <div :class="['third-step', currentStep === 3 ? 'step-active' : '']"></div>
                    </div>
                    <b-collapse :visible="isStepBlockOpenned">
                        <div class="progress-block">
                            <div class="separator-progress"></div>
                            <div class="detail-block">
                                <div
                                    :class="[
                                        'info-block',
                                        currentStep === 1
                                            ? 'step-active'
                                            : currentStep > 1
                                              ? 'next-step'
                                              : ''
                                    ]"
                                >
                                    <div class="icon"><InfoIcon /></div>
                                    <div class="text">
                                        {{ $t("PeoplePage.GeneralInformation") }}
                                    </div>
                                </div>
                                <div
                                    :class="[
                                        'vertical-separator',
                                        currentStep === 2
                                            ? 'next-step'
                                            : currentStep > 2
                                              ? 'next-step'
                                              : ''
                                    ]"
                                ></div>
                                <div
                                    :class="[
                                        'times-block',
                                        currentStep === 2
                                            ? 'step-active'
                                            : currentStep > 2
                                              ? 'next-step'
                                              : ''
                                    ]"
                                >
                                    <div class="icon"><TimesIcon /></div>
                                    <div class="text">{{ $t("MaterialsPage.Times") }}</div>
                                </div>
                                <div
                                    :class="[
                                        'vertical-separator',
                                        currentStep === 3 ? 'next-step' : ''
                                    ]"
                                ></div>
                                <div
                                    :class="[
                                        'attachments-block',
                                        currentStep === 3 ? 'step-active' : ''
                                    ]"
                                >
                                    <div class="icon"><AttachmentsIcon /></div>
                                    <div class="text">{{ $t("MaterialsPage.Attachments") }}</div>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div class="separator-progress"></div>
                <b-col
                    md="12"
                    class="footer-sidebar"
                >
                    <div class="d-flex buttons">
                        <b-button
                            class="btn btn-cancel btn-footer btn-cancel-outline"
                            @click="emitCancel()"
                        >
                            {{ $t("RefuseStatus.Cancel") }}
                        </b-button>
                        <b-button
                            v-if="!modalTitle.includes('Edit') && currentStep !== 1"
                            class="btn-previous btn-footer"
                            @click="previousStep()"
                        >
                            {{ $t("PeoplePage.Previous") }}
                        </b-button>
                        <b-button
                            v-if="modalTitle.includes('Edit') || currentStep === 3"
                            class="btn btn-save btn-footer"
                            @click="onSave()"
                        >
                            {{ $t("RefuseStatus.Save") }}
                        </b-button>
                        <b-button
                            v-else
                            class="btn btn-save btn-footer"
                            @click="nextStep()"
                        >
                            {{ $t("RefuseStatus.Next") }}
                        </b-button>
                    </div>
                </b-col>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import {
        BFormGroup,
        BFormInput,
        BButton,
        BFormTextarea,
        BFormCheckbox,
        BRow,
        BCol,
        BSidebar,
        BCollapse
    } from "bootstrap-vue";
    import { mapState, mapMutations } from "vuex";
    import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
    import Multiselect from "components-code";
    import PdfFileIcon from "@core/assets/icons/pdf-file-icon.svg";
    import ImgFileIcon from "@core/assets/icons/img-file-icon.svg";
    import ZipFileIcon from "@core/assets/icons/zip-file-icon.svg";
    import VideoFileIcon from "@core/assets/icons/video-file-icon.svg";
    import TextFileIcon from "@core/assets/icons/text-file-icon.svg";
    import WordFileIcon from "@core/assets/icons/word-file-icon.svg";
    import ExcelFileIcon from "@core/assets/icons/excel-file-icon.svg";
    import GlobalFileIcon from "@core/assets/icons/global-file-icon.svg";
    import modalStepByStep from "./ModalStepByStep.vue";
    import CustomFields from "@core/components/customFields/index.vue";
    import moment from "moment";
    import { handleCreateParametersForPayload, handleFillCustomFields } from "@/utils/customFields";

    const ICONS = {
        PdfFileIcon,
        ImgFileIcon,
        ZipFileIcon,
        VideoFileIcon,
        TextFileIcon,
        ExcelFileIcon,
        WordFileIcon,
        GlobalFileIcon
    };

    export default {
        components: {
            BFormGroup,
            BFormInput,
            BButton,
            BRow,
            BCol,
            BSidebar,
            BFormTextarea,
            BFormCheckbox,
            BCollapse,
            CustomFields,
            Multiselect,
            modalStepByStep,
            AddRole: () => import("@core/assets/icons/add-circle.svg"),
            CloseIcon: () => import("@core/assets/icons/close-icon.svg"),
            ConfirmIcon: () => import("@core/assets/icons/confirm-icon.svg"),
            RemoveRole: () => import("@core/assets/icons/red-line-icon.svg"),
            EditRole: () => import("@core/assets/icons/edit-role-icon.svg"),
            DeclineIcon: () => import("@core/assets/icons/decline-icon.svg"),
            PhoneIcon: () => import("@core/assets/icons/phone-icon.svg"),
            ArrowDownIcon: () => import("@core/assets/icons/arrow-down.svg"),
            EmailIcon: () => import("@core/assets/icons/email-icon.svg"),
            InfoIcon: () => import("@core/assets/icons/info-icon.svg"),
            AttachmentsIcon: () => import("@core/assets/icons/attachments-icon.svg"),
            TimesIcon: () => import("@core/assets/icons/times-icon.svg"),
            SingleSelect: () => import("@core/components/multiselect/SingleSelect.vue"),
            EmptyBoxIcon: () => import("@core/assets/icons/empty-box-icon.svg"),
            TrashIcon: () => import("@core/assets/icons/trash-icon.svg")
        },
        props: {
            modalTitle: {
                type: String,
                default: ""
            },
            currentId: {
                type: Number,
                default: null
            },
            device: {
                type: String,
                default: "desktop"
            },
            customFields: {
                type: Array,
                default: () => []
            },
            workcentersList: {
                type: Array
            },
            tagsList: {
                type: Array
            }
        },
        data() {
            return {
                forceRenderIndustrialParametersList: true,
                currentStep: 1,
                isOpen: false,
                flagNameEqual: false,
                isStepBlockOpenned: false,
                isToValidate: false,
                prefix: localStorage.getItem("prefix"),
                currentTab: "info",
                name: "",
                code: "",
                number: "",
                description: "",
                workcenter: null,
                tags: [],
                note: "",
                attachments: [],
                initialAttachments: [],
                cycleSpecificTime: true,
                cycle: "",
                valueAggregation: "",
                setupStart: "",
                startTime: "",
                endTime: "",
                states: {
                    name: null,
                    code: null,
                    number: null,
                    description: null,
                    tags: null,
                    workcenter: null,
                    note: null,
                    cycle: null,
                    valueAggregation: null,
                    customFieldsForceError: null
                }
            };
        },
        async mounted() {
            this.UPDATE_MODAL_ADD_EDIT_OPERATION(false);
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("productionOrders", ["UPDATE_MODAL_ADD_EDIT_OPERATION"]),
            openStepBlock() {
                this.isStepBlockOpenned = !this.isStepBlockOpenned;
            },
            closeModal() {
                this.UPDATE_MODAL_ADD_EDIT_OPERATION(false);
            },
            emitCancel() {
                this.UPDATE_MODAL_ADD_EDIT_OPERATION(false);
                this.clearValidations();
            },
            getModalTitle(v) {
                if (v === 1) return "PeoplePage.GeneralInformation";
                if (v === 2) return "MaterialsPage.Times";
                if (v === 3) return "MaterialsPage.Attachments";
            },
            addNewAttachment() {
                this.$refs.editfiles.click();
            },
            removeFile(index) {
                this.attachments.splice(index, 1);
            },
            handleFileChange() {
                const files = Array.from(this.$refs.editfiles.files);
                if (files.length) {
                    for (let i = 0; i < files.length; i++) {
                        const file = files[i];
                        const reader = new FileReader();
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            const { fileType, icon } = this.getFileDetails(file.type);

                            this.attachments.push({
                                file: reader.result,
                                name: file.name,
                                icon,
                                fileType
                            });
                        };
                    }
                }
            },
            getFileDetails(file) {
                const mimeType = file?.toLowerCase() || "";

                switch (true) {
                    case mimeType.includes("image"):
                        return { fileType: "Image", icon: ICONS.ImgFileIcon };
                    case mimeType.includes("x-zip"):
                        return { fileType: "ZIP", icon: ICONS.ZipFileIcon };
                    case mimeType.includes("pdf"):
                        return { fileType: "PDF", icon: ICONS.PdfFileIcon };
                    case mimeType.includes("video"):
                        return { fileType: "Video", icon: ICONS.VideoFileIcon };
                    case mimeType.includes("spreadsheetml"):
                        return { fileType: "Excel", icon: ICONS.ExcelFileIcon };
                    case mimeType.includes("wordprocessingml"):
                        return { fileType: "Word", icon: ICONS.WordFileIcon };
                    case mimeType.includes("text"):
                        return { fileType: "Text", icon: ICONS.TextFileIcon };
                    default:
                        return { fileType: "Unknown", icon: ICONS.GlobalFileIcon };
                }
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            },
            async getInfos() {
                if (this.modalTitle.includes("Edit")) {
                    this.UPDATE_FLAG_SHOW_OVERLAY(true);

                    await this.$http2
                        .get(`/api/myconfig/order/operation/${this.currentId}`)
                        .then((response) => {
                            this.name = response.data.data.name;
                            this.description = response.data.data.description;
                            this.note = response.data.data.notes;
                            this.number =
                                response.data.data.number === 0 ? "" : response.data.data.number;
                            this.cycleSpecificTime = response.data.data.specificTimeCycle;
                            this.tags = response.data.data.tags;
                            this.workcenter = response.data.data.workcenter;
                            this.valueAggregation =
                                response.data.data.aggregationValue === 0
                                    ? ""
                                    : response.data.data.aggregationValue;
                            this.cycle =
                                response.data.data.cycle === 0 ? "" : response.data.data.cycle;
                            this.setupStart = response.data.data.setupStart
                                ? new Date(response.data.data.setupStart)
                                : null;
                            this.startTime = response.data.data.startTime
                                ? new Date(response.data.data.startTime)
                                : null;
                            this.endTime = response.data.data.endTime
                                ? new Date(response.data.data.endTime)
                                : null;

                            handleFillCustomFields(
                                this.customFields,
                                response?.data?.data?.industrialParameters
                            );

                            if (response.data.data?.attachments) {
                                const localAttachaments =
                                    response.data.data.attachments.map((e) => {
                                        const { icon } = this.getFileDetails(e.fileType);
                                        return { ...e, icon };
                                    }) || [];

                                this.attachments = [...localAttachaments];
                                this.initialAttachments = [...localAttachaments];
                            }
                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        });

                    this.forceRenderIndustrialParametersValue();
                }
            },
            clearFields() {
                this.name = "";
                this.code = "";
                this.number = "";
                this.description = "";
                this.attachments = [];
                this.initialAttachments = [];
                this.tags = [];
                this.note = "";
                this.cycleSpecificTime = true;
                this.workcenter = null;
                this.cycle = "";
                this.valueAggregation = "";
                this.setupStart = "";
                this.startTime = "";
                this.endTime = "";
                this.customFields.map((e) => (e.value = null));
                this.isStepBlockOpenned = false;
                this.currentStep = 1;
                this.currentTab = "info";
            },
            clearValidations() {
                this.states.name = null;
                this.states.code = null;
                this.states.number = null;
                this.states.description = null;
                this.states.tags = null;
                this.states.workcenter = null;
                this.states.note = null;
                this.states.cycle = null;
                this.states.valueAggregation = null;
                this.states.customFieldsForceError = null;
                this.flagNameEqual = false;
                this.isToValidate = false;
            },
            fieldsValidate() {
                const requiredFields = this.customFields.filter((e) => e.required);
                const isCustomFieldsValid = requiredFields.map((e) => {
                    if (e.required) {
                        return e.value != null && e.value != 0;
                    }
                });
                const hasInvalidFields = isCustomFieldsValid.includes(false);
                hasInvalidFields
                    ? (this.states.customFieldsForceError = true)
                    : (this.states.customFieldsForceError = false);

                const validName = (this.states.name =
                    this.name && this.flagNameEqual === false ? true : false);

                const validNumber = (this.states.number =
                    this.number !== null && this.number !== "");
                const validWorkcenter = (this.states.workcenter =
                    this.workcenter !== null && this.number !== 0);

                const startOperationFilled = this.startTime !== null && this.startTime !== "";
                const startSetupFilled = this.setupStart !== null && this.setupStart !== "";
                const endOperationFilled = this.endTime !== null && this.endTime !== "";

                const anyFieldFilled =
                    startOperationFilled || startSetupFilled || endOperationFilled;
                const allFieldsFilled =
                    startOperationFilled && startSetupFilled && endOperationFilled;
                let validThreeFields = true;

                if (this.currentTab === "times" || this.modalTitle.includes("Edit")) {
                    this.isToValidate = true;
                    validThreeFields = !anyFieldFilled || allFieldsFilled;

                    !validThreeFields ? (this.currentTab = "times") : "";
                }

                if (!validName || !validNumber || !validWorkcenter) {
                    this.currentTab = "info";
                }

                return (
                    !hasInvalidFields &&
                    validName &&
                    validNumber &&
                    validWorkcenter &&
                    validThreeFields
                );
            },
            previousStep() {
                this.currentStep = this.currentStep - 1;
            },
            nextStep() {
                this.flagNameEqual = false;
                this.isToValidate = false;
                const isValid = this.fieldsValidate();
                if (!isValid) return;

                if (this.currentStep < 3) {
                    this.currentStep = this.currentStep + 1;
                }
            },
            onSave() {
                this.flagNameEqual = false;
                this.isToValidate = false;
                if (this.modalTitle.includes("Edit")) {
                    const isValid = this.fieldsValidate();
                    if (!isValid) return;
                    this.editOperation();
                } else {
                    this.createOperation();
                }
            },
            async createOperation() {
                const parameters = handleCreateParametersForPayload(this.customFields);
                const payload = {
                    name: this.name,
                    number: String(this.number),
                    description: this.description,
                    tags: this.tags,
                    workcenter: this.workcenter,
                    notes: this.note,
                    specificTimeCycle: this.cycleSpecificTime,
                    cycle: Number(this.cycle),
                    aggregationValue: Number(this.valueAggregation),
                    setupStart: this.setupStart ? this.adjustTimeZone3(this.setupStart) : null,
                    startTime: this.startTime ? this.adjustTimeZone3(this.startTime) : null,
                    endTime: this.endTime ? this.adjustTimeZone3(this.endTime) : null,
                    attachments: this.attachments.map((e) => {
                        return { file: e.file, name: e.name, fileType: e.fileType };
                    }),
                    industrialParameter: parameters
                };
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                await this.$http2
                    .post(`/api/myconfig/order/${this.currentId}/operation`, payload)
                    .then((response) => {
                        this.showToast(
                            this.$t("IntegrationPage.success"),
                            "SmileIcon",
                            this.$t("MaterialsPage.OperationCreated"),
                            "success"
                        );
                        this.$emit("updateList");
                        this.closeModal();
                    })
                    .catch((error) => {
                        error.response.data.errors.map((e) => {
                            this.showToast(this.$t("Error"), "FrownIcon", e.message, "danger");
                        });
                    })
                    .finally(() => {
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            },
            async editOperation() {
                const parameters = handleCreateParametersForPayload(this.customFields);
                const missingIds = this.initialAttachments
                    .filter(
                        (initialAttachment) =>
                            !this.attachments.some(
                                (attachment) =>
                                    attachment.id === initialAttachment.id &&
                                    attachment.name === initialAttachment.name
                            )
                    )
                    .map((attachment) => attachment.id);
                const payload = {
                    name: this.name,
                    number: String(this.number),
                    description: this.description,
                    tags: this.tags,
                    workcenter: this.workcenter,
                    notes: this.note,
                    specificTimeCycle: this.cycleSpecificTime,
                    cycle: this.cycle ? Number(this.cycle) : null,
                    aggregationValue: this.valueAggregation ? Number(this.valueAggregation) : null,
                    setupStart: this.setupStart ? this.adjustTimeZone3(this.setupStart) : null,
                    startTime: this.startTime ? this.adjustTimeZone3(this.startTime) : null,
                    endTime: this.endTime ? this.adjustTimeZone3(this.endTime) : null,
                    attachments: this.attachments.map((e) => {
                        return { id: e.id, file: e.file, name: e.name, fileType: e.fileType };
                    }),
                    industrialParameter: parameters,
                    deletedAttachments: missingIds
                };

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                await this.$http2
                    .put(`/api/myconfig/order/operation/${this.currentId}`, payload)
                    .then((response) => {
                        this.showToast(
                            this.$t("IntegrationPage.success"),
                            "SmileIcon",
                            this.$t("MaterialsPage.OperationEdited"),
                            "success"
                        );
                        this.$emit("updateList");
                        this.closeModal();
                    })
                    .catch((error) => {
                        this.showToast(
                            this.$t("IntegrationPage.Error"),
                            "SmileIcon",
                            error.response.data.message,
                            "danger"
                        );
                    })
                    .finally(() => {
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            },
            adjustTimeZone3(date) {
                // Lógica temporaria para resolver problema de -3 do fuso
                const offset = date.getTimezoneOffset() * 60000;
                const localISOTime = new Date(date.getTime() - offset).toISOString().slice(0, -1);

                return localISOTime;
            },
            forceRenderIndustrialParametersValue() {
                this.forceRenderIndustrialParametersList = false;
                this.$nextTick().then(() => {
                    this.forceRenderIndustrialParametersList = true;
                });
            }
        },
        computed: {
            ...mapState("productionOrders", {
                isModalAddEditOperationOpen: "isModalAddEditOperationOpen"
            }),
            displayImportantFirst() {
                return this.customFields.sort((a, b) => {
                    if (a.important && !b.important) {
                        return -1;
                    }
                    if (!a.important && b.important) {
                        return 1;
                    }
                    return 0;
                });
            }
        },
        watch: {
            isModalAddEditOperationOpen(v) {
                this.isOpen = v;
                this.clearFields();
                this.clearValidations();
                if (v) {
                    this.getInfos();
                } else {
                    this.forceRenderIndustrialParametersValue();
                }
            },
            cycleSpecificTime(v) {
                if (v) {
                    this.cycle = "";
                    this.valueAggregation = "";
                }
            },
            currentStep(v) {
                if (v === 1) this.currentTab = "info";
                if (v === 2) this.currentTab = "times";
                if (v === 3) this.currentTab = "attachments";
            }
        }
    };
</script>
<style lang="scss">
    .sidebar-add-edit-operation {
        .custom-select {
            cursor: pointer;
            padding: 4px 34px 4px 8px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
            &[disabled] {
                border-radius: 5px;
                border: 1px solid #7e7570;
                background-color: #eee;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
                color: #7e7570 !important;
            }
        }

        .b-sidebar-header {
            display: contents;

            .header-sidebar {
                display: flex;
                justify-content: space-between;
                padding: 16px;

                .sidebar-title {
                    display: flex;
                    align-items: center;
                    color: #4c4541;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    min-height: 26px;
                }
            }

            .modal-navbar {
                display: flex;

                .info,
                .times,
                .attachments {
                    cursor: pointer;
                    width: 100%;
                    text-align: center;
                    color: #998f8a;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 16px 0 14px;
                    border-bottom: 1px solid #cfc4be;

                    &.active {
                        color: #974900;
                        background: #fff;
                        box-shadow: 0px -4px 0px -1px #974900 inset;
                    }
                }
            }

            .close-icon {
                border: 1px solid #974900;
                border-radius: 5px;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    width: 12px;
                    height: 12px;
                    path {
                        fill: #974900;
                    }
                }

                &:hover {
                    background-color: #ffede2 !important;
                }

                &:active {
                    background-color: #ffdbc4 !important;
                }
            }

            .separator {
                width: 100%;
                height: 1px;
                background: #cfc4be;
            }
        }

        &.is-edit {
            .header-sidebar {
                .sidebar-title {
                    font-size: 18px;
                }
            }
            .b-sidebar {
                box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
                border-left: none;
            }
        }

        .b-sidebar {
            box-shadow: none;
            border-left: 1px solid #cfc4be;
            transition: all 0.5s ease-out;
            height: 100% !important;
            .b-sidebar-body {
                padding: 16px 0;

                .no-attachments {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    border-radius: 6px;
                    margin: 0 16px;

                    .empty-box {
                        width: 80px;
                        height: 80px;
                    }

                    .title {
                        padding-top: 12px;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                        color: #4c4541;
                        margin: 0;
                    }

                    .subtitle {
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        color: #998f8a;
                        padding: 4px 0 12px;
                        margin: 0;
                        text-align: center;
                    }

                    .btn-new-attachments {
                        border-color: #974900 !important;
                        background-color: #974900 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        padding: 8px 21px;
                        line-height: 20px;
                        letter-spacing: 0.175px;

                        .add-icon {
                            width: 14px;
                            height: 14px;
                            fill: #fff;
                        }
                    }

                    @media (max-width: 480px) {
                        .empty-box {
                            width: 40px;
                            height: 40px;
                        }

                        .title {
                            font-size: 14px;
                        }

                        .subtitle {
                            font-size: 12px;
                            text-align: center;
                            padding: 4px 48px 12px;
                            line-height: 16px;
                        }

                        .btn-new-attachments {
                            padding: 3px 14px;
                            color: #fff;
                            font-size: 12px;
                            font-weight: 600;
                            line-height: 20px;
                            letter-spacing: 0.15px;

                            .add-icon {
                                width: 12px;
                                height: 12px;
                            }
                        }
                    }
                }

                .btn-new-attachment {
                    box-shadow: none;
                    width: 100%;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    border-radius: 5px;

                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    padding: 8px 21px;

                    &:hover {
                        box-shadow: none;
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }

                    @media (max-width: 480px) {
                        font-size: 12px !important;
                        padding: 3px 21px !important;
                    }
                }

                .attached-files {
                    color: #4c4541;
                    font-size: 16px;
                    font-weight: 600;
                    line-height: 26px;
                    padding: 16px 16px 0;
                }

                .attached-file {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: 16px;
                    padding: 23.5px 29px;
                    border-bottom: 1px solid #cfc4be;

                    .icon-name {
                        display: flex;
                        align-items: center;
                        gap: 16px;
                        overflow: hidden;

                        .icon {
                            width: 28px;
                            height: 20px;
                        }

                        .text {
                            color: #4c4541;
                            font-size: 14px;
                            font-weight: 400;
                            max-width: -webkit-fill-available;
                            width: 100%;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                        }
                    }

                    .delete-file {
                        display: flex;
                        width: 28px;
                        height: 28px;
                        border-radius: 5px;
                        cursor: pointer;
                        padding: 8px;

                        &:hover {
                            background-color: #ffede2 !important;
                        }

                        &:active {
                            background-color: #ffdbc4 !important;
                        }
                    }
                }

                .form-group {
                    .form-control.is-invalid,
                    .form-control.is-valid {
                        background-image: none !important;
                    }

                    .form-control {
                        &::placeholder {
                            color: #cfc4be;
                        }
                        &:focus {
                            border-color: #974900;
                        }
                    }
                }

                .custom-switch-button {
                    padding: 0 16px 16px;
                    .custom-control-label {
                        margin: 0;
                        line-height: 20px;
                    }

                    .custom-control-input ~ .custom-control-label::before {
                        background-color: #e2e2e2;
                        height: 20px;
                    }

                    .custom-control-input:checked ~ .custom-control-label::before {
                        background-color: #974900;
                    }

                    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
                        background-color: #e2e2e2;
                        border-color: #e2e2e2;
                    }

                    .text-allow {
                        color: #4c4541;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }

                &::-webkit-scrollbar {
                    display: none !important;
                }
            }
        }

        .step-action {
            padding: 16px 0;

            .step-collapse {
                padding-left: 16px;
            }

            .step-collapse,
            .text {
                display: flex;
                gap: 8px;
                color: #4c4541;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                .icon {
                    svg {
                        width: 16px;
                        height: 16px;
                        fill: #974900;
                    }
                }

                &.not-collapsed {
                    svg {
                        transform: rotate(-180deg);
                        transition: all 0.3s;
                    }
                }

                &.collapsed {
                    svg {
                        transform: rotate(0);
                        transition: all 0.3s;
                    }
                }
            }
            .text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 16px;
            }

            .step-progress {
                display: flex;
                gap: 4px;
                padding: 4px 16px 0;
                .first-step,
                .second-step,
                .third-step {
                    width: 50%;
                    height: 4px;
                    border-radius: 4px;
                    background: #f4eee8;

                    &.step-active {
                        background: #974900;
                    }

                    &.next-step {
                        background: #66bb6a;
                    }
                }
            }
        }

        .progress-block {
            padding-top: 16px;

            .detail-block {
                padding: 16px 16px 0;

                .info-block,
                .attachments-block,
                .times-block {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .icon {
                        display: flex;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        padding: 7px;
                        border-radius: 50%;
                        border: 1px solid #cfc4be;

                        svg {
                            width: 14px;
                            height: 14px;
                            fill: #cfc4be;
                        }
                    }

                    .text {
                        overflow: hidden;
                        color: #cfc4be;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                    }

                    &.step-active {
                        .icon {
                            border-color: #974900;
                            svg {
                                fill: #974900;
                            }
                        }

                        .text {
                            color: #974900;
                        }
                    }

                    &.next-step {
                        .icon {
                            border-color: #66bb6a;
                            background: #66bb6a;
                            svg {
                                fill: #fff;
                            }
                        }

                        .text {
                            color: #66bb6a;
                        }
                    }
                }

                .vertical-separator {
                    margin-left: 14px;
                    width: 1px;
                    height: 16px;
                    background: #cfc4be;

                    &.step-active {
                        background: #974900;
                    }

                    &.next-step {
                        background: #66bb6a;
                    }
                }
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
        }

        .separator-progress {
            width: 100%;
            height: 1px;
            background: #eee;
        }

        .footer-sidebar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 16px;

            .buttons,
            .edit-buttons {
                gap: 8px;
            }

            @media (max-width: 480px) {
                justify-content: center;
                .buttons {
                    width: 100%;

                    .btn {
                        width: 50%;
                    }
                }

                .btn-footer {
                    font-size: 12px !important;
                    padding: 3px 21px !important;
                }
            }

            .btn-footer {
                box-shadow: none;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                border-radius: 5px;

                &:hover {
                    box-shadow: none;
                }

                &.btn-cancel {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid transparent !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-previous,
                &.btn-cancel.btn-cancel-outline {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-save {
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    color: #ffff !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #9f5714 !important;
                    }

                    &:active {
                        background-color: #a45f1f !important;
                    }
                }
            }
        }
    }
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-flex"},[(_vm.device !== 'mobile' && !_vm.modalTitle.includes('Edit'))?_c('modal-step-by-step',{attrs:{"modalTitle":_vm.modalTitle,"isOpen":_vm.isOpen,"stepPosition":_vm.currentStep}}):_vm._e(),_c('b-sidebar',{class:['sidebar-add-edit-order', _vm.modalTitle.includes('Edit') ? 'is-edit' : ''],attrs:{"id":"add-edit-order-sidebar-right","right":"","bg-variant":"white","width":_vm.device === 'desktop' ? '407px' : _vm.device === 'tablet' ? '60vw' : '100vw'},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-sidebar"},[_c('div',{class:['sidebar-title', _vm.modalTitle.includes('Edit') ? '--edit' : '']},[_vm._v(" "+_vm._s(_vm.modalTitle.includes("Edit") ? _vm.$t(_vm.modalTitle) : _vm.$t(_vm.getModalTitle(_vm.currentStep)))+" ")]),(_vm.modalTitle.includes('Edit'))?_c('span',{staticClass:"close-icon",on:{"click":function($event){return _vm.closeModal()}}},[_c('CloseIcon')],1):_vm._e()]),_c('div',{staticClass:"separator"}),(_vm.modalTitle.includes('Edit'))?_c('div',{staticClass:"modal-navbar"},[_c('div',{class:['order-info', _vm.currentTab === 'order-info' ? 'active' : ''],on:{"click":function($event){_vm.currentTab = 'order-info'}}},[_vm._v(" "+_vm._s(_vm.$t("orderView.Order"))+" ")]),_c('div',{class:['material-info', _vm.currentTab === 'material-info' ? 'active' : ''],on:{"click":function($event){_vm.currentTab = 'material-info'}}},[_vm._v(" Material ")])]):_vm._e()]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"separator"}),(_vm.device === 'mobile' && !_vm.modalTitle.includes('Edit'))?_c('div',{staticClass:"step-action"},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{class:[
                            'step-collapse',
                            _vm.isStepBlockOpenned ? 'not-collapsed' : 'collapsed'
                        ]},[_vm._v(" "+_vm._s((_vm.currentStep + " " + (_vm.$t("Of")) + " 2"))),_c('span',{staticClass:"icon",attrs:{"id":"step-collapse"},on:{"click":function($event){return _vm.openStepBlock()}}},[_c('ArrowDownIcon')],1)]),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t(_vm.getModalTitle(_vm.currentStep))))])]),_c('div',{staticClass:"step-progress"},[_c('div',{class:[
                            'first-step',
                            _vm.currentStep === 1
                                ? 'step-active'
                                : _vm.currentStep > 1
                                  ? 'next-step'
                                  : ''
                        ]}),_c('div',{class:[
                            'second-step',
                            _vm.currentStep === 2
                                ? 'step-active'
                                : _vm.currentStep > 2
                                  ? 'next-step'
                                  : ''
                        ]})]),_c('b-collapse',{attrs:{"visible":_vm.isStepBlockOpenned}},[_c('div',{staticClass:"progress-block"},[_c('div',{staticClass:"separator-progress"}),_c('div',{staticClass:"detail-block"},[_c('div',{class:[
                                    'info-block',
                                    _vm.currentStep === 1
                                        ? 'step-active'
                                        : _vm.currentStep > 1
                                          ? 'next-step'
                                          : ''
                                ]},[_c('div',{staticClass:"icon"},[_c('OrderIcon')],1),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.$t("OrdersPage.OrderInformation")))])]),_c('div',{class:[
                                    'vertical-separator',
                                    _vm.currentStep === 2 ? 'next-step' : ''
                                ]}),_c('div',{class:[
                                    'material-info-block',
                                    _vm.currentStep === 2 ? 'step-active' : ''
                                ]},[_c('div',{staticClass:"icon"},[_c('MaterialIcon')],1),_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.$t("OrdersPage.MaterialInformation"))+" ")])])])])])],1):_vm._e(),_c('div',{staticClass:"separator-progress"}),_c('b-col',{staticClass:"footer-sidebar",attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex buttons"},[_c('b-button',{staticClass:"btn btn-cancel btn-footer btn-cancel-outline",on:{"click":function($event){return _vm.emitCancel()}}},[_vm._v(" "+_vm._s(_vm.$t("RefuseStatus.Cancel"))+" ")]),(!_vm.modalTitle.includes('Edit') && _vm.currentStep !== 1)?_c('b-button',{staticClass:"btn-previous btn-footer",on:{"click":function($event){return _vm.previousStep()}}},[_vm._v(" "+_vm._s(_vm.$t("PeoplePage.Previous"))+" ")]):_vm._e(),(_vm.modalTitle.includes('Edit') || _vm.currentStep === 2)?_c('b-button',{staticClass:"btn btn-save btn-footer",on:{"click":function($event){return _vm.onSave()}}},[_vm._v(" "+_vm._s(_vm.$t("RefuseStatus.Save"))+" ")]):_c('b-button',{staticClass:"btn btn-save btn-footer",on:{"click":function($event){return _vm.nextStep()}}},[_vm._v(" "+_vm._s(_vm.$t("RefuseStatus.Next"))+" ")])],1)])]},proxy:true}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'order-info'),expression:"currentTab === 'order-info'"}]},[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('OrdersPage.OrderNumber'),"label-for":"orderNumber","invalid-feedback":_vm.flagOrderNumber
                                ? this.$t('OrdersPage.EqualOrderNumber')
                                : this.$t('RequiredField')}},[_c('b-form-input',{attrs:{"id":"orderNumber","placeholder":_vm.$t('ResourceStatus.Placeholder'),"state":_vm.states.orderNumber,"lazy-formatter":"","formatter":function () {
                                    _vm.states.orderNumber = _vm.orderNumber !== '' ? null : false;
                                    return _vm.orderNumber;
                                }},model:{value:(_vm.orderNumber),callback:function ($$v) {_vm.orderNumber=$$v},expression:"orderNumber"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('OrdersPage.Priority'),"label-for":"priority","invalid-feedback":_vm.$t('RequiredField')}},[_c('b-form-input',{attrs:{"id":"priority","placeholder":_vm.$t('ResourceStatus.Placeholder'),"state":_vm.states.priority,"lazy-formatter":"","type":"text"},nativeOn:{"input":function($event){return (function (e) {
                                    var maskApplied = e.target.value.replace(/[^0-9]/g, '');
                                    _vm.priority = maskApplied;
                                    e.target.value = maskApplied;
                                }).apply(null, arguments)}},model:{value:(_vm.priority),callback:function ($$v) {_vm.priority=$$v},expression:"priority"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('OrdersPage.Quantity'),"label-for":"quantity","invalid-feedback":_vm.$t('RequiredField')}},[_c('b-form-input',{attrs:{"id":"quantity","placeholder":_vm.$t('ResourceStatus.Placeholder'),"state":_vm.states.quantity,"lazy-formatter":"","type":"text","formatter":function () {
                                    _vm.states.quantity = _vm.quantity !== '';
                                    return _vm.quantity;
                                }},nativeOn:{"input":function($event){return (function (e) {
                                    var maskApplied = e.target.value.replace(/[^0-9]/g, '');
                                    _vm.quantity = maskApplied;
                                    e.target.value = maskApplied;
                                }).apply(null, arguments)}},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}})],1)],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12"}},[_c('Multiselect',{attrs:{"label":"Tags","fieldPlaceholder":_vm.$t('Select'),"status":_vm.states.tags,"feedback":_vm.$t('RequiredField'),"datalist":_vm.tagsList,"notFoundMessage":_vm.$t('NoResultsFound'),"selectedItems":_vm.tags,"searchPlaceholder":_vm.$t('message.SearchPlaceholderDefault')},on:{"update:selectedItems":function($event){_vm.tags=$event},"update:selected-items":function($event){_vm.tags=$event}}})],1),(_vm.forceRenderIndustrialParametersList)?_c('div',[_c('custom-fields',{attrs:{"field":{
                            id: 'delivery-date-datepicker',
                            label: _vm.$t('OrdersPage.DeliveryDate'),
                            industrialStructParameterType: 'datetime',
                            value: _vm.inputDeliveryDatepicker,
                            type: 'datetime'
                        }},on:{"input":function (value) { return _vm.handleDatepickerChange(value); }}})],1):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'material-info'),expression:"currentTab === 'material-info'"}]},[_c('b-col',{staticClass:"form-group",attrs:{"md":"12"}},[_c('label',{attrs:{"for":"material-select"}},[_vm._v(" Material ")]),_c('SingleSelect',{attrs:{"id":"material-select","placeholder":_vm.$t('Select'),"optionSelected":_vm.materialList.find(function (item) { return item.value == _vm.material; }),"options":_vm.materialList,"searchable":true,"translate":true,"state":_vm.states.material},on:{"input":function (value) { return (_vm.material = value ? value.value : value); }}},[_vm._v(" "+_vm._s(_vm.$t("RequiredField"))+" ")])],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12"}},[_c('label',{attrs:{"for":"route-select"}},[_vm._v(" "+_vm._s(_vm.$t("MaterialsPage.RouteSingular"))+" ")]),_c('SingleSelect',{attrs:{"id":"route-select","placeholder":_vm.$t('Select'),"optionSelected":_vm.routeList.find(function (item) { return item.value == _vm.route; }),"options":_vm.routeList.map(function (e) { return ({ value: e.value, text: e.text }); }),"defaultValue":_vm.routeList.find(function (e) { return e.default; }),"searchable":true,"translate":true,"state":_vm.states.route,"disabled":_vm.material === null},on:{"input":function (value) { return (_vm.route = value ? value.value : value); }}},[_vm._v(" "+_vm._s(_vm.$t("RequiredField"))+" ")])],1)],1),(
                    _vm.currentTab === 'order-info' &&
                    _vm.customFields &&
                    _vm.customFields.length &&
                    _vm.forceRenderIndustrialParametersList
                )?_c('div',_vm._l((_vm.customFields),function(field){return _c('div',{key:field.id},[_c('custom-fields',{attrs:{"field":field,"hasError":_vm.states.customFieldsForceError},on:{"input":function (value) {
                                field.value = value ? value : null;
                            }}})],1)}),0):_vm._e(),(
                    _vm.currentTab === 'material-info' &&
                    _vm.currentCustomField.length &&
                    _vm.forceRenderIndustrialParametersList
                )?_c('div',_vm._l((_vm.currentCustomField),function(field){return _c('div',{key:field.id},[_c('custom-fields',{attrs:{"field":field,"disabled":"","isOrder":""}})],1)}),0):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentTab === 'material-info' && _vm.route),expression:"currentTab === 'material-info' && route"}]},[(_vm.forceRenderIndustrialParametersList && _vm.operationsList.length)?_c('div',_vm._l((_vm.sortOperationsByNumber(_vm.operationsList)),function(operation){return _c('div',{key:operation.id,staticClass:"operation-row"},[_c('div',{class:[
                                'operation-header',
                                _vm.openOperationId !== operation.id ? 'not-collapsed' : 'collapsed'
                            ],on:{"click":function($event){return _vm.openOperationCollapse(operation.id)}}},[_c('div',{staticClass:"operation-text"},[_vm._v(" "+_vm._s(operation.number)+" · "+_vm._s(operation.name)+" ")]),_c('ArrowDownIcon')],1),_c('b-collapse',{attrs:{"visible":_vm.openOperationId === operation.id}},[_c('div',{staticClass:"operation-body"},[_c('custom-fields',{attrs:{"field":{
                                        id: ((operation.id) + "-setup-start"),
                                        label: _vm.$t('OrdersPage.SetupStart'),
                                        industrialStructParameterType: 'datetime',
                                        required: true,
                                        value: operation.setupStart,
                                        type: 'datetime'
                                    },"hasError":_vm.isToValidate &&
                                        (operation.endTime !== '' || operation.startTime !== ''),"errorMessage":_vm.$t('RequiredField')},on:{"input":function (value) {
                                            operation.setupStart = value;
                                        }}}),_c('custom-fields',{attrs:{"field":{
                                        id: ((operation.id) + "-operation-start"),
                                        label: _vm.$t('OrdersPage.OperationStart'),
                                        industrialStructParameterType: 'datetime',
                                        required: true,
                                        value: operation.startTime,
                                        type: 'datetime'
                                    },"hasError":_vm.isToValidate &&
                                        (operation.setupStart !== '' ||
                                            operation.endTime !== ''),"errorMessage":_vm.$t('RequiredField')},on:{"input":function (value) {
                                            operation.startTime = value;
                                        }}}),_c('custom-fields',{attrs:{"field":{
                                        id: ((operation.id) + "-operation-end"),
                                        label: _vm.$t('OrdersPage.OperationEnd'),
                                        industrialStructParameterType: 'datetime',
                                        required: true,
                                        value: operation.endTime,
                                        type: 'datetime'
                                    },"hasError":_vm.isToValidate &&
                                        (operation.setupStart !== '' ||
                                            operation.startTime !== ''),"errorMessage":_vm.$t('RequiredField')},on:{"input":function (value) {
                                            operation.endTime = value;
                                        }}}),(
                                        operation.industrialParameters.length &&
                                        _vm.forceRenderIndustrialParametersList
                                    )?_c('div',_vm._l((operation.industrialParameters),function(field){return _c('div',{key:field.id},[(field.value !== null)?_c('custom-fields',{attrs:{"field":field,"isOrder":"","disabled":""}}):_vm._e()],1)}),0):_vm._e()],1)])],1)}),0):_vm._e()])]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div>
        <b-col
            md="12"
            class="form-group"
            v-if="
                field.industrialStructParameterType === 'select_unique' ||
                field.industrialStructParameterType === 'select_multiple' ||
                field.industrialStructParameterType === 'location'
            "
        >
            <label
                class="label"
                :for="`custom-field-${field.id}`"
            >
                {{ field.label }}
                <span
                    v-if="field.important"
                    class="d-flex"
                    :id="`${field.id}-${field.label}`"
                    ><ImportantIcon
                /></span>
            </label>
            <SingleSelect
                :id="`custom-field-${field.id}`"
                :placeholder="$t('Select')"
                :optionSelected="verifySelectedOptions()"
                :options="
                    field.industrialStructParameterType !== 'location'
                        ? field.industrialStructParameterOption
                            ? field.industrialStructParameterOption.map((item) => ({
                                  value: item.id,
                                  text: item.name
                              }))
                            : []
                        : suggestions
                "
                :searchable="field.industrialStructParameterType === 'location'"
                :translate="true"
                :disabled="disabled"
                :state="field.required && hasError ? verifyValidFields(field) : null"
                :multiselect="
                    field.industrialStructParameterType === 'select_multiple' ? true : false
                "
                :class="
                    field.industrialStructParameterType === 'select_multiple'
                        ? 'custom-multiple'
                        : ''
                "
                :isLocationSelect="field.industrialStructParameterType === 'location'"
                @input="(value) => alterValueInternal(value)"
                @input.native="
                    (e) =>
                        field.industrialStructParameterType === 'location' &&
                        searchByAddress(e.target.value)
                "
                @open="openSelect()"
            >
                {{ $t("RequiredField") }}
            </SingleSelect>
        </b-col>
        <b-col
            md="12"
            class="form-group input-block"
            v-if="
                field.industrialStructParameterType === 'input_text' ||
                field.industrialStructParameterType === 'input_numeric'
            "
        >
            <b-form-group
                :label-for="`custom-field-${field.id}`"
                :invalid-feedback="$t('RequiredField')"
            >
                <template #label>
                    <div class="label">
                        {{ field.label }}
                        <span
                            v-if="field.important"
                            class="d-flex"
                            :id="`${field.id}-${field.label}`"
                            ><ImportantIcon
                        /></span>
                    </div>
                </template>
                <b-form-input
                    :id="`custom-field-${field.id}`"
                    :value="fieldValueInternal"
                    :placeholder="$t('ResourceStatus.Placeholder')"
                    :state="field.required && hasError ? verifyValidFields(field) : null"
                    :type="
                        field.industrialStructParameterType === 'input_numeric' ? 'number' : 'text'
                    "
                    :disabled="disabled"
                    @input.native="
                        (e) => {
                            let newValue = applyMask(e.target.value);
                            e.target.value = newValue;
                            alterValueInternal(newValue);
                        }
                    "
                />
            </b-form-group>
        </b-col>
        <b-col
            md="12"
            class="form-group input-text-area"
            v-if="field.industrialStructParameterType === 'input_text_area'"
        >
            <b-form-group
                :label-for="`custom-field-${field.id}`"
                :invalid-feedback="$t('RequiredField')"
            >
                <template #label>
                    <div class="label">
                        {{ field.label }}
                        <span
                            v-if="field.important"
                            class="d-flex"
                            :id="`${field.id}-${field.label}`"
                            ><ImportantIcon
                        /></span>
                    </div>
                </template>
                <b-form-textarea
                    :id="`custom-field-${field.id}`"
                    :value="fieldValueInternal"
                    :placeholder="$t('ResourceStatus.Placeholder')"
                    :state="field.required && hasError ? verifyValidFields(field) : null"
                    :disabled="disabled"
                    @input="
                        (value) => {
                            alterValueInternal(value);
                        }
                    "
                    rows="2"
                    no-resize
                />
            </b-form-group>
        </b-col>
        <b-col
            md="12"
            :class="[
                field.industrialStructParameterType === 'switch' ? 'custom-switch-button' : '',
                field.industrialStructParameterType === 'checkbox' ? 'custom-checkbox' : ''
            ]"
            v-if="
                field.industrialStructParameterType === 'switch' ||
                field.industrialStructParameterType === 'checkbox'
            "
        >
            <div class="d-flex align-items-center">
                <b-form-checkbox
                    :id="field.name"
                    v-model="field.value"
                    name="resource-check-button"
                    :switch="field.industrialStructParameterType === 'switch'"
                    class="check-button p-0"
                    :disabled="disabled"
                    @change="(value) => alterValueInternal(value)"
                />
                <div class="text-allow label">
                    {{ $t(field.name) }}
                    <span
                        v-if="field.important"
                        class="d-flex"
                        :id="`${field.id}-${field.label}`"
                        ><ImportantIcon
                    /></span>
                </div>
            </div>
        </b-col>
        <b-col
            md="12"
            class="form-group datepicker"
            v-if="
                field.industrialStructParameterType === 'datepicker' ||
                field.industrialStructParameterType === 'datetime' ||
                field.industrialStructParameterType === 'range_dates' ||
                field.industrialStructParameterType === 'input_hour'
            "
        >
            <b-form-group
                :label-for="`custom-field-${field.id}`"
                :invalid-feedback="$t('RequiredField')"
            >
                <template #label>
                    <div class="label">
                        {{ field.label }}
                        <span
                            v-if="field.important"
                            class="d-flex"
                            :id="`${field.id}-${field.label}`"
                            ><ImportantIcon
                        /></span>
                    </div>
                </template>
                <DateTimePicker
                    :datepicker-id="`${field.id}`"
                    v-model="datepicker"
                    position="bottom"
                    :range="field.industrialStructParameterType === 'range_dates'"
                    :type="
                        field.industrialStructParameterType === 'input_hour'
                            ? 'time'
                            : field.industrialStructParameterType === 'datetime'
                              ? 'datetime'
                              : 'date'
                    "
                    :disabled="disabled"
                    ref="dateTimePickerRef"
                    :weekDays="setWeekDays()"
                    :month-names="setMonths()"
                    :txt-cancel-button="$t('Datepicker.Cancel')"
                    :txt-save-button="$t('Datepicker.Save')"
                    :txt-time="$t('Datepicker.Time')"
                    :txt-date="$t('Datepicker.Date')"
                    @input="(value) => handleDatepickerChange(value)"
                >
                    <template v-slot:input>
                        <div class="input-with-icon">
                            <TimeIcon
                                class="icon-input"
                                v-if="field.industrialStructParameterType === 'input_hour'"
                            />
                            <CalendarIcon
                                class="icon-input"
                                v-if="
                                    field.industrialStructParameterType === 'datepicker' ||
                                    field.industrialStructParameterType === 'datetime'
                                "
                            />

                            <b-form-input
                                :class="[
                                    field.industrialStructParameterType !== 'range_dates'
                                        ? 'input-datepicker'
                                        : ''
                                ]"
                                :id="`custom-field-${field.id}`"
                                :value="inputDatepicker"
                                type="text"
                                autocomplete="off"
                                :disabled="disabled"
                                :state="
                                    field.required && hasError ? verifyValidFields(field) : null
                                "
                                :placeholder="
                                    field.industrialStructParameterType === 'range_dates'
                                        ? `00/00/0000 ${$t('Operator.Until')} 00/00/0000`
                                        : field.industrialStructParameterType === 'datepicker'
                                          ? '00/00/0000'
                                          : field.industrialStructParameterType === 'input_hour'
                                            ? '00:00'
                                            : '00/00/0000 00:00'
                                "
                                onkeydown="return false;"
                            />
                        </div>
                    </template>
                    <template #errorMessage>
                        <div
                            v-if="field.required && hasError ? !verifyValidFields(field) : false"
                            class="error-message"
                        >
                            {{ errorMessage }}
                        </div>
                    </template>
                </DateTimePicker>
            </b-form-group>
        </b-col>
        <b-col
            md="12"
            class="custom-input-photo"
            v-if="field.industrialStructParameterType === 'photo'"
        >
            <b-form-group
                :label-for="`custom-field-${field.id}`"
                :invalid-feedback="$t('RequiredField')"
            >
                <template #label>
                    <div class="label">
                        {{ field.label }}
                        <span
                            v-if="field.important"
                            class="d-flex"
                            :id="`${field.id}-${field.label}`"
                            ><ImportantIcon
                        /></span>
                    </div>
                </template>
                <div :class="['input-button-photo', disabled ? '--disabled' : '']">
                    <b-form-input
                        :value="fieldValueInternal ? fieldValueInternal.name : ''"
                        :placeholder="$t('UploadPhoto')"
                        @click="openModal"
                        onkeydown="return false;"
                    ></b-form-input>

                    <!-- Botão para carregar a imagem -->
                    <b-button
                        v-if="!disabled"
                        variant="primary"
                        :class="loadingPhoto && 'btn-loading'"
                        @click="triggerFileUpload"
                    >
                        <LoadSpinner
                            v-if="loadingPhoto"
                            class="loading"
                        />
                        <div
                            v-else
                            class="info-btn"
                        >
                            <RemoveIcon v-if="image" />
                            <UploadIcon v-else />
                            {{ image ? $t("Operator.Remove") : $t("Upload") }}
                        </div>
                    </b-button>
                </div>

                <!-- Campo de input de arquivo oculto (com ref) -->
                <input
                    type="file"
                    ref="fileInput"
                    accept="image/png, image/jpeg"
                    @change="handleFileUpload"
                    style="display: none"
                />

                <b-modal
                    v-if="field.industrialStructParameterType === 'photo'"
                    v-model="showModal"
                    :title="fieldValueInternal ? fieldValueInternal.name : ''"
                    hide-footer
                >
                    <img
                        :src="fieldValueInternal ? fieldValueInternal.file : ''"
                        alt="Imagem carregada"
                        class="img-fluid"
                    />
                </b-modal>
            </b-form-group>
        </b-col>
        <Tooltip
            :target="`${field.id}-${field.label}`"
            placement="top"
        >
            <div class="text-left">
                <div>{{ $t("DataFieldsPage.ImportantField") }}</div>
            </div>
        </Tooltip>
    </div>
</template>

<script>
    import DateTimePicker from "components-code/date-time-picker/DatePickerFull.vue";
    import {
        BFormGroup,
        BFormInput,
        BButton,
        BCol,
        BFormCheckbox,
        BFormTextarea,
        BFormFile,
        BModal
    } from "bootstrap-vue";
    import Tooltip from "@/@core/layouts/layout-horizontal/components/Menu/Tooltip.vue";
    import moment from "moment";
    import LoadSpinner from "@/assets/images/pages/load-icon.svg";
    import { Loader } from "@googlemaps/js-api-loader";

    export default {
        name: "custom-fields",
        components: {
            SingleSelect: () => import("@core/components/multiselect/SingleSelect.vue"),
            CalendarIcon: () => import("@core/assets/icons/calendar-icon.svg"),
            TimeIcon: () => import("@core/assets/icons/time-icon.svg"),
            UploadIcon: () => import("@core/assets/icons/upload-icon.svg"),
            RemoveIcon: () => import("@core/assets/icons/remove-icon.svg"),
            ImportantIcon: () => import("@core/assets/icons/important-icon.svg"),
            BFormGroup,
            BFormInput,
            BButton,
            BCol,
            BFormCheckbox,
            BFormTextarea,
            DateTimePicker,
            BFormFile,
            BModal,
            LoadSpinner,
            Tooltip
        },
        props: {
            field: {
                type: Object,
                default: {}
            },
            hasError: {
                type: Boolean,
                default: null
            },
            isOrder: {
                type: Boolean,
                default: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            errorMessage: {
                type: String,
                default: ""
            }
        },
        data() {
            return {
                data: {},
                fieldValueInternal: null,
                autoCompleteService: null,
                placesService: null,
                sessionToken: null,
                coordinates: {
                    lat: 0,
                    lng: 0
                },
                suggestions: [],
                datepicker: [],
                inputDatepicker: null,
                image: null,
                showModal: false,
                loadingPhoto: false
            };
        },
        computed: {
            maxRangeDates() {
                return 31;
            },
            defaultDate() {
                //Periodo de dias pré selecionados para o range date
                return [
                    new Date(new Date().setDate(new Date().getDate() - (this.maxRangeDates - 1))),
                    new Date()
                ];
            }
        },
        created() {
            const isDateField =
                this.field.industrialStructParameterType === "range_dates" ||
                this.field.industrialStructParameterType === "input_hour" ||
                this.field.industrialStructParameterType === "datepicker" ||
                this.field.industrialStructParameterType === "datetime";
            const isHourField = this.field.industrialStructParameterType === "input_hour";
            let fieldValue = this.field.value;

            try {
                if (!fieldValue && isDateField) {
                    this.datepicker =
                        this.field.industrialStructParameterType === "range_dates"
                            ? [...this.defaultDate]
                            : !isHourField
                              ? new Date()
                              : null;
                }

                if (!!fieldValue && isDateField) {
                    this.datepicker = fieldValue;
                }

                if (isDateField) {
                    this.handleFormatInputDatepicker(fieldValue);
                }
            } catch (error) {
                console.error("Erro na conversão de data.");
            }

            if (
                Array.isArray(fieldValue) &&
                this.isOrder &&
                this.field.industrialStructParameterType === "select_multiple"
            ) {
                fieldValue = this.field.value.map((e) => parseInt(e));
            }

            this.fieldValueInternal = fieldValue;
        },
        methods: {
            handleFormatInputDatepicker(value) {
                if (!value) {
                    this.inputDatepicker = "";
                    return;
                }

                if (this.field.industrialStructParameterType === "range_dates") {
                    this.inputDatepicker = `${moment(value[0]).format("DD/MM/YYYY")} ${this.$t("Operator.Until")} ${moment(
                        value[1]
                    ).format("DD/MM/YYYY")}`;
                    return;
                }

                if (this.field.industrialStructParameterType === "datepicker") {
                    this.inputDatepicker = moment(value).format("DD/MM/YYYY");
                    return;
                }

                if (this.field.industrialStructParameterType === "input_hour") {
                    this.inputDatepicker = moment(value).format("HH:mm");
                    return;
                }

                if (this.field.industrialStructParameterType === "datetime") {
                    this.inputDatepicker = moment(value).format("DD/MM/YYYY HH:mm");
                    return;
                }
            },
            formatToISOString(dateString) {
                if (!dateString) return;

                if (dateString instanceof Date) {
                    return dateString.toISOString();
                }

                if (dateString.includes("T")) {
                    return dateString;
                }

                const [date, time] = dateString.split(" ");
                const [day, month, year] = date.split("/");
                return new Date(`${year}-${month}-${day}T${time}`).toISOString();
            },
            openModal() {
                if (this.fieldValueInternal?.file) {
                    this.showModal = true;
                }
            },
            timeStringToDate(timeString) {
                const [hours, minutes] = timeString.split(":").map(Number);
                const now = new Date();
                now.setHours(hours, minutes, 0, 0);

                return now;
            },
            triggerFileUpload() {
                if (this.image) {
                    this.removeImage();
                } else {
                    this.$refs.fileInput.click();
                }
            },
            openSelect() {
                if (this.field.industrialStructParameterType === "location") {
                    this.$getLocation({})
                        .then((coordinates) => {
                            this.coordinates = coordinates;
                            this.getPlaces();
                        })
                        .catch((error) => alert(error));
                }
            },
            handleFileUpload(event) {
                this.loadingPhoto = true;
                const file = event.target.files[0];
                if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
                    const reader = new FileReader();
                    const vm = this;
                    reader.onload = function (e) {
                        const data = { file: e.target.result, name: file.name };
                        vm.fieldValueInternal = { ...data };
                        vm.$emit("input", { ...data });
                    };

                    reader.readAsDataURL(file);

                    this.loadingPhoto = false;
                }
            },
            removeImage() {
                this.fieldValueInternal = null;
                this.$refs.fileInput.value = "";
            },
            handleDatepickerChange(value) {
                this.datepicker = value;
                this.handleFormatInputDatepicker(value);
                this.$emit("input", value);
            },
            resetDatepicker() {
                this.datepicker = [...this.defaultDate];
            },

            setWeekDays() {
                const weekdays = [
                    "Sunday",
                    "Monday",
                    "Tuesday",
                    "Wednesday",
                    "Thursday",
                    "Friday",
                    "Saturday"
                ];

                return weekdays.map((day) => ({
                    name: this.$t(`Datepicker.Weekdays.Full.${day}`),
                    short: this.$t(`Datepicker.Weekdays.Short.${day}`)
                }));
            },
            setMonths() {
                const months = [
                    "January",
                    "February",
                    "March",
                    "April",
                    "May",
                    "June",
                    "July",
                    "August",
                    "September",
                    "October",
                    "November",
                    "December"
                ];

                return months.map((month) => ({
                    name: this.$t(`Datepicker.Months.Full.${month}`),
                    short: this.$t(`Datepicker.Months.Short.${month}`)
                }));
            },
            blockDates(date) {
                const currentDate = moment(moment().format("YYYY-MM-DD"));
                const inspected = moment(moment(new Date(date)).format("YYYY-MM-DD"));
                return inspected > currentDate;
            },
            applyMask(value) {
                if (!this.field.mask) return value;

                return value.replace(this.field.mask, "");
            },
            verifyValidFields(field) {
                return field.value == null ||
                    field.value.length == 0 ||
                    field.value == "" ||
                    field.value == 0
                    ? false
                    : true;
            },
            async getPlaces() {
                const loader = new Loader({
                    apiKey: process.env.VUE_APP_GOGGLE_MAPS_API,
                    libraries: ["places"]
                });
                await loader.load();
                this.autoCompleteService = new google.maps.places.AutocompleteService();
                const map = new google.maps.Map(document.createElement("div"));
                this.placesService = new google.maps.places.PlacesService(map);

                this.placesService.nearbySearch(
                    {
                        location: this.coordinates,
                        radius: 1000
                    },
                    (places, status) => {
                        let placesFiltered = [];
                        places.map((e) => {
                            if (
                                !e.types.includes("locality") &&
                                !e.types.includes("sublocality") &&
                                !e.types.includes("country")
                            ) {
                                placesFiltered.push({
                                    value: e.place_id,
                                    text: e.vicinity
                                });
                            }
                        });
                        this.suggestions = placesFiltered;
                    }
                );
            },
            searchByAddress(v) {
                if (!this.sessionToken) {
                    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
                }
                this.autoCompleteService.getPlacePredictions(
                    { input: v, sessionToken: this.sessionToken },
                    (prediction, status) => {
                        let placesFiltered = [];
                        if (prediction) {
                            prediction.map((e) => {
                                placesFiltered.push({
                                    value: e.place_id,
                                    text: e.description
                                });
                            });
                        }
                        this.suggestions =
                            placesFiltered.length > 0
                                ? placesFiltered
                                : [{ value: null, text: "" }];
                    }
                );
            },
            alterValueInternal(value) {
                let valueInternal = value;

                if (this.field.industrialStructParameterType === "select_multiple") {
                    valueInternal = valueInternal.map((e) => e.value);
                }

                if (this.field.industrialStructParameterType === "select_unique") {
                    valueInternal = valueInternal && valueInternal.value;
                }

                if (this.field.industrialStructParameterType === "location") {
                    this.placesService.getDetails(
                        {
                            placeId: valueInternal.value,
                            fields: ["name", "formatted_address", "geometry.location"],
                            sessionToken: this.sessionToken
                        },
                        (place, status) => {}
                    );

                    this.sessionToken = null;
                }

                this.fieldValueInternal = valueInternal;

                this.$emit("input", this.fieldValueInternal);
            },
            verifySelectedOptions() {
                if (this.field.industrialStructParameterType === "location") {
                    return this.fieldValueInternal || [];
                }

                if (
                    !this.fieldValueInternal ||
                    (this.field.industrialStructParameterOption &&
                        this.field.industrialStructParameterOption.length === 0)
                )
                    return null;

                const isMultipleSelect =
                    this.field.industrialStructParameterType === "select_multiple";
                const fieldValueInternalSelecteds = isMultipleSelect
                    ? this.fieldValueInternal
                    : [Number(this.fieldValueInternal)];

                const optionsMultiple =
                    this.field.industrialStructParameterOption
                        ?.filter((e) => !!fieldValueInternalSelecteds?.includes?.(e.id))
                        ?.map((e) => ({
                            value: e.id,
                            text: e.name
                        })) || [];

                return isMultipleSelect ? optionsMultiple : optionsMultiple[0];
            }
        }
    };
</script>
<style lang="scss">
    ::-webkit-scrollbar {
        width: 7px;
    }

    ::-webkit-scrollbar-track {
        background: #fff;
        border-radius: 0 5px 5px 0;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #cfc4be;
        border-radius: 9px;
    }

    .input-with-icon {
        position: relative;
    }

    .input-with-icon .icon-input {
        position: absolute;
        left: 14px;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }

    .input-with-icon .input-datepicker {
        cursor: pointer;
        color: transparent;
        text-shadow: 0 0 0 #4c4541;
        padding: 9px 14px 9px 42px;

        &:focus {
            outline: none;
        }
    }

    .input-block {
        padding-inline: 16px !important;
    }

    .label {
        display: flex;
        gap: 5px;
        align-items: center;

        svg {
            cursor: pointer;
        }
    }

    .custom-multiple {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100% !important;
        border: none;

        .multiselect-invalid-feedback {
            margin: 3.5px 0 0 5px;
        }

        .multiselect-component-single {
            .multiselect__tags {
                width: 100% !important;
                height: 100% !important;
                white-space: nowrap !important;
                display: flex !important;
                align-items: center !important;
                overflow-x: scroll;
                overflow-y: hidden;
                margin-top: 0;
                height: 100% !important;
                cursor: grabbing;
                cursor: -webkit-grabbing;

                .multiselect__placeholder {
                    padding-bottom: 8px;
                    color: #cfc4be;
                }

                .multiselect__tag {
                    background-color: #974900;
                    margin-right: 0.6rem;
                    font-size: 13px;

                    .multiselect__tag-icon {
                        line-height: 21px;

                        &:hover {
                            background-color: #974900;
                            text-decoration: none;
                            opacity: 0.75;

                            &:after {
                                color: #000;
                            }
                        }

                        &:after {
                            color: #fff;
                        }
                    }
                }
                &::-webkit-scrollbar {
                    display: none !important;
                }
            }

            .multiselect__select {
                height: 34px;
                background-color: #fff;
                z-index: 1;
            }
        }

        &.focus {
            box-shadow: none;
        }
    }

    .form-group {
        .single-select {
            .multiselect--disabled {
                .multiselect__tags {
                    .multiselect__input {
                        .multiselect__single {
                            color: red !important;
                        }
                    }
                }
            }
        }
    }

    .custom-control-label:after {
        top: 3px !important;
    }

    .check-button {
        padding-block: 8px;
        line-height: 20px;
        min-height: 20px;
    }

    .error-message {
        color: #ea5455;
        font-size: 10px;
        margin: 3.5px 0 0 5px;
    }

    .text-allow {
        color: #4c4541;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
    }
    .custom-switch-button {
        .custom-control-label {
            margin: 0;
            line-height: 20px;
        }

        .custom-control-input ~ .custom-control-label::before {
            background-color: #e2e2e2;
            height: 20px;
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: #974900;
        }

        .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
            background-color: #e2e2e2;
            border-color: #e2e2e2;
        }
    }

    .custom-checkbox {
        margin-right: 23px;
        padding-bottom: 16px;
        .custom-control-label:after {
            top: 0 !important;
        }
        .custom-control-label {
            margin: 0;
            line-height: 20px;
        }

        .custom-control-input:focus ~ .custom-control-label::before {
            box-shadow: none !important;
            border-color: #e2e2e2 !important;
        }

        .custom-control-input:disabled:checked ~ .custom-control-label::before {
            background-color: #974900 !important;
            opacity: 0.8;
        }

        .custom-control-input:checked ~ .custom-control-label::before {
            background-color: #974900;
            box-shadow: none !important;
            border-color: #cfc4be !important;
        }

        .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
            background-color: #e2e2e2;
            border-color: #e2e2e2;
        }
    }
    .form-control:disabled {
        background-color: #efefef !important;
    }
    .custom-input-photo {
        .input-button-photo {
            display: flex;
            input {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                caret-color: transparent;
                color: #974900;
                cursor: pointer;
            }
            .btn {
                background-color: #974900 !important;
                border-color: #974900 !important;
                padding: 9px 21px;
                color: #ffffff;
                border-top-left-radius: 0;
                border-bottom-left-radius: 0;
                margin: 0 !important;
                .info-btn {
                    display: flex;
                    gap: 5px;
                    align-items: center;
                    justify-content: center;
                }

                &.btn-loading {
                    pointer-events: none;
                    width: 124px;
                    .loading {
                        width: 16px;
                        height: 16px;
                    }
                }

                &:hover {
                    box-shadow: none;
                }
            }

            &.--disabled {
                input {
                    background-color: #efefef;
                    color: #6e6b7b;
                    border-top-right-radius: 0.357rem;
                    border-bottom-right-radius: 0.357rem;

                    &:focus {
                        border-color: #d8d6de !important;
                        box-shadow: none;
                    }
                }
            }
        }
    }
</style>

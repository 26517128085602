<template>
    <div class="d-flex">
        <modal-step-by-step
            v-if="device !== 'mobile' && !modalTitle.includes('Edit')"
            :modalTitle="modalTitle"
            :isOpen="isOpen"
            :stepPosition="currentStep"
        />
        <b-sidebar
            id="add-edit-order-sidebar-right"
            :class="['sidebar-add-edit-order', modalTitle.includes('Edit') ? 'is-edit' : '']"
            right
            bg-variant="white"
            :width="device === 'desktop' ? '407px' : device === 'tablet' ? '60vw' : '100vw'"
            v-model="isOpen"
            @hidden="closeModal"
        >
            <template #header>
                <div class="header-sidebar">
                    <div :class="['sidebar-title', modalTitle.includes('Edit') ? '--edit' : '']">
                        {{
                            modalTitle.includes("Edit")
                                ? $t(modalTitle)
                                : $t(getModalTitle(currentStep))
                        }}
                    </div>
                    <span
                        v-if="modalTitle.includes('Edit')"
                        class="close-icon"
                        @click="closeModal()"
                    >
                        <CloseIcon />
                    </span>
                </div>
                <div class="separator"></div>
                <div
                    v-if="modalTitle.includes('Edit')"
                    class="modal-navbar"
                >
                    <div
                        :class="['order-info', currentTab === 'order-info' ? 'active' : '']"
                        @click="currentTab = 'order-info'"
                    >
                        {{ $t("orderView.Order") }}
                    </div>
                    <div
                        :class="['material-info', currentTab === 'material-info' ? 'active' : '']"
                        @click="currentTab = 'material-info'"
                    >
                        Material
                    </div>
                </div>
            </template>
            <template>
                <div v-show="currentTab === 'order-info'">
                    <b-col md="12">
                        <b-form-group
                            :label="$t('OrdersPage.OrderNumber')"
                            label-for="orderNumber"
                            :invalid-feedback="
                                flagOrderNumber
                                    ? this.$t('OrdersPage.EqualOrderNumber')
                                    : this.$t('RequiredField')
                            "
                        >
                            <b-form-input
                                id="orderNumber"
                                v-model="orderNumber"
                                :placeholder="$t('ResourceStatus.Placeholder')"
                                :state="states.orderNumber"
                                lazy-formatter
                                :formatter="
                                    () => {
                                        states.orderNumber = orderNumber !== '' ? null : false;
                                        return orderNumber;
                                    }
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="$t('OrdersPage.Priority')"
                            label-for="priority"
                            :invalid-feedback="$t('RequiredField')"
                        >
                            <b-form-input
                                id="priority"
                                v-model="priority"
                                :placeholder="$t('ResourceStatus.Placeholder')"
                                :state="states.priority"
                                lazy-formatter
                                type="text"
                                @input.native="
                                    (e) => {
                                        const maskApplied = e.target.value.replace(/[^0-9]/g, '');
                                        priority = maskApplied;
                                        e.target.value = maskApplied;
                                    }
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="$t('OrdersPage.Quantity')"
                            label-for="quantity"
                            :invalid-feedback="$t('RequiredField')"
                        >
                            <b-form-input
                                id="quantity"
                                v-model="quantity"
                                :placeholder="$t('ResourceStatus.Placeholder')"
                                :state="states.quantity"
                                lazy-formatter
                                type="text"
                                :formatter="
                                    () => {
                                        states.quantity = quantity !== '';
                                        return quantity;
                                    }
                                "
                                @input.native="
                                    (e) => {
                                        const maskApplied = e.target.value.replace(/[^0-9]/g, '');
                                        quantity = maskApplied;
                                        e.target.value = maskApplied;
                                    }
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col
                        md="12"
                        class="form-group"
                    >
                        <Multiselect
                            label="Tags"
                            :fieldPlaceholder="$t('Select')"
                            :status="states.tags"
                            :feedback="$t('RequiredField')"
                            :datalist="tagsList"
                            :notFoundMessage="$t('NoResultsFound')"
                            :selectedItems.sync="tags"
                            :searchPlaceholder="$t('message.SearchPlaceholderDefault')"
                        />
                    </b-col>
                    <div v-if="forceRenderIndustrialParametersList">
                        <custom-fields
                            :field="{
                                id: 'delivery-date-datepicker',
                                label: $t('OrdersPage.DeliveryDate'),
                                industrialStructParameterType: 'datetime',
                                value: inputDeliveryDatepicker,
                                type: 'datetime'
                            }"
                            @input="(value) => handleDatepickerChange(value)"
                        />
                    </div>
                </div>
                <div v-show="currentTab === 'material-info'">
                    <b-col
                        md="12"
                        class="form-group"
                    >
                        <label for="material-select"> Material </label>

                        <SingleSelect
                            id="material-select"
                            :placeholder="$t('Select')"
                            :optionSelected="materialList.find((item) => item.value == material)"
                            :options="materialList"
                            :searchable="true"
                            :translate="true"
                            :state="states.material"
                            @input="(value) => (material = value ? value.value : value)"
                        >
                            {{ $t("RequiredField") }}
                        </SingleSelect>
                    </b-col>
                    <b-col
                        md="12"
                        class="form-group"
                    >
                        <label for="route-select">
                            {{ $t("MaterialsPage.RouteSingular") }}
                        </label>
                        <SingleSelect
                            id="route-select"
                            :placeholder="$t('Select')"
                            :optionSelected="routeList.find((item) => item.value == route)"
                            :options="routeList.map((e) => ({ value: e.value, text: e.text }))"
                            :defaultValue="routeList.find((e) => e.default)"
                            :searchable="true"
                            :translate="true"
                            :state="states.route"
                            :disabled="material === null"
                            @input="(value) => (route = value ? value.value : value)"
                        >
                            {{ $t("RequiredField") }}
                        </SingleSelect>
                    </b-col>
                </div>
                <div
                    v-if="
                        currentTab === 'order-info' &&
                        customFields &&
                        customFields.length &&
                        forceRenderIndustrialParametersList
                    "
                >
                    <div
                        v-for="field in customFields"
                        :key="field.id"
                    >
                        <custom-fields
                            :field="field"
                            :hasError="states.customFieldsForceError"
                            @input="
                                (value) => {
                                    field.value = value ? value : null;
                                }
                            "
                        />
                    </div>
                </div>
                <div
                    v-if="
                        currentTab === 'material-info' &&
                        currentCustomField.length &&
                        forceRenderIndustrialParametersList
                    "
                >
                    <div
                        v-for="field in currentCustomField"
                        :key="field.id"
                    >
                        <custom-fields
                            :field="field"
                            disabled
                            isOrder
                        />
                    </div>
                </div>
                <div v-show="currentTab === 'material-info' && route">
                    <div v-if="forceRenderIndustrialParametersList && operationsList.length">
                        <div
                            v-for="operation in sortOperationsByNumber(operationsList)"
                            :key="operation.id"
                            class="operation-row"
                        >
                            <div
                                @click="openOperationCollapse(operation.id)"
                                :class="[
                                    'operation-header',
                                    openOperationId !== operation.id ? 'not-collapsed' : 'collapsed'
                                ]"
                            >
                                <div class="operation-text">
                                    {{ operation.number }} · {{ operation.name }}
                                </div>
                                <ArrowDownIcon />
                            </div>
                            <b-collapse :visible="openOperationId === operation.id">
                                <div class="operation-body">
                                    <custom-fields
                                        :field="{
                                            id: `${operation.id}-setup-start`,
                                            label: $t('OrdersPage.SetupStart'),
                                            industrialStructParameterType: 'datetime',
                                            required: true,
                                            value: operation.setupStart,
                                            type: 'datetime'
                                        }"
                                        :hasError="
                                            isToValidate &&
                                            (operation.endTime !== '' || operation.startTime !== '')
                                        "
                                        :errorMessage="$t('RequiredField')"
                                        @input="
                                            (value) => {
                                                operation.setupStart = value;
                                            }
                                        "
                                    />
                                    <custom-fields
                                        :field="{
                                            id: `${operation.id}-operation-start`,
                                            label: $t('OrdersPage.OperationStart'),
                                            industrialStructParameterType: 'datetime',
                                            required: true,
                                            value: operation.startTime,
                                            type: 'datetime'
                                        }"
                                        :hasError="
                                            isToValidate &&
                                            (operation.setupStart !== '' ||
                                                operation.endTime !== '')
                                        "
                                        :errorMessage="$t('RequiredField')"
                                        @input="
                                            (value) => {
                                                operation.startTime = value;
                                            }
                                        "
                                    />
                                    <custom-fields
                                        :field="{
                                            id: `${operation.id}-operation-end`,
                                            label: $t('OrdersPage.OperationEnd'),
                                            industrialStructParameterType: 'datetime',
                                            required: true,
                                            value: operation.endTime,
                                            type: 'datetime'
                                        }"
                                        :hasError="
                                            isToValidate &&
                                            (operation.setupStart !== '' ||
                                                operation.startTime !== '')
                                        "
                                        :errorMessage="$t('RequiredField')"
                                        @input="
                                            (value) => {
                                                operation.endTime = value;
                                            }
                                        "
                                    />
                                    <div
                                        v-if="
                                            operation.industrialParameters.length &&
                                            forceRenderIndustrialParametersList
                                        "
                                    >
                                        <div
                                            v-for="field in operation.industrialParameters"
                                            :key="field.id"
                                        >
                                            <custom-fields
                                                v-if="field.value !== null"
                                                :field="field"
                                                isOrder
                                                disabled
                                            />
                                        </div>
                                    </div>
                                </div>
                            </b-collapse>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="separator"></div>
                <div
                    v-if="device === 'mobile' && !modalTitle.includes('Edit')"
                    class="step-action"
                >
                    <div class="d-flex justify-content-between">
                        <div
                            :class="[
                                'step-collapse',
                                isStepBlockOpenned ? 'not-collapsed' : 'collapsed'
                            ]"
                        >
                            {{ `${currentStep} ${$t("Of")} 2`
                            }}<span
                                id="step-collapse"
                                @click="openStepBlock()"
                                class="icon"
                            >
                                <ArrowDownIcon
                            /></span>
                        </div>
                        <div class="text">{{ $t(getModalTitle(currentStep)) }}</div>
                    </div>
                    <div class="step-progress">
                        <div
                            :class="[
                                'first-step',
                                currentStep === 1
                                    ? 'step-active'
                                    : currentStep > 1
                                      ? 'next-step'
                                      : ''
                            ]"
                        ></div>
                        <div
                            :class="[
                                'second-step',
                                currentStep === 2
                                    ? 'step-active'
                                    : currentStep > 2
                                      ? 'next-step'
                                      : ''
                            ]"
                        ></div>
                    </div>
                    <b-collapse :visible="isStepBlockOpenned">
                        <div class="progress-block">
                            <div class="separator-progress"></div>
                            <div class="detail-block">
                                <div
                                    :class="[
                                        'info-block',
                                        currentStep === 1
                                            ? 'step-active'
                                            : currentStep > 1
                                              ? 'next-step'
                                              : ''
                                    ]"
                                >
                                    <div class="icon"><OrderIcon /></div>
                                    <div class="text">{{ $t("OrdersPage.OrderInformation") }}</div>
                                </div>
                                <div
                                    :class="[
                                        'vertical-separator',
                                        currentStep === 2 ? 'next-step' : ''
                                    ]"
                                ></div>
                                <div
                                    :class="[
                                        'material-info-block',
                                        currentStep === 2 ? 'step-active' : ''
                                    ]"
                                >
                                    <div class="icon"><MaterialIcon /></div>
                                    <div class="text">
                                        {{ $t("OrdersPage.MaterialInformation") }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                </div>
                <div class="separator-progress"></div>
                <b-col
                    md="12"
                    class="footer-sidebar"
                >
                    <div class="d-flex buttons">
                        <b-button
                            class="btn btn-cancel btn-footer btn-cancel-outline"
                            @click="emitCancel()"
                        >
                            {{ $t("RefuseStatus.Cancel") }}
                        </b-button>
                        <b-button
                            v-if="!modalTitle.includes('Edit') && currentStep !== 1"
                            class="btn-previous btn-footer"
                            @click="previousStep()"
                        >
                            {{ $t("PeoplePage.Previous") }}
                        </b-button>
                        <b-button
                            v-if="modalTitle.includes('Edit') || currentStep === 2"
                            class="btn btn-save btn-footer"
                            @click="onSave()"
                        >
                            {{ $t("RefuseStatus.Save") }}
                        </b-button>
                        <b-button
                            v-else
                            class="btn btn-save btn-footer"
                            @click="nextStep()"
                        >
                            {{ $t("RefuseStatus.Next") }}
                        </b-button>
                    </div>
                </b-col>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import { BFormGroup, BFormInput, BButton, BCol, BSidebar, BCollapse } from "bootstrap-vue";
    import { mapState, mapMutations } from "vuex";
    import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
    import Multiselect from "components-code";
    import modalStepByStep from "./ModalStepByStep.vue";
    import CustomFields from "@core/components/customFields/index.vue";
    import DateTimePicker from "components-code/date-time-picker/DatePickerFull.vue";
    import moment from "moment";
    import {
        handleCreateParametersForPayload,
        handleFillCustomFields,
        handleFillSingleCustomFields,
        processIndustrialParameters
    } from "@/utils/customFields";

    export default {
        components: {
            BFormGroup,
            DateTimePicker,
            BFormInput,
            BButton,
            BCol,
            BSidebar,
            BCollapse,
            CustomFields,
            Multiselect,
            modalStepByStep,
            CloseIcon: () => import("@core/assets/icons/close-icon.svg"),
            MaterialIcon: () => import("@core/assets/icons/material-hierarchy-icon.svg"),
            OrderIcon: () => import("@core/assets/icons/order-info-icon.svg"),
            ArrowDownIcon: () => import("@core/assets/icons/arrow-down.svg"),
            SingleSelect: () => import("@core/components/multiselect/SingleSelect.vue"),
            EmptyBoxIcon: () => import("@core/assets/icons/empty-box-icon.svg")
        },
        props: {
            modalTitle: {
                type: String,
                default: ""
            },
            currentId: {
                type: Number,
                default: null
            },
            device: {
                type: String,
                default: "desktop"
            },
            customFields: {
                type: Array,
                default: () => []
            },
            tagsList: {
                type: Array,
                default: []
            },
            materialList: {
                type: Array,
                default: []
            }
        },
        data() {
            return {
                forceRenderIndustrialParametersList: true,
                currentStep: 1,
                isOpen: false,
                isFirstEditCall: false,
                flagOrderNumber: false,
                isStepBlockOpenned: false,
                isToValidate: false,
                openOperationId: null,
                prefix: localStorage.getItem("prefix"),
                currentTab: "order-info",
                orderNumber: "",
                priority: "",
                quantity: "",
                tags: [],
                material: null,
                route: null,
                inputDeliveryDatepicker: "",
                currentCustomField: [],
                routeList: [],
                operationsList: [],
                operationsCustomFieldsList: [],
                states: {
                    orderNumber: null,
                    priority: null,
                    quantity: null,
                    tags: null,
                    material: null,
                    route: null
                }
            };
        },
        async mounted() {
            this.UPDATE_MODAL_ADD_EDIT_ORDER(false);
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("productionOrders", ["UPDATE_MODAL_ADD_EDIT_ORDER"]),
            openStepBlock() {
                this.isStepBlockOpenned = !this.isStepBlockOpenned;
            },
            openOperationCollapse(operationId) {
                this.openOperationId = this.openOperationId === operationId ? null : operationId;
            },
            closeModal() {
                this.UPDATE_MODAL_ADD_EDIT_ORDER(false);
            },
            emitCancel() {
                this.UPDATE_MODAL_ADD_EDIT_ORDER(false);
                this.clearValidations();
            },
            handleDatepickerChange(value) {
                this.inputDeliveryDatepicker = value;
            },
            getModalTitle(v) {
                if (v === 1) return "OrdersPage.OrderInformation";
                if (v === 2) return "OrdersPage.MaterialInformation";
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            },
            sortOperationsByNumber(operations) {
                return [...operations].sort((a, b) => {
                    if (a.number && b.number) {
                        return a.number - b.number;
                    } else if (!a.number) {
                        return 1;
                    } else if (!b.number) {
                        return -1;
                    }
                    return 0;
                });
            },
            displayImportantFirst(materialId) {
                const list = this.materialList.find(
                    (e) => e.value == materialId
                ).industrialParameter;
                this.currentCustomField = list.sort((a, b) => {
                    if (a.important && !b.important) {
                        return -1;
                    }
                    if (!a.important && b.important) {
                        return 1;
                    }
                    return 0;
                });
            },
            async getRouteList(materialId) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                await this.$http2
                    .get(`/api/myconfig/order/material/${materialId}/routes`)
                    .then((response) => {
                        this.routeList = response.data.data
                            .map((e) => ({
                                default: e.default,
                                value: e.id,
                                text: e.name
                            }))
                            .sort((a, b) => a.text.localeCompare(b.text));
                        if (
                            this.routeList.find((e) => e.default) &&
                            !this.modalTitle.includes("Edit")
                        ) {
                            this.route = this.routeList.find((e) => e.default).value;
                        }
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            },
            async getOperationList(routeId) {
                if (this.isFirstEditCall) {
                    this.isFirstEditCall = false;
                    return;
                }
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                await this.$http2
                    .get(`/api/myconfig/order/route/${routeId}/operations`)
                    .then((response) => {
                        this.operationsList = response.data.data.map((e) => {
                            const formattedIndustrialParameters = processIndustrialParameters(
                                e.industrialParameters
                            );

                            handleFillSingleCustomFields(formattedIndustrialParameters);
                            return {
                                ...e,
                                industrialParameters: formattedIndustrialParameters,
                                setupStart: "",
                                startTime: "",
                                endTime: ""
                            };
                        });
                        this.forceRenderIndustrialParametersValue();
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            },
            async getInfos() {
                if (this.modalTitle.includes("Edit")) {
                    this.isFirstEditCall = true;
                    this.UPDATE_FLAG_SHOW_OVERLAY(true);
                    await this.$http2
                        .get(`/api/myconfig/order/${this.currentId}`)
                        .then((response) => {
                            this.orderNumber = response.data.data.order.orderNumber;
                            this.priority = response.data.data.order.priority;
                            this.quantity = response.data.data.order.quantity;
                            this.tags = response.data.data.tags;
                            this.operationsList = response.data.data.operation.map((e) => {
                                e.industrialParameters = e.industrialParameters.map(
                                    (item, index) => {
                                        return {
                                            ...item,
                                            value:
                                                typeof item.value == "object" && item.value
                                                    ? JSON.stringify(item.value.map(Number))
                                                    : item.value
                                        };
                                    }
                                );

                                const formattedIndustrialParameters = processIndustrialParameters(
                                    e.industrialParameters
                                );

                                handleFillCustomFields(
                                    formattedIndustrialParameters,
                                    e.industrialParameters
                                );

                                return {
                                    ...e,
                                    industrialParameters: formattedIndustrialParameters,
                                    setupStart: e.setupStart ? new Date(e.setupStart) : null,
                                    startTime: e.startTime ? new Date(e.startTime) : null,
                                    endTime: e.endTime ? new Date(e.endTime) : null
                                };
                            });
                            this.inputDeliveryDatepicker = response.data.data.order.deliveryDate
                                ? new Date(response.data.data.order.deliveryDate)
                                : null;
                            this.material = response.data.data.order.materialId;
                            this.route = response.data.data.order.routeId;
                            handleFillCustomFields(
                                this.customFields,
                                response?.data?.data?.industrialParameters
                            );

                            this.forceRenderIndustrialParametersValue();
                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        });
                }
            },
            clearFields() {
                this.orderNumber = "";
                this.inputDeliveryDatepicker = "";
                this.priority = "";
                this.quantity = "";
                this.material = null;
                this.route = null;
                this.tags = [];
                this.openOperationId = null;
                this.isStepBlockOpenned = false;
                this.isFirstEditCall = false;
                this.currentStep = 1;
                this.currentTab = "order-info";
                this.currentCustomField = [];
                this.customFields.map((e) => (e.value = null));
            },
            clearValidations() {
                this.states.orderNumber = null;
                this.states.priority = null;
                this.states.quantity = null;
                this.states.tags = null;
                this.states.customFieldsForceError = null;
                this.isToValidate = false;
            },
            fieldsValidate() {
                const requiredFields = this.customFields.filter((e) => e.required);
                const isCustomFieldsValid = requiredFields.map((e) => {
                    if (e.required) {
                        return e.value != null && e.value != 0;
                    }
                });
                const hasInvalidFields = isCustomFieldsValid.includes(false);
                hasInvalidFields
                    ? (this.states.customFieldsForceError = true)
                    : (this.states.customFieldsForceError = false);

                const validOrderNumber = (this.states.orderNumber =
                    this.orderNumber && this.flagOrderNumber === false ? true : false);

                const validQuantity = (this.states.quantity = Number(this.quantity) !== 0);

                let validOperationDatepickers = true;
                let validMaterial = true;
                let validRoute = true;

                if (this.currentTab === "material-info") {
                    this.isToValidate = true;

                    validMaterial = this.states.material = this.material !== null;
                    validRoute = this.states.route = this.route !== null;

                    const operations = this.operationsList;

                    validOperationDatepickers = operations.every((operation) => {
                        const setupStart = operation.setupStart;
                        const startTime = operation.startTime;
                        const endTime = operation.endTime;
                        const allFieldsFilled = !!setupStart && !!startTime && !!endTime;
                        const allFieldsEmpty = !setupStart && !startTime && !endTime;

                        return allFieldsFilled || allFieldsEmpty;
                    });
                }

                return (
                    !hasInvalidFields &&
                    validOrderNumber &&
                    validQuantity &&
                    validMaterial &&
                    validRoute &&
                    validOperationDatepickers
                );
            },
            previousStep() {
                this.currentStep = this.currentStep - 1;
            },
            nextStep() {
                this.flagOrderNumber = false;
                const isValid = this.fieldsValidate();
                if (!isValid) return;

                if (this.currentStep < 3) {
                    this.currentStep = this.currentStep + 1;
                }
            },
            onSave() {
                this.flagOrderNumber = false;
                const isValid = this.fieldsValidate();
                if (!isValid) return;
                if (this.modalTitle.includes("Edit")) {
                    this.editOrder();
                } else {
                    this.createOrder();
                }
            },
            async createOrder() {
                const parameters = handleCreateParametersForPayload(this.customFields);
                const operations = this.operationsList.map((e) => ({
                    ...e,
                    setupStart: e.setupStart ? this.adjustTimeZone3(e.setupStart) : null,
                    startTime: e.startTime ? this.adjustTimeZone3(e.startTime) : null,
                    endTime: e.endTime ? this.adjustTimeZone3(e.endTime) : null
                }));

                const payload = {
                    orderNumber: this.orderNumber,
                    priority: Number(this.priority),
                    quantity: Number(this.quantity),
                    tags: this.tags,
                    deliveryDate: this.inputDeliveryDatepicker
                        ? this.adjustTimeZone3(this.inputDeliveryDatepicker)
                        : null,
                    materialId: this.material,
                    routeId: this.route,
                    industrialParameter: parameters,
                    operation: operations
                };

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                await this.$http2
                    .post("/api/myconfig/order", payload)
                    .then((response) => {
                        this.showToast(
                            this.$t("IntegrationPage.success"),
                            "SmileIcon",
                            this.$t("OrdersPage.OrderCreated"),
                            "success"
                        );
                        this.$emit("updateList");
                        this.closeModal();
                    })
                    .catch((error) => {
                        error.response.data.errors.map((e) => {
                            if (e.id === 3) {
                                this.flagOrderNumber = true;
                                this.currentStep = 1;
                                this.currentTab = "order-info";
                            } else {
                                this.showToast(this.$t("Error"), "FrownIcon", e.message, "danger");
                            }
                        });
                        this.fieldsValidate();
                    })
                    .finally(() => {
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            },
            async editOrder() {
                const parameters = handleCreateParametersForPayload(this.customFields);
                const operations = this.operationsList.map((e) => ({
                    ...e,
                    setupStart: e.setupStart ? this.adjustTimeZone3(e.setupStart) : null,
                    startTime: e.startTime ? this.adjustTimeZone3(e.startTime) : null,
                    endTime: e.endTime ? this.adjustTimeZone3(e.endTime) : null
                }));

                const payload = {
                    orderNumber: this.orderNumber,
                    priority: Number(this.priority),
                    quantity: Number(this.quantity),
                    tags: this.tags,
                    deliveryDate: this.inputDeliveryDatepicker
                        ? this.adjustTimeZone3(this.inputDeliveryDatepicker)
                        : null,
                    materialId: this.material,
                    routeId: this.route,
                    industrialParameter: parameters,
                    operation: operations
                };

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                await this.$http2
                    .put(`/api/myconfig/order/${this.currentId}`, payload)
                    .then((response) => {
                        this.showToast(
                            this.$t("IntegrationPage.success"),
                            "SmileIcon",
                            this.$t("OrdersPage.OrderEdited"),
                            "success"
                        );
                        this.$emit("updateList");
                        this.closeModal();
                    })
                    .catch((error) => {
                        error.response.data.errors.map((e) => {
                            const orderEqualId = 124;
                            if (e.id === orderEqualId) {
                                this.flagOrderNumber = true;
                                this.currentTab = "order-info";
                            } else {
                                this.showToast(this.$t("Error"), "FrownIcon", e.message, "danger");
                            }
                        });
                        this.fieldsValidate();
                    })
                    .finally(() => {
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            },
            adjustTimeZone3(date) {
                // Lógica temporaria para resolver problema de -3 do fuso
                const offset = date.getTimezoneOffset() * 60000;
                const localISOTime = new Date(date.getTime() - offset).toISOString().slice(0, -1);

                return localISOTime;
            },
            forceRenderIndustrialParametersValue() {
                this.forceRenderIndustrialParametersList = false;
                this.$nextTick().then(() => {
                    this.forceRenderIndustrialParametersList = true;
                });
            }
        },
        computed: {
            ...mapState("productionOrders", {
                isModalAddEditOpen: "isModalAddEditOpen"
            })
        },
        watch: {
            isModalAddEditOpen(v) {
                this.isOpen = v;
                this.clearFields();
                this.clearValidations();
                if (v) {
                    this.getInfos();
                } else {
                    this.flagOrderNumber = false;
                    this.forceRenderIndustrialParametersValue();
                }
            },
            currentStep(v) {
                if (v === 1) this.currentTab = "order-info";
                if (v === 2) this.currentTab = "material-info";
            },
            material(v) {
                if (!this.isFirstEditCall || !v) {
                    this.route = null;
                    this.routeList = [];
                    this.operationsList = [];
                    this.currentCustomField = [];
                }
                if (v) {
                    this.getRouteList(v);
                    this.displayImportantFirst(v);
                    this.forceRenderIndustrialParametersValue();
                }
            },
            route(v) {
                if (!this.isFirstEditCall || !v) {
                    this.operationsList = [];
                }
                if (v) {
                    this.getOperationList(v);
                }
            }
        }
    };
</script>
<style lang="scss">
    .sidebar-add-edit-order {
        .custom-select {
            cursor: pointer;
            padding: 4px 34px 4px 8px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
            &[disabled] {
                border-radius: 5px;
                border: 1px solid #7e7570;
                background-color: #eee;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
                color: #7e7570 !important;
            }
        }

        .b-sidebar-header {
            display: contents;

            .header-sidebar {
                display: flex;
                justify-content: space-between;
                padding: 16px;

                .sidebar-title {
                    display: flex;
                    align-items: center;
                    color: #4c4541;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 600;
                    min-height: 26px;

                    &.--edit {
                        font-size: 18px;
                    }
                }
            }

            .modal-navbar {
                display: flex;

                .order-info,
                .material-info {
                    cursor: pointer;
                    width: 100%;
                    text-align: center;
                    color: #998f8a;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 16px 0 14px;
                    border-bottom: 1px solid #cfc4be;

                    &.active {
                        color: #974900;
                        background: #fff;
                        box-shadow: 0px -4px 0px -1px #974900 inset;
                    }
                }
            }

            .close-icon {
                border: 1px solid #974900;
                border-radius: 5px;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    width: 12px;
                    height: 12px;
                    path {
                        fill: #974900;
                    }
                }

                &:hover {
                    background-color: #ffede2 !important;
                }

                &:active {
                    background-color: #ffdbc4 !important;
                }
            }

            .separator {
                width: 100%;
                height: 1px;
                background: #cfc4be;
            }
        }

        &.is-edit .b-sidebar {
            box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
            border-left: none;
        }

        .b-sidebar {
            box-shadow: none;
            border-left: 1px solid #cfc4be;
            transition: all 0.5s ease-out;
            height: 100% !important;
            .b-sidebar-body {
                padding: 16px 0;

                .operation-row {
                    border-bottom: 1px solid #cfc4be;
                    background-color: #fff;

                    .operation-header {
                        padding: 16px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        cursor: pointer;

                        .operation-text {
                            color: #4c4541;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 20px;
                        }

                        svg {
                            fill: #4c4541;
                            height: 16px;
                            width: 16px;
                        }

                        &.not-collapsed {
                            svg {
                                transform: rotate(0);
                                transition: all 0.3s;
                            }
                        }

                        &.collapsed {
                            svg {
                                transform: rotate(-180deg);
                                transition: all 0.3s;
                            }
                        }
                    }

                    .operation-body {
                        padding: 0 0 16px;
                        display: flex;
                        flex-direction: column;
                    }
                }

                .form-group {
                    .form-control.is-invalid,
                    .form-control.is-valid {
                        background-image: none !important;
                    }

                    .form-control {
                        &::placeholder {
                            color: #cfc4be;
                        }
                        &:focus {
                            border-color: #974900;
                        }
                    }
                }

                &::-webkit-scrollbar {
                    display: none !important;
                }
            }
        }

        .step-action {
            padding: 16px 0;

            .step-collapse {
                padding-left: 16px;
            }

            .step-collapse,
            .text {
                display: flex;
                gap: 8px;
                color: #4c4541;
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                .icon {
                    svg {
                        width: 16px;
                        height: 16px;
                        fill: #974900;
                    }
                }

                &.not-collapsed {
                    svg {
                        transform: rotate(-180deg);
                        transition: all 0.3s;
                    }
                }

                &.collapsed {
                    svg {
                        transform: rotate(0);
                        transition: all 0.3s;
                    }
                }
            }
            .text {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                padding-right: 16px;
            }

            .step-progress {
                display: flex;
                gap: 4px;
                padding: 4px 16px 0;
                .first-step,
                .second-step,
                .third-step {
                    width: 50%;
                    height: 4px;
                    border-radius: 4px;
                    background: #f4eee8;

                    &.step-active {
                        background: #974900;
                    }

                    &.next-step {
                        background: #66bb6a;
                    }
                }
            }
        }

        .progress-block {
            padding-top: 16px;

            .detail-block {
                padding: 16px 16px 0;

                .material-info-block {
                    svg {
                        stroke: #cfc4be;
                    }
                    &.step-active {
                        svg {
                            stroke: #974900;
                        }
                    }
                }

                .info-block,
                .material-info-block {
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .icon {
                        display: flex;
                        align-items: center;
                        width: 30px;
                        height: 30px;
                        padding: 7px;
                        border-radius: 50%;
                        border: 1px solid #cfc4be;

                        svg {
                            width: 14px;
                            height: 14px;
                            fill: #cfc4be;
                        }
                    }

                    .text {
                        overflow: hidden;
                        color: #cfc4be;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                    }

                    &.step-active {
                        .icon {
                            border-color: #974900;
                            svg {
                                fill: #974900;
                            }
                        }

                        .text {
                            color: #974900;
                        }
                    }

                    &.next-step {
                        .icon {
                            border-color: #66bb6a;
                            background: #66bb6a;
                            svg {
                                fill: #fff;
                            }
                        }

                        .text {
                            color: #66bb6a;
                        }
                    }
                }

                .vertical-separator {
                    margin-left: 14px;
                    width: 1px;
                    height: 16px;
                    background: #cfc4be;

                    &.step-active {
                        background: #974900;
                    }

                    &.next-step {
                        background: #66bb6a;
                    }
                }
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
        }

        .separator-progress {
            width: 100%;
            height: 1px;
            background: #eee;
        }

        .footer-sidebar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 16px;

            .buttons,
            .edit-buttons {
                gap: 8px;
            }

            @media (max-width: 480px) {
                justify-content: center;
                .buttons {
                    width: 100%;

                    .btn {
                        width: 50%;
                    }
                }

                .btn-footer {
                    font-size: 12px !important;
                    padding: 3px 21px !important;
                }
            }

            .btn-footer {
                box-shadow: none;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                border-radius: 5px;

                &:hover {
                    box-shadow: none;
                }

                &.btn-cancel {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid transparent !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-previous,
                &.btn-cancel.btn-cancel-outline {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-save {
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    color: #ffff !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #9f5714 !important;
                    }

                    &:active {
                        background-color: #a45f1f !important;
                    }
                }
            }
        }
    }
</style>

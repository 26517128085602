<template>
    <div id="header-action-calendar">
        <div class="first-block">
            <span
                class="icon-block"
                @click="() => {}"
            >
                <SettingsIcon class="settings-icon" />
            </span>
            <div class="text-block">{{ `${name} • ${duration} ${$t("ProductionList.Days")}` }}</div>
        </div>
        <div class="second-block">
            {{ $t("CalendarsPage.StartsAt") }}
            <span class="ref-date">{{ refDate }}</span>
        </div>
    </div>
</template>

<script>
    import { mapMutations } from "vuex";
    import SettingsIcon from "@core/assets/icons/settings-icon.svg";

    export default {
        props: {
            name: {
                type: String,
                default: ""
            },
            duration: {
                type: String | Number,
                default: ""
            },
            refDate: {
                type: String,
                default: ""
            }
        },
        components: { SettingsIcon },
        data() {
            return {
                isNew: this.$router.currentRoute.name.includes("new"),
                newName: "",
                newDuration: null,
                newRefDate: ""
            };
        },
        mounted() {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"])
        }
    };
</script>

<style lang="scss">
    #header-action-calendar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;
        gap: 8px;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

        .first-block {
            display: flex;
            gap: 8px;

            .icon-block {
                border: 1px solid #974900;
                border-radius: 5px;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                .settings-icon {
                    width: 12px;
                    height: 12px;
                    fill: #974900;
                }

                &:hover {
                    background-color: #ffede2 !important;
                }

                &:active {
                    background-color: #ffdbc4 !important;
                }
            }

            .text-block {
                display: flex;
                align-items: center;
                color: #4c4541;
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
            }
        }

        .second-block {
            color: #4c4541;
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;

            .ref-date {
                font-weight: 600;
            }
        }
    }

    @media (max-width: 768px) {
        #header-action-calendar {
            margin-top: 16px;
            flex-wrap: wrap;
        }
    }

    @media (max-width: 480px) {
        #header-action-calendar {
            margin-top: 16px;
            flex-direction: column;
            align-items: start;

            .first-block {
                .text-block {
                    font-size: 16px;
                }
            }

            .second-block {
                font-size: 12px;
            }
        }
    }
</style>

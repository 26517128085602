function handleCreateParametersForPayload(customFields) {
    return customFields
        .filter(
            (e) =>
                e.industrialStructParameterType !== "checkbox" ||
                (e.industrialStructParameterType === "checkbox" && !!e.value)
        )
        .flatMap((e) => {
            if (
                e.industrialStructParameterType === "select_multiple" &&
                e.value &&
                e.value.length > 0
            ) {
                return e.value?.map((f) => {
                    return {
                        id: e.id,
                        value: f ? String(f) : ""
                    };
                });
            }

            if (e.industrialStructParameterType === "checkbox") {
                return {
                    id: e?.industrialParameterId ?? "",
                    value: String(e.id)
                };
            }

            if (
                e.industrialStructParameterType === "photo" ||
                e.industrialStructParameterType === "location"
            ) {
                return {
                    id: e.id,
                    value: e.value ? JSON.stringify(e.value) : ""
                };
            }

            if (
                e.industrialStructParameterType === "datepicker" ||
                e.industrialStructParameterType === "datetime" ||
                e.industrialStructParameterType === "input_hour"
            ) {
                return {
                    id: e.id,
                    value: e.value ? e.value.toISOString() : ""
                };
            }

            if (e.industrialStructParameterType === "range_dates") {
                return {
                    id: e.id,
                    value: e.value
                        ? JSON.stringify([e.value[0].toISOString(), e.value[1].toISOString()])
                        : ""
                };
            }

            return {
                id: e.id,
                value: e.value ? String(e.value) : ""
            };
        });
}

function handleFillSingleCustomFields(customFields) {
    customFields?.forEach((e) => {
        if (
            e.industrialStructParameterType === "datetime" ||
            e.industrialStructParameterType === "datepicker" ||
            e.industrialStructParameterType === "input_hour"
        ) {
            e.value = e.value ? new Date(e.value) : null;
            return;
        }

        if (e.industrialStructParameterType === "range_dates") {
            const dates = e.value && typeof e.value == "string" ? JSON.parse(e.value) : null;
            e.value = dates ? [new Date(dates[0]), new Date(dates[1])] : null;
            return;
        }

        if (
            e.industrialStructParameterType === "photo" ||
            e.industrialStructParameterType === "location"
        ) {
            e.value = e.value && typeof e.value == "string" ? JSON.parse(e.value) : null;
            return;
        }

        if (e.industrialStructParameterType === "checkbox") {
            const checkedValues = e.value && typeof e.value == "string" ? JSON.parse(e.value) : [];
            e.value = checkedValues.includes(e.id) ?? false;
            return;
        }

        if (e.industrialStructParameterType === "select_multiple") {
            const selectedValues = e.value && typeof e.value == "string" ? JSON.parse(e.value) : [];
            e.value = selectedValues;
            return;
        }
    });
}

function handleFillCustomFields(customFields, industrialParameters) {
    customFields?.forEach((e) => {
        industrialParameters?.forEach((f) => {
            if (
                (e.industrialStructParameterType === "datetime" ||
                    e.industrialStructParameterType === "datepicker" ||
                    e.industrialStructParameterType === "input_hour") &&
                f &&
                e.id === f.id
            ) {
                e.value = f.value ? new Date(f.value) : null;
                return;
            }

            if (e.industrialStructParameterType === "range_dates" && f && e.id === f.id) {
                const dates = f.value && typeof f.value == "string" ? JSON.parse(f.value) : null;
                e.value = dates ? [new Date(dates[0]), new Date(dates[1])] : null;
                return;
            }

            if (
                (e.industrialStructParameterType === "photo" ||
                    e.industrialStructParameterType === "location") &&
                f &&
                e.id === f.id
            ) {
                e.value = f.value && typeof f.value == "string" ? JSON.parse(f.value) : null;

                return;
            }

            if (
                e.industrialStructParameterType === "checkbox" &&
                f &&
                e?.industrialParameterId === f.id
            ) {
                const checkedValues =
                    f.value && typeof f.value == "string" ? JSON.parse(f.value) : [];
                e.value = checkedValues.includes(e.id) ?? false;
                return;
            }

            if (e.industrialStructParameterType === "select_multiple" && f && e?.id === f.id) {
                const selectedValues =
                    f.value && typeof f.value == "string" ? JSON.parse(f.value) : [];
                e.value = selectedValues;
                return;
            }

            if (f && e.id === f.id) {
                e.value = f.value;
                return;
            }
        });
    });
}

function processIndustrialParameters(parameters) {
    return (
        parameters?.map((e) => {
            if (e.industrialStructParameterType === "checkbox") {
                return e.industrialStructParameterOption.map((option) => ({
                    ...e,
                    industrialParameterId: e.id,
                    id: option.id,
                    name: option.name,
                    label: option.name
                }));
            } else {
                return e;
            }
        }) || []
    ).flat();
}

export {
    handleCreateParametersForPayload,
    handleFillSingleCustomFields,
    handleFillCustomFields,
    processIndustrialParameters
};

<template>
    <b-modal
        id="modal-add-edit-reason"
        shadow
        bg-variant="white"
        backdrop
        hide-footer
        centered
    >
        <template #modal-header>
            <div class="d-flex align-items-center justify-content-start w-100 title-modal">
                <span class="modal-title">
                    {{
                        modalAddEditReason.reasonId != null
                            ? $t("RefuseStatus.EditReason")
                            : $t("RefuseStatus.CreateReason")
                    }}
                </span>
            </div>
            <button
                type="button"
                aria-label="Close"
                class="close"
                @click="closeModal"
            >
                <CloseModalIcon />
            </button>
        </template>

        <div class="modal-add-edit-reason__container">
            <div class="modal-add-edit-reason__container-fields-reason">
                <div class="modal-add-edit-reason__row-fields">
                    <b-form-group
                        :label="$t('RefuseStatus.Code')"
                        label-for="code"
                        :invalid-feedback="
                            isEqualCode ? $t('RefuseStatus.CodeEqual') : $t('RequiredField')
                        "
                    >
                        <b-form-input
                            type="text"
                            id="code"
                            :value="modalAddEditReason.fields.code"
                            @input="(value) => UPDATE_MODAL_ADD_EDIT_REASON_CODE_FIELD(value)"
                            :state="states.code"
                            :placeholder="states.code != false ? $t('TypeHere') : ''"
                        />
                    </b-form-group>

                    <b-form-group
                        label-for="name"
                        :label="$t('RefuseStatus.Name')"
                        :invalid-feedback="
                            isEqualName ? $t('RefuseStatus.NameAlreadyExists') : $t('RequiredField')
                        "
                    >
                        <b-form-input
                            type="text"
                            :value="modalAddEditReason.fields.name"
                            @input="(value) => UPDATE_MODAL_ADD_EDIT_REASON_NAME_FIELD(value)"
                            :state="states.name"
                            :placeholder="states.name != false ? $t('TypeHere') : ''"
                        />
                    </b-form-group>
                </div>

                <b-form-group
                    label-for="description"
                    :label="$t('RefuseStatus.Description')"
                    :invalid-feedback="$t('RequiredField')"
                    class="rejection-reasons-description"
                >
                    <b-form-textarea
                        rows="2"
                        max-rows="2"
                        no-resize
                        type="text"
                        :value="modalAddEditReason.fields.description"
                        @input="(value) => UPDATE_MODAL_ADD_EDIT_REASON_DESCRIPTION_FIELD(value)"
                        :state="states.description"
                        :placeholder="states.description != false ? $t('TypeHere') : ''"
                    />
                </b-form-group>

                <div class="modal-add-edit-reason__unit-field">
                    <label>
                        {{ $t("OperationalStatusPage.Unit") }}
                    </label>

                    <SingleSelect
                        :placeholder="$t('Select')"
                        :optionSelected="modalAddEditReason.fields.site"
                        :options="context.sites"
                        :searchable="true"
                        :state="states.unit"
                        @input="(value) => UPDATE_MODAL_ADD_EDIT_REASON_SITE_FIELD(value)"
                    >
                        {{ $t("RequiredField") }}
                    </SingleSelect>
                </div>
            </div>

            <div class="modal-add-edit-reason__footer">
                <sys-button
                    v-if="!sendingForm"
                    scheme="square"
                    variant="contained"
                    size="lg"
                    @click="
                        modalAddEditReason.reasonId != null
                            ? updateRejectionReason()
                            : createRejectionReason()
                    "
                >
                    {{ $t("OperationalStatusPage.Save") }}
                </sys-button>
            </div>
        </div>
    </b-modal>
</template>

<script>
    import {
        BModal,
        BForm,
        BButton,
        BCol,
        BRow,
        BFormInput,
        BFormGroup,
        BFormTextarea
    } from "bootstrap-vue";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import CloseModalIcon from "@/@core/assets/icons/close-modal-icon.svg";
    import SysButton from "@/@core/components/sys-button";
    import { mapMutations, mapState } from "vuex";

    export default {
        components: {
            BModal,
            BForm,
            BRow,
            BCol,
            BFormGroup,
            BFormInput,
            BButton,
            BFormTextarea,
            ToastificationContent,
            CloseModalIcon,
            SysButton,
            SingleSelect: () => import("@core/components/multiselect/SingleSelect.vue")
        },
        props: {
            showToast: {
                type: Function
            }
        },
        computed: {
            ...mapState("rejectionReasons", ["modalAddEditReason", "context", "reasonList"])
        },
        data() {
            return {
                currentSite: this.$cookies.get("userInfo").currentSite,
                isEqualCode: false,
                isEqualName: false,
                sendingForm: false,
                states: {
                    code: null,
                    name: null,
                    description: null,
                    unit: null
                }
            };
        },

        mounted() {
            this.$root.$on("bv::modal::hide", (bvEvent, modalId) => {
                if (modalId == "modal-add-edit-reason") {
                    this.RESET_MODAL_ADD_EDIT_REASON();
                    this.isEqualCode = false;
                    this.isEqualName = false;
                    this.sendingForm = false;

                    this.states = {
                        code: null,
                        name: null,
                        description: null,
                        unit: null
                    };
                }
            });
        },
        methods: {
            ...mapMutations("rejectionReasons", [
                "RESET_MODAL_ADD_EDIT_REASON",
                "UPDATE_MODAL_ADD_EDIT_REASON_NAME_FIELD",
                "UPDATE_MODAL_ADD_EDIT_REASON_CODE_FIELD",
                "UPDATE_MODAL_ADD_EDIT_REASON_DESCRIPTION_FIELD",
                "UPDATE_MODAL_ADD_EDIT_REASON_SITE_FIELD"
            ]),
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            closeModal() {
                this.$bvModal.hide("modal-add-edit-reason");
            },
            validateFields() {
                let equalCode = false;
                let equalName = false;

                for (const reasonItem of this.reasonList) {
                    if (
                        reasonItem.code === this.modalAddEditReason.fields.code &&
                        this.modalAddEditReason.fields.code !== this.modalAddEditReason.initialCode
                    ) {
                        equalCode = true;
                    }

                    if (
                        reasonItem.name === this.modalAddEditReason.fields.name &&
                        this.modalAddEditReason.fields.name !== this.modalAddEditReason.initialName
                    ) {
                        equalName = true;
                    }
                }

                this.isEqualCode = !!equalCode && !!this.modalAddEditReason.fields.code;
                this.isEqualName = !!equalName && !!this.modalAddEditReason.fields.name;

                const localStates = { ...this.states };

                localStates.code = !!this.modalAddEditReason.fields.code && !this.isEqualCode;
                localStates.name = !!this.modalAddEditReason.fields.name && !this.isEqualName;
                localStates.description = !!this.modalAddEditReason.fields.description;
                localStates.unit = !!this.modalAddEditReason.fields.site;

                this.states = { ...localStates };

                return Object.values(localStates).every((item) => !!item);
            },
            handleCreatePayload() {
                return {
                    name: this.modalAddEditReason.fields.name,
                    code: this.modalAddEditReason.fields.code,
                    description: this.modalAddEditReason.fields.description,
                    siteId: this.modalAddEditReason.fields.site?.id ?? null
                };
            },
            async createRejectionReason() {
                const validationResult = this.validateFields();

                if (!validationResult) return;

                const POST_CREATE_REJECTION_REASON_URL = `/api/myconfig/refuse-reason`;
                const payload = this.handleCreatePayload();

                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                this.sendingForm = true;

                try {
                    await this.$http2.post(POST_CREATE_REJECTION_REASON_URL, payload);

                    this.$emit("refresh-list");
                    this.showToast(
                        this.$t("RefuseStatus.Success"),
                        "SmileIcon",
                        this.$t("RefuseStatus.MessageAddSuccess"),
                        "success"
                    );
                    this.closeModal();
                } catch (error) {
                    console.error(error);
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                } finally {
                    this.sendingForm = false;
                }
            },
            async updateRejectionReason() {
                const validationResult = this.validateFields();

                if (!validationResult) return;

                const PUT_UPDATE_REJECTION_REASON_URL = `/api/myconfig/refuse-reason/${this.modalAddEditReason.reasonId ?? null}`;
                const payload = this.handleCreatePayload();

                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                this.sendingForm = true;

                try {
                    await this.$http2.put(PUT_UPDATE_REJECTION_REASON_URL, payload);

                    this.$emit("refresh-list");
                    this.closeModal();
                } catch (error) {
                    console.error(error);
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                } finally {
                    this.sendingForm = false;
                }
            },
            getRefuseStatusById(id) {
                this.$http
                    .get(`/api/${this.$router.currentRoute.params.prefix}/refuse-status/${id}`)
                    .then((response) => {
                        this.data = response.data.response[0];
                        this.code = this.data.code;
                        this.name = this.data.name;
                    });
            }
        }
    };
</script>

<style lang="scss">
    .modal-add-edit-reason__container {
        display: flex;
        flex-direction: column;
        padding: 20px 16px;
        gap: 20px;

        .modal-add-edit-reason__footer {
            display: flex;
            justify-content: flex-end;
        }

        .form-control {
            &::placeholder {
                color: #cfc4be;
            }

            &:focus {
                border-color: #974900;
            }
        }

        .modal-add-edit-reason__container-fields-reason {
            display: flex;
            flex-direction: column;
            gap: 16px;

            label {
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #4c4541;
                padding-bottom: 0px !important;
                margin-bottom: 4px;
            }

            .rejection-reasons-description {
                textarea {
                    max-height: 59px !important;
                }
            }

            .form-group {
                margin: 0;
            }

            .modal-add-edit-reason__unit-field {
                display: flex;
                flex-direction: column;
                flex: 1;
            }

            .modal-add-edit-reason__row-fields {
                display: flex;
                gap: 16px;
                flex-wrap: nowrap;

                .form-group {
                    flex: 1;
                }
            }
        }
    }

    #modal-add-edit-reason {
        .custom-select option:not(:checked) {
            color: #4c4541;
        }

        .form-control.is-invalid {
            background-image: none !important;
        }

        .invalid-feedback {
            margin-left: 5px !important;
            font-size: 10px;
        }

        .modal-dialog {
            width: 95%;
            max-width: none;
        }

        .modal-content {
            width: 100%;
            right: 0 !important;
        }

        .modal-header {
            position: relative !important;
            height: 66px;
            background-color: #eceff1;
            padding-top: 0;
            padding-bottom: 0;

            .title-modal {
                height: 100%;
            }

            .modal-title {
                color: #4c4541;
                font-size: 18px;
                font-weight: 600;
                line-height: 26px;
            }

            .close {
                display: flex;
                align-items: center;
                justify-content: center;
                min-height: 34px;
                min-width: 34px;
                max-height: 34px;
                max-width: 34px;
                transform: translate(6px, 5px);

                svg {
                    min-height: 12px;
                    min-height: 12px;
                    min-width: 12px;
                    max-width: 12px;
                    fill: #4c4541;
                }

                &:hover {
                    transform: translate(6px, 5px);
                }
            }
        }

        .modal-body {
            padding: 0 !important;
        }
    }

    @media (max-width: 570px) {
        #modal-add-edit-reason {
            padding: 0 !important;

            .modal-dialog {
                margin: 24px auto;
            }

            .modal-header {
                padding-bottom: 0;
            }

            .modal-add-edit-reason__row-fields {
                flex-direction: column !important;
            }
        }
    }

    @media (min-width: 768px) {
        #modal-add-edit-reason {
            padding: 0 !important;

            .modal-content,
            .modal-dialog {
                max-width: 746px !important;
            }
        }
    }
</style>

<template>
    <div>
        <Header
            :name="'Calendario novo'"
            :duration="6"
            :refDate="'27/12/2024 12:43'"
        />
    </div>
</template>

<script>
    import { mapMutations } from "vuex";
    import Header from "./components/header/index.vue";

    export default {
        components: { Header },
        data() {
            return {};
        },
        mounted() {
            this.UPDATE_FLAG_SHOW_OVERLAY(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"])
        }
    };
</script>

<style lang="scss"></style>

<template>
    <div>
        <b-sidebar
            id="sidebar-right"
            class="sidebar-manage-table"
            shadow
            right
            bg-variant="white"
            width="407px"
            v-model="sidebarVisible"
            backdrop
        >
            <template #header>
                <div class="header-sidebar d-flex justify-content-between">
                    <span class="sidebar-title">
                        {{ $t(modalTitle) }}
                    </span>

                    <span
                        class="close-icon"
                        v-b-toggle.sidebar-right
                    >
                        <CloseIcon />
                    </span>
                </div>

                <div class="separator"></div>
            </template>
            <template>
                <b-col md="12">
                    <b-form-group
                        :label="$t('ResourceStatus.Name')"
                        label-for="name"
                        :invalid-feedback="
                            flagNameEqual ? $t('NameAlreadyExists') : $t('RequiredField')
                        "
                    >
                        <b-form-input
                            id="name"
                            v-model="name"
                            :placeholder="$t('ResourceStatus.Placeholder')"
                            :state="states.name"
                        />
                    </b-form-group>
                </b-col>
                <b-col
                    md="12"
                    class="form-group"
                >
                    <label for="type-select">
                        {{ $t("ResourceStatus.Type") }}
                    </label>
                    <SingleSelect
                        id="type-select"
                        :placeholder="$t('Select')"
                        :optionSelected="typeList.find((item) => item.value == type)"
                        :options="typeList"
                        :searchable="false"
                        :translate="true"
                        :state="states.type"
                        @input="(value) => (type = value ? value.value : value)"
                    >
                        {{ $t("RequiredField") }}
                    </SingleSelect>
                </b-col>
                <b-col md="12">
                    <b-form-group
                        :label="$t('ResourceStatus.Code')"
                        label-for="code"
                        :invalid-feedback="
                            flagCodeEqual ? $t('CodeAlreadyExists') : $t('RequiredField')
                        "
                    >
                        <b-form-input
                            id="code"
                            v-model="code"
                            :placeholder="$t('ResourceStatus.Placeholder')"
                            :state="states.code"
                        />
                    </b-form-group>
                </b-col>
                <b-col md="12">
                    <b-form-group
                        :label="$t('ResourceStatus.ProductionRate')"
                        label-for="productionRate"
                        :invalid-feedback="$t('RequiredField')"
                    >
                        <b-form-input
                            id="productionRate"
                            v-model="productionRate"
                            type="number"
                            step="0.01"
                            :placeholder="$t('ResourceStatus.Placeholder')"
                            :state="states.productionRate"
                            @input.native="decimalMask()"
                        />
                    </b-form-group>
                </b-col>
                <div v-if="customFields.length && forceRenderIndustrialParametersList">
                    <div
                        v-for="field in displayImportantFirst"
                        :key="field.id"
                    >
                        <custom-fields
                            :field="field"
                            :hasError="states.customFieldsForceError"
                            @input="
                                (value) => {
                                    field.value = value ? value : null;
                                }
                            "
                        />
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="separator"></div>
                <b-col
                    md="12"
                    class="footer-sidebar"
                >
                    <div class="d-flex w-100 justify-content-end">
                        <b-button
                            class="btn-cancel btn-footer"
                            v-b-toggle.sidebar-right
                            @click="getResources()"
                        >
                            {{ $t("RefuseStatus.Cancel") }}
                        </b-button>
                        <b-button
                            class="btn-save btn-footer"
                            @click="addResource()"
                        >
                            {{ $t("RefuseStatus.Save") }}
                        </b-button>
                    </div>
                </b-col>
            </template>
        </b-sidebar>
        <div id="resources-page">
            <div
                v-if="list.length === 0 && !noItemsFound"
                class="h-100"
            >
                <div class="no-items h-100">
                    <EmptyBoxIcon class="empty-box" />
                    <p class="title">{{ $t("ResourcePage.NoResourcesTitle") }}</p>
                    <p class="subtitle">{{ $t("ResourcePage.NoResourcesSubtitle") }}</p>
                    <b-button
                        class="btn-new-resource"
                        v-b-toggle.sidebar-right
                        @click="changeModalName('new')"
                    >
                        <AddIcon class="add-icon" />
                        {{ $t("ResourcePage.NewFeature") }}
                    </b-button>
                </div>
            </div>
            <div v-else>
                <div class="header-block">
                    <div class="title">
                        {{ $t("PagesViews.Resources") }}
                    </div>
                    <div class="search-input col-12">
                        <b-col
                            cols="12"
                            sm="9"
                            md="8"
                            lg="10"
                            xl="10"
                            class="div-custom-input input__resources"
                        >
                            <div class="icon-block">
                                <SearchIcon class="search-icon" />
                            </div>
                            <b-form-tags
                                class="input-search-resources"
                                ref="formTagResources"
                                v-model="filter.textFilter"
                                duplicate-tag-text=""
                                :placeholder="
                                    filter.textFilter.length === 0
                                        ? $t('SearchPlaceholderDefault')
                                        : ''
                                "
                                @input="getResources()"
                                remove-on-delete
                                add-on-change
                            />
                        </b-col>
                        <b-col
                            cols="12"
                            sm="3"
                            md="4"
                            lg="2"
                            xl="2"
                            class="pr-1 pr-sm-0 add-resource"
                        >
                            <b-button
                                class="btn-new-resource"
                                v-b-toggle.sidebar-right
                                @click="changeModalName('new')"
                            >
                                <AddIcon class="add-icon" />
                                {{ $t("ResourcePage.NewFeature") }}
                            </b-button>
                        </b-col>
                    </div>
                </div>
                <div class="body-block">
                    <resources-table
                        :list="list"
                        :filter="filter"
                        :typeList="typeList"
                        :prefix="prefix"
                        @edit="(id) => changeModalName('edit', id)"
                        @deletedResource="onDeleteResource(list)"
                        @updatePageNumber="updatePageNumber"
                        @updatepageSize="updatepageSize"
                        @updateFieldAndSort="updateFieldAndSort"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapState } from "vuex";
    import {
        BFormTags,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormSelectOption,
        BButton,
        BCol,
        BSidebar,
        VBToggle
    } from "bootstrap-vue";
    import Ripple from "vue-ripple-directive";
    import ResourcesTable from "./components/resources-table.vue";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import EmptyBoxIcon from "@core/assets/icons/empty-box-icon.svg";
    import AddIcon from "@core/assets/icons/add-icon.svg";
    import CustomFields from "@core/components/customFields/index.vue";
    import {
        handleCreateParametersForPayload,
        processIndustrialParameters,
        handleFillCustomFields
    } from "@/utils/customFields";

    export default {
        components: {
            BFormTags,
            BButton,
            BCol,
            BSidebar,
            BFormGroup,
            BFormInput,
            BFormSelect,
            BFormSelectOption,
            ResourcesTable,
            ToastificationContent,
            AddIcon: () => import("@core/assets/icons/add-icon.svg"),
            CloseIcon: () => import("@core/assets/icons/close-icon.svg"),
            SingleSelect: () => import("@core/components/multiselect/SingleSelect.vue"),
            SearchIcon: () => import("@core/assets/icons/search-icon.svg"),
            AddIcon,
            VBToggle,
            CustomFields,
            EmptyBoxIcon
        },
        data() {
            return {
                filter: {
                    pageNumber: 1,
                    pageSize: 10,
                    totalItems: 0,
                    sort: "",
                    fieldSort: "",
                    textFilter: []
                },
                sidebarVisible: false,
                forceRenderIndustrialParametersList: true,
                modalTitle: "ResourcePage.NewFeature",
                prefix: localStorage.getItem("prefix"),
                noItemsFound: false,
                name: "",
                type: 0,
                code: "",
                customMultipleSelect: 0,
                customSelect: 0,
                customInput: "",
                customNumericInput: 0,
                productionRate: "0.0",
                customFields: [],
                flagNameEqual: false,
                flagCodeEqual: false,
                editById: null,
                states: {
                    name: null,
                    code: null,
                    type: null,
                    productionRate: null,
                    customFieldsForceError: null
                },
                typeList: [],
                list: []
            };
        },
        directives: {
            "b-toggle": VBToggle,
            Ripple
        },
        async mounted() {
            this.$http2
                .get(`/api/myconfig/resource/context`)
                .then((response) => {
                    this.typeList = response.data.resourceType.map((item) => ({
                        value: item.id,
                        text: item.name
                    }));

                    this.customFields = processIndustrialParameters(
                        response?.data?.industrialParameter
                    );
                })
                .catch((error) => console.error(error));

            await this.getResources();
            if (this.editId) {
                this.sidebarVisible = true;
                this.changeModalName("edit", this.editId);
                this.SET_EDIT_ID(null);
            }
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("resource", ["SET_EDIT_ID"]),
            decimalMask() {
                this.productionRate = this.productionRate.replace(/\D(?=[^\.])+/g, ""); // Remove caracteres não numéricos, exceto ponto decimal
                this.productionRate = this.productionRate.replace(/^0*(\d+)(\d{2})$/, "$1.$2"); // Insere o ponto decimal antes dos 2 últimos dígitos
            },
            activeCarousel() {
                const vm = this;
                $(function () {
                    document
                        .getElementsByClassName("input-search-resources")[0]
                        .setAttribute("tabIndex", "-1");

                    const slider = document.querySelector(".b-form-tags-list");
                    let mouseDown = false;
                    let startX, scrollLeft;

                    let startDragging = function (e) {
                        mouseDown = true;
                        startX = e.pageX - slider.offsetLeft;
                        scrollLeft = slider.scrollLeft;
                    };
                    let stopDragging = function () {
                        mouseDown = false;
                    };

                    slider.addEventListener("mousemove", (e) => {
                        e.preventDefault();
                        if (!mouseDown) {
                            return;
                        }
                        const x = e.pageX - slider.offsetLeft;
                        const scroll = x - startX;
                        slider.scrollLeft = scrollLeft - scroll;
                    });

                    slider.addEventListener("mousedown", startDragging, false);
                    slider.addEventListener("mouseup", stopDragging, false);
                    slider.addEventListener("mouseleave", stopDragging, false);

                    $(".b-form-tags-input").blur((event) => {
                        let input = vm.$refs.formTagResources;
                        input.addTag(event.target.value);
                    });
                });
            },
            async getResources() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                const textFilterQuery = this.filter.textFilter;

                await this.$http2
                    .get(
                        `/api/myconfig/resource?PageSize=${this.filter.pageSize}&PageNumber=${this.filter.pageNumber}&PageOrder=${this.filter.sort}&PageOrderField=${this.filter.fieldSort}&TextFilter=${textFilterQuery}`
                    )
                    .then((response) => {
                        this.noItemsFound = !!textFilterQuery.length;
                        this.list = response.data.data.resources;
                        this.filter.totalItems = response.data.data.totalItems;
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        this.forceRenderIndustrialParametersValue();
                        this.activeCarousel();
                    });
            },
            getEditResource(id) {
                this.editById = id;
                const resourceSelected = this.list.filter((e) => e.id === id);
                this.name = resourceSelected[0].name;
                this.code = resourceSelected[0].code;
                this.type = resourceSelected[0].resourceTypeId;
                this.productionRate = resourceSelected[0].productionRate;
                handleFillCustomFields(
                    this.customFields,
                    resourceSelected?.[0].industrialParameter
                );
            },
            onDeleteResource(list) {
                if (list.length === 1 && this.filter.pageNumber > 1) {
                    this.updatePageNumber(this.filter.pageNumber - 1);
                } else {
                    this.getResources();
                }
            },
            changeModalName(name, id) {
                if (name === "edit") {
                    this.modalTitle = "ResourcePage.EditFeature";
                    this.getEditResource(id);
                } else {
                    this.modalTitle = "ResourcePage.NewFeature";
                    this.clearFields();
                }
                this.clearValidations();
            },
            forceRenderIndustrialParametersValue() {
                this.forceRenderIndustrialParametersList = false;
                this.$nextTick().then(() => {
                    this.forceRenderIndustrialParametersList = true;
                });
            },
            updatepageSize(value) {
                this.filter.pageSize = value;
            },
            updatePageNumber(value) {
                this.filter.pageNumber = value;
            },
            updateFieldAndSort(e, text) {
                this.filter.sort = this.filter.sort = text;
                this.filter.fieldSort = e;
            },
            clearFields() {
                this.name = "";
                this.code = "";
                this.type = 0;
                this.productionRate = "0.0";
                this.customFields.map((e) => (e.value = null));
            },
            clearValidations() {
                this.states.name = null;
                this.states.type = null;
                this.states.code = null;
                this.states.productionRate = null;
                this.states.customFieldsForceError = null;
            },
            fieldsValidate() {
                this.clearValidations();

                const validName = (this.states.name =
                    this.name && this.flagNameEqual === false ? true : false);
                const validType = (this.states.type =
                    this.type != "" && this.type != 0 && this.type !== null);
                const validCode = (this.states.code =
                    this.code && this.flagCodeEqual === false ? true : false);
                const validProductionRate = (this.states.productionRate =
                    Number(this.productionRate) != 0);

                const requiredFields = this.customFields.filter((e) => e.required);
                const isCustomFieldsValid = requiredFields.map((e) => {
                    if (e.required) {
                        return e.value != null && e.value != 0;
                    }
                });
                const hasInvalidFields = isCustomFieldsValid.includes(false);
                hasInvalidFields
                    ? (this.states.customFieldsForceError = true)
                    : (this.states.customFieldsForceError = false);

                return (
                    validName && validType && validCode && validProductionRate && !hasInvalidFields
                );
            },
            verifyTypeOfResource() {
                return this.modalTitle.includes("NewFeature") ? "new" : "edit";
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            },
            async addResource() {
                this.flagCodeEqual = false;
                this.flagNameEqual = false;
                const isValid = this.fieldsValidate();
                if (!isValid) return;

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                let typeOfResource = "";

                typeOfResource = this.verifyTypeOfResource();

                const parameters = handleCreateParametersForPayload(this.customFields);

                const payload = {
                    name: this.name,
                    resourceTypeId: this.type,
                    code: this.code,
                    productionRate: this.productionRate ? Number(this.productionRate) : null,
                    industrialParameter: parameters
                };

                if (typeOfResource === "new") {
                    await this.$http2
                        .post(`/api/myconfig/resource`, payload)
                        .then((response) => {
                            this.showToast(
                                this.$t("IntegrationPage.success"),
                                "SmileIcon",
                                this.$t("ResourcePage.ResourceCreated"),
                                "success"
                            );
                            this.getResources();
                            this.$root.$emit("bv::toggle::collapse", "sidebar-right");
                            this.clearFields();
                            this.clearValidations();
                        })
                        .catch((error) => {
                            error.response.data.errors.map((e) => {
                                if (e.id === 3) {
                                    this.flagNameEqual = true;
                                } else if (e.id === 4) {
                                    this.flagCodeEqual = true;
                                } else {
                                    this.showToast(
                                        this.$t("IntegrationPage.Error"),
                                        "SmileIcon",
                                        e.message,
                                        "danger"
                                    );
                                }
                            });
                            this.fieldsValidate();
                        })
                        .finally(() => {
                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        });
                } else if (typeOfResource === "edit") {
                    await this.$http2
                        .put(`/api/myconfig/resource/${this.editById}`, payload)
                        .then((response) => {
                            this.showToast(
                                this.$t("IntegrationPage.success"),
                                "SmileIcon",
                                this.$t("ResourcePage.SavedChanges"),
                                "success"
                            );
                            this.$root.$emit("bv::toggle::collapse", "sidebar-right");
                            this.clearFields();
                            this.clearValidations();
                        })
                        .catch((error) => {
                            error.response.data.errors.map((e) => {
                                if (e.id === 3) {
                                    this.flagNameEqual = true;
                                } else if (e.id === 4) {
                                    this.flagCodeEqual = true;
                                } else {
                                    this.showToast(
                                        this.$t("IntegrationPage.Error"),
                                        "SmileIcon",
                                        e.message,
                                        "danger"
                                    );
                                }
                            });
                            this.fieldsValidate();
                        })
                        .finally(() => {
                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                            this.getResources();
                        });
                }
            }
        },
        computed: {
            ...mapState("resource", {
                editId: "editId"
            }),
            displayImportantFirst() {
                return this.customFields.sort((a, b) => {
                    if (a.important && !b.important) {
                        return -1;
                    }
                    if (!a.important && b.important) {
                        return 1;
                    }
                    return 0;
                });
            }
        },
        watch: {
            sidebarVisible() {
                this.forceRenderIndustrialParametersValue();
            },
            "filter.pageNumber"() {
                this.getResources();
            },
            "filter.pageSize"() {
                this.getResources();
            },
            "filter.sort"() {
                this.getResources();
            }
        }
    };
</script>
<style lang="scss">
    .sidebar-manage-table {
        .custom-select {
            cursor: pointer;
            padding: 4px 34px 4px 8px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;

            &[disabled] {
                border-radius: 5px;
                border: 1px solid #7e7570;
                background-color: #eee;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
                color: #7e7570 !important;
            }
        }

        .b-sidebar-header {
            display: contents;

            .header-sidebar {
                padding: 16px;

                .sidebar-title {
                    display: flex;
                    align-items: center;
                    color: #4c4541;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                }
            }

            .close-icon {
                border: 1px solid #974900;
                border-radius: 5px;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;

                svg {
                    width: 12px;
                    height: 12px;

                    path {
                        fill: #974900;
                    }
                }

                &:hover {
                    background-color: #ffede2 !important;
                }

                &:active {
                    background-color: #ffdbc4 !important;
                }
            }

            .separator {
                width: 100%;
                height: 1px;
                background: #cfc4be;
            }
        }

        .b-sidebar {
            height: 100% !important;

            .b-sidebar-body {
                padding: 16px 0;

                .form-group {
                    .form-control.is-invalid,
                    .form-control.is-valid {
                        background-image: none !important;
                    }

                    .form-control {
                        &::placeholder {
                            color: #cfc4be;
                        }

                        &:focus {
                            border-color: #974900;
                        }
                    }
                }

                &::-webkit-scrollbar {
                    display: none !important;
                }
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
        }

        .footer-sidebar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 16px;

            .btn-footer {
                box-shadow: none;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                border-radius: 5px;

                &:hover {
                    box-shadow: none;
                }

                &.btn-reset {
                    background-color: transparent !important;
                    color: #974900 !important;
                    font-size: 12px !important;
                    border: 1px solid transparent !important;
                    padding: 4px 14px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-cancel {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    margin-right: 8px;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-save {
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    color: #ffff !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #9f5714 !important;
                    }

                    &:active {
                        background-color: #a45f1f !important;
                    }
                }
            }

            @media (max-width: 480px) {
                justify-content: center;

                .btn-footer {
                    width: 100%;
                    font-size: 12px !important;
                    padding: 3px 21px !important;
                }
            }
        }
    }

    #resources-page {
        padding: 16px 0;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

        @media (max-width: 768px) {
            margin-top: 24px;
        }

        &:has(.no-items) {
            height: calc(100vh - 170px);
        }

        .no-items {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            margin: 0 16px;
            border: 1px solid #cfc4be;

            .empty-box {
                width: 80px;
                height: 80px;
            }

            .title {
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                color: #4c4541;
                margin: 0;
            }

            .subtitle {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #998f8a;
                padding: 4px 0 12px;
                margin: 0;
            }

            .btn-new-resource {
                border-color: #974900 !important;
                background-color: #974900 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                padding: 8px 21px;
                line-height: 20px;
                letter-spacing: 0.175px;

                .add-icon {
                    width: 14px;
                    height: 14px;
                    fill: #fff;
                }
            }

            @media (max-width: 480px) {
                .empty-box {
                    width: 40px;
                    height: 40px;
                }

                .title {
                    font-size: 14px;
                }

                .subtitle {
                    font-size: 12px;
                    text-align: center;
                    padding: 4px 48px 12px;
                    line-height: 16px;
                }

                .btn-new-resource {
                    padding: 4px 14px;
                }
            }
        }

        .header-block {
            padding: 0 16px;

            .title {
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
            }

            .search-input {
                display: flex;
                align-items: center;
                padding: 16px 0 8px;

                @media (max-width: 580px) {
                    flex-direction: column;
                    gap: 8px;
                }

                .div-custom-input {
                    display: flex;
                    align-items: center;
                    border-radius: 4px;
                    width: 100%;
                    overflow: hidden;
                    border: 1px solid #cfc4be;

                    .icon-block {
                        display: flex;
                        align-items: center;
                        height: 14px;

                        .search-icon {
                            width: 14px;
                            height: 14px;
                            fill: #cfc4be;
                        }
                    }

                    .input-search-resources {
                        width: 100% !important;
                        border: none;
                        overflow: hidden;

                        &.focus {
                            box-shadow: none;
                        }

                        &.b-form-tags {
                            padding: 0 !important;
                            display: flex;
                            align-items: center !important;

                            .b-form-tags-list {
                                width: 100% !important;
                                margin-left: 14px !important;
                                margin-top: 0;
                                height: 100% !important;
                                flex-wrap: nowrap !important;
                                display: flex !important;
                                align-items: center !important;
                                overflow: scroll;
                                overflow-y: hidden;
                                cursor: grabbing;
                                cursor: -webkit-grabbing;

                                &::-webkit-scrollbar {
                                    display: none !important;
                                }

                                .b-form-tags-field {
                                    margin: 9px 0;
                                }
                            }
                        }

                        .b-form-tags-button {
                            display: none;
                        }

                        .b-form-tag {
                            background-color: #974900;
                            margin-right: 0.6rem;
                            padding: 2px 8px !important;
                            font-size: 13px;
                        }
                    }
                }

                .add-resource {
                    padding-left: 8px !important;
                    padding-right: 0 !important;

                    @media (max-width: 580px) {
                        max-width: 100% !important;
                        padding-left: 0 !important;
                        flex: 0 0 100% !important;
                        width: 100% !important;
                    }

                    .btn-new-resource {
                        border-color: #974900 !important;
                        background-color: #974900 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        width: 100% !important;
                        padding: 8px 0 !important;
                        line-height: 20px;
                        letter-spacing: 0.175px;

                        @media (max-width: 580px) {
                            width: 100%;
                            display: flex;
                            justify-content: center;
                            padding: 3.5px 0 !important;
                        }

                        .add-icon {
                            width: 14px;
                            height: 14px;
                            fill: #fff;
                        }
                    }
                }
            }
        }

        .body-block {
            padding: 0 16px;
            margin: 0 14px;
            overflow-x: auto;

            &::-webkit-scrollbar {
                height: 3px;
            }

            &::-webkit-scrollbar-track {
                background: #fff;
            }

            &::-webkit-scrollbar-thumb {
                background-color: #cfc4be;
                border-radius: 9px;
            }
        }
    }

    @media (max-width: 480px) {
        .b-sidebar {
            width: 100% !important;

            .b-sidebar-body {
                overflow-x: hidden;
            }
        }

        .btn-reset-text {
            display: none;
        }
    }
</style>

const initialState = () => ({
    context: {
        statusClassificationLang: [],
        statusClassification: [],
        sites: []
    },
    modalAddEditStatus: {
        statusId: null,
        language: "pt-BR",
        allowEdit: false,
        initialName: null,
        fields: {
            name: "",
            description: "",
            statusClassification: null,
            efficiency: "0%",
            color: "",
            colorHex: "",
            site: null
        }
    },
    helpers: {
        statusNames: []
    }
});

export default {
    namespaced: true,
    state: initialState(),
    mutations: {
        UPDATE_CONTEXT(state, val) {
            state.context = val;
        },
        // general
        RESET_STATES(state) {
            Object.assign(state, initialState());
        },
        RESET_MODAL_ADD_EDIT_STATUS(state) {
            Object.assign(state.modalAddEditStatus, initialState().modalAddEditStatus);
            state.context.statusClassificationLang = [];
        },
        UPDATE_HELPERS_STATUS_NAMES(state, val) {
            state.helpers.statusNames = val;
        },
        UPDATE_MODAL_ADD_EDIT_STATUS(state, val) {
            state.modalAddEditStatus = val;
        },
        UPDATE_MODAL_ADD_EDIT_STATUS_NAME_FIELD(state, val) {
            state.modalAddEditStatus.fields.name = val;
        },
        UPDATE_MODAL_ADD_EDIT_STATUS_DESCRIPTION_FIELD(state, val) {
            state.modalAddEditStatus.fields.description = val;
        },
        UPDATE_MODAL_ADD_EDIT_STATUS_SITE_FIELD(state, val) {
            state.modalAddEditStatus.fields.site = val;
        },
        UPDATE_MODAL_ADD_EDIT_STATUS_CLASSIFICATION_FIELD(state, val) {
            state.modalAddEditStatus.fields.statusClassification = val;
        },
        UPDATE_MODAL_ADD_EDIT_STATUS_EFFICIENCY_FIELD(state, val) {
            state.modalAddEditStatus.fields.efficiency = val;
        },
        UPDATE_MODAL_ADD_EDIT_STATUS_COLOR_FIELD(state, val) {
            state.modalAddEditStatus.fields.color = val;
        },
        UPDATE_MODAL_ADD_EDIT_STATUS_COLOR_HEX_FIELD(state, val) {
            state.modalAddEditStatus.fields.colorHex = val;
        },
        UPDATE_CONTEXT_CLASSIFICATION_LANG(state, val) {
            state.context.statusClassificationLang = val;
        }
    }
};

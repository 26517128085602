<template>
    <div>
        <b-sidebar
            id="structure-resource-info-sidebar-right"
            class="structure-sidebar-resource-info-modal"
            right
            bg-variant="white"
            width="407px"
            v-model="isOpen"
            @hidden="closeModal"
        >
            <template #header>
                <div
                    :class="[
                        'header-sidebar d-flex',
                        device === 'mobile' ? 'justify-content-between' : 'justify-content-end'
                    ]"
                >
                    <span
                        v-if="device === 'mobile'"
                        class="arrow-left-icon"
                        @click="closeModal()"
                    >
                        <ArrowLeft />
                    </span>
                    <span
                        v-if="device === 'mobile'"
                        class="sidebar-title"
                    >
                        {{ $t(modalTitle) }}
                    </span>
                    <span
                        class="edit-resource-icon"
                        v-if="device !== 'mobile'"
                        @click="editResoucer(resourceInfo.id)"
                    >
                        <OpenIcon />
                    </span>
                    <div class="icons">
                        <span
                            v-if="permissionsList.find((e) => e.type === 'update')"
                            class="unlink-icon"
                            @click="$emit('onUnlink', resourceInfo)"
                        >
                            <UnLinkIcon />
                        </span>
                        <span
                            class="close-icon"
                            @click="closeModal()"
                        >
                            <CloseIcon />
                        </span>
                    </div>
                </div>

                <div class="separator"></div>
            </template>
            <template>
                <div>
                    <div class="info-block">
                        <div class="info-title">{{ $t("StructurePage.ResourceDetails") }}</div>
                        <div class="first-line">
                            <div class="code-block">
                                <div class="label">{{ $t("ResourceStatus.Code") }}</div>

                                <div
                                    class="code info"
                                    id="code-block"
                                >
                                    {{ resourceInfo.code }}
                                </div>

                                <Tooltip
                                    :target="`code-block`"
                                    :text="resourceInfo.code"
                                    placement="top"
                                />
                            </div>
                            <div class="name-block">
                                <div class="label">{{ $t("ResourceStatus.Name") }}</div>

                                <div
                                    class="name info"
                                    id="name-block"
                                >
                                    {{ resourceInfo.name }}
                                </div>

                                <Tooltip
                                    :target="`name-block`"
                                    :text="resourceInfo.name"
                                    placement="top"
                                />
                            </div>
                        </div>
                        <div class="second-line">
                            <div class="type-block">
                                <div class="label">{{ $t("StructurePage.Type") }}</div>

                                <div
                                    class="type info"
                                    id="type-block"
                                >
                                    {{
                                        resourceInfo.type
                                            ? Array.isArray(resourceInfo.type)
                                                ? resourceInfo.type[0]
                                                : resourceInfo.type
                                            : ""
                                    }}
                                </div>

                                <Tooltip
                                    :target="`type-block`"
                                    :text="
                                        resourceInfo.type
                                            ? Array.isArray(resourceInfo.type)
                                                ? resourceInfo.type[0]
                                                : resourceInfo.type
                                            : ''
                                    "
                                    placement="top"
                                />
                            </div>
                            <div class="production-block">
                                <div class="label">{{ $t("ResourceStatus.ProductionRate") }}</div>
                                <div
                                    class="production info"
                                    id="production-block"
                                >
                                    {{ productionRate }}
                                </div>

                                <Tooltip
                                    :target="`production-block`"
                                    :text="productionRate"
                                    placement="top"
                                />
                            </div>
                        </div>
                        <div class="custom-line">
                            <div
                                v-if="customFields.length && isOpen"
                                class="w-100 fields-block"
                            >
                                <div
                                    v-for="(field, fieldIndex) in customFields"
                                    :key="field.id"
                                >
                                    <div class="custom-fields-block">
                                        <div class="label">{{ field.name }}</div>
                                        <div class="custom-field-wrapper">
                                            <div
                                                :class="`production info custom-field`"
                                                :id="`custom-field-${fieldIndex}`"
                                            >
                                                {{ field.value }}
                                            </div>
                                        </div>

                                        <Tooltip
                                            :target="`custom-field-${fieldIndex}`"
                                            :text="field.value"
                                            placement="top"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="separator"></div>
                    <div class="resource-time">
                        <div class="title">{{ $t("MaterialsPage.ResourceTime") }}</div>
                        <div>
                            <b-col md="12">
                                <b-form-group
                                    :label="$t('MaterialsPage.Cycle')"
                                    label-for="cycle"
                                    :invalid-feedback="$t('RequiredField')"
                                >
                                    <b-form-input
                                        id="cycle"
                                        v-model="cycle"
                                        :placeholder="$t('MaterialsPage.TimesPlaceholder')"
                                        lazy-formatter
                                        :readonly="
                                            !permissionsList.find((e) => e.type === 'update')
                                        "
                                        type="text"
                                        :formatter="
                                            () => {
                                                states.cycle = cycle !== '';
                                                return cycle;
                                            }
                                        "
                                        @input.native="
                                            (e) => {
                                                const maskApplied = e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ''
                                                );
                                                cycle = maskApplied;
                                                e.target.value = maskApplied;
                                            }
                                        "
                                    />
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-form-group
                                    :label="$t('MaterialsPage.ValueAggregation')"
                                    label-for="aggregationValue"
                                    :invalid-feedback="$t('RequiredField')"
                                >
                                    <b-form-input
                                        id="aggregationValue"
                                        v-model="aggregationValue"
                                        :placeholder="$t('MaterialsPage.TimesPlaceholder')"
                                        lazy-formatter
                                        type="text"
                                        :formatter="
                                            () => {
                                                states.aggregationValue = aggregationValue !== '';
                                                return aggregationValue;
                                            }
                                        "
                                        @input.native="
                                            (e) => {
                                                const maskApplied = e.target.value.replace(
                                                    /[^0-9]/g,
                                                    ''
                                                );
                                                aggregationValue = maskApplied;
                                                e.target.value = maskApplied;
                                            }
                                        "
                                    />
                                </b-form-group>
                            </b-col>
                        </div>
                    </div>
                </div>
            </template>
            <template
                v-if="permissionsList.find((e) => e.type === 'update')"
                #footer
            >
                <div class="separator"></div>
                <b-col
                    md="12"
                    class="footer-sidebar"
                >
                    <div class="d-flex buttons">
                        <b-button
                            class="btn-cancel btn-footer btn"
                            @click="closeModal()"
                        >
                            {{ $t("RefuseStatus.Cancel") }}
                        </b-button>
                        <b-button
                            class="btn-save btn-footer btn"
                            @click="onSave()"
                        >
                            {{ $t("RefuseStatus.Save") }}
                        </b-button>
                    </div>
                </b-col>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import { BSidebar, BCol, BButton, BFormInput, BFormGroup } from "bootstrap-vue";
    import { mapState, mapMutations } from "vuex";
    import CustomFields from "@core/components/customFields/index.vue";
    import moment from "moment";
    import Tooltip from "@/@core/components/sys-tooltip";

    export default {
        components: {
            BSidebar,
            BCol,
            BButton,
            BFormGroup,
            BFormInput,
            CustomFields,
            Tooltip,
            CloseIcon: () => import("@core/assets/icons/close-icon.svg"),
            OpenIcon: () => import("@core/assets/icons/open-icon.svg"),
            UnLinkIcon: () => import("@core/assets/icons/unlink-icon.svg"),
            ArrowLeft: () => import("@core/assets/icons/arrow-left.svg")
        },
        props: {
            resourceInfo: {
                type: Object,
                default: {}
            },
            device: {
                type: String,
                default: "desktop"
            },
            modalTitle: {
                type: String,
                default: ""
            },
            currentId: {
                type: Number,
                default: null
            },
            permissionsList: {
                type: Array,
                default: () => []
            }
        },
        data() {
            return {
                isOpen: false,
                customFields: [],
                productionRate: null,
                cycle: "",
                aggregationValue: "",
                states: {
                    cycle: null,
                    aggregationValue: null
                }
            };
        },
        mounted() {
            this.UPDATE_MODAL_RESOURCE_INFO_TOGGLE(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("productionOrders", ["UPDATE_MODAL_RESOURCE_INFO_TOGGLE"]),
            ...mapMutations("resource", ["SET_EDIT_ID"]),
            closeModal() {
                this.UPDATE_MODAL_RESOURCE_INFO_TOGGLE(false);
            },
            async getResourceInfo() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                await this.$http2
                    .get(
                        `api/myconfig/material/operation/${this.currentId}/resource/${this.resourceInfo.id}/info`
                    )
                    .then((response) => {
                        this.customFields = response.data.data.industrialParameters
                            ? this.formatCustomFields(response.data.data.industrialParameters)
                            : [];
                        this.productionRate = response.data.data.productionRate;
                        this.cycle = response.data.data.cycle === 0 ? "" : response.data.data.cycle;
                        this.aggregationValue =
                            response.data.data.aggregationValue === 0
                                ? ""
                                : response.data.data.aggregationValue;
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            },
            formatCustomFields(customFields) {
                if (!customFields?.length) return [];

                return customFields
                    .map((field) => {
                        if (field.industrialStructParameterType === "range_dates") {
                            const dates =
                                field.value && typeof field.value === "string"
                                    ? JSON.parse(field.value)
                                    : [];

                            return {
                                ...field,
                                value: `${dates?.[0] ? moment(dates[0]).format("DD/MM/YYYY") : ""} ${this.$t("Operator.Until")} ${dates?.[1] ? moment(dates[1]).format("DD/MM/YYYY") : ""} `
                            };
                        }

                        if (field.industrialStructParameterType === "input_hour") {
                            return {
                                ...field,
                                value: field.value ? moment(field.value).format("HH:mm") : ""
                            };
                        }

                        if (field.industrialStructParameterType === "datepicker") {
                            return {
                                ...field,
                                value: field.value ? moment(field.value).format("DD/MM/YYYY") : ""
                            };
                        }

                        return field;
                    })
                    .sort((a, b) => {
                        if (a.important && !b.important) {
                            return -1;
                        }
                        if (!a.important && b.important) {
                            return 1;
                        }
                        return 0;
                    });
            },
            editResoucer(id) {
                this.SET_EDIT_ID(id);
                this.$router.push({ name: "resources" });
            },
            onSave() {
                const payload = {
                    cycle: Number(this.cycle),
                    valueAggregation: Number(this.aggregationValue)
                };

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                this.$http2
                    .put(
                        `api/myconfig/material/operation/${this.currentId}/${this.resourceInfo.id}/update-details`,
                        payload
                    )
                    .then((response) => {
                        this.closeModal();
                    })
                    .catch((error) => {
                        error.response.data.errors.map((e) => {
                            this.showToast(
                                this.$t("IntegrationPage.Error"),
                                "SmileIcon",
                                e.message,
                                "danger"
                            );
                        });
                    })
                    .finally(() => {
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            }
        },
        computed: {
            ...mapState("productionOrders", {
                isModalResourceInfoOpen: "isModalResourceInfoOpen"
            })
        },
        watch: {
            isModalResourceInfoOpen(v) {
                this.isOpen = v;
            },
            resourceInfo() {
                this.isOpen && this.getResourceInfo();
            }
        }
    };
</script>
<style lang="scss">
    .structure-sidebar-resource-info-modal {
        .b-sidebar.b-sidebar-right {
            transition: all 0.5s ease-out;
            border-left: 1px solid #cfc4be;
            @media (max-width: 814px) {
                width: 50vw !important;
            }
            @media (max-width: 480px) {
                width: 100vw !important;
            }
        }

        .b-sidebar-header {
            display: contents;

            .header-sidebar {
                gap: 8px;
                padding: 16px;

                .icons {
                    display: flex;
                    gap: 8px;
                }

                .sidebar-title {
                    display: flex;
                    align-items: center;
                    color: #4c4541;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;

                    @media (max-width: 480px) {
                        font-size: 16px;
                    }
                }
            }

            .arrow-left-icon {
                border-radius: 5px;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                svg {
                    width: 12px;
                    height: 12px;
                    path {
                        fill: #974900;
                    }
                }

                &:hover {
                    background-color: #ffede2 !important;
                }

                &:active {
                    background-color: #ffdbc4 !important;
                }
            }

            .close-icon,
            .edit-resource-icon,
            .unlink-icon {
                border: 1px solid #974900;
                border-radius: 5px;
                width: 28px;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg {
                    width: 12px;
                    height: 12px;
                    path {
                        fill: #974900;
                    }
                }

                &:hover {
                    background-color: #ffede2 !important;
                }

                &:active {
                    background-color: #ffdbc4 !important;
                }
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
        }

        .b-sidebar {
            height: 100% !important;
            .b-sidebar-body {
                padding-bottom: 16px;
                overflow-x: hidden;

                .form-control {
                    &:read-only {
                        background: transparent !important;
                    }
                }

                .info-block {
                    padding: 16px;
                    .info-title {
                        color: #4c4541;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;

                        @media (max-width: 480px) {
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }

                    .info-block-wrapper {
                        display: flex;
                        width: 100%;
                    }

                    .first-line {
                        display: flex;
                        gap: 16px;
                        padding-block: 16px;
                        .code-block,
                        .name-block {
                            overflow: hidden;
                            width: 100%;

                            .info {
                                white-space: nowrap;
                                word-break: break-all;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                    .second-line {
                        display: flex;
                        gap: 16px;
                        padding-bottom: 16px;
                        .type-block {
                            width: 100%;
                            overflow: hidden;

                            .type {
                                white-space: nowrap;
                                word-break: break-all;
                                overflow: hidden;
                                text-overflow: ellipsis;
                            }
                        }
                        .production-block {
                            width: 100%;
                        }
                    }

                    .custom-line {
                        .fields-block {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            gap: 16px;
                            .custom-fields-block {
                                width: 100%;
                                .custom-field-wrapper {
                                    display: flex;
                                    width: 179px;

                                    .custom-field {
                                        white-space: nowrap;
                                        word-break: break-all;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                    }
                                }
                            }
                        }
                    }
                }

                .resource-time {
                    .title {
                        padding: 16px 16px 8px;
                        color: #4c4541;
                        font-size: 16px;
                        font-weight: 600;
                        line-height: 26px;
                    }
                }

                .label {
                    color: #998f8a;
                    font-size: 12px;
                    font-weight: 400;
                    line-height: 16px;
                    padding-bottom: 4px;

                    @media (max-width: 480px) {
                        font-size: 10px;
                        line-height: 14px;
                    }
                }

                .info {
                    color: #4c4541;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;

                    @media (max-width: 480px) {
                        font-size: 12px;
                        line-height: 16px;
                    }
                }

                &::-webkit-scrollbar {
                    width: 7px;
                }

                &::-webkit-scrollbar-track {
                    background: #fff;
                }

                &::-webkit-scrollbar-thumb {
                    background-color: #cfc4be;
                    border-radius: 9px;
                }
            }
        }
        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
        }

        .footer-sidebar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 16px;

            .buttons,
            .edit-buttons {
                gap: 8px;
            }

            @media (max-width: 480px) {
                justify-content: center;
                .buttons {
                    width: 100%;

                    .btn {
                        width: 50%;
                    }
                }

                .btn-footer {
                    font-size: 12px !important;
                    padding: 3px 21px !important;
                }
            }

            .btn-footer {
                box-shadow: none;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                border-radius: 5px;

                &:hover {
                    box-shadow: none;
                }

                &.btn-cancel {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-previous,
                &.btn-cancel.btn-cancel-outline {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-save {
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    color: #ffff !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #9f5714 !important;
                    }

                    &:active {
                        background-color: #a45f1f !important;
                    }
                }
            }
        }
    }
</style>

<template>
    <div>
        <AddEditStatusModal
            :showToast="showToast"
            @refresh-list="() => handleGetStatusList()"
        />

        <div id="card-resource-status">
            <div>
                <div class="d-flex align-items-center justify-content-start c-header">
                    <b-form-group class="w-100">
                        <div
                            class="d-flex flex-column flex-sm-row align-items-start align-items-sm-center justify-content-sm-between"
                        >
                            <span class="c-title">{{ $t("OperationalStatusPage.Title") }}</span>
                            <b-button
                                class="btn-new-reason"
                                @click="handleOpenAddReasonModal"
                            >
                                <span class="text-nowrap">{{
                                    $t("OperationalStatusPage.Add")
                                }}</span>
                            </b-button>
                        </div>
                    </b-form-group>
                </div>
                <div class="mt-1 table-operational-status">
                    <table-default
                        :rows="rows"
                        :fields="fields"
                        :stickyHeader="false"
                        :striped="true"
                        :filter="filter"
                        id="table-resource-status"
                        @updatePageNumber="updatePageNumber"
                        @updatepageSize="updatepageSize"
                        currentPageOff
                    >
                        <template v-slot:cell(action)="data">
                            <span
                                v-if="!data.item.allowEdit"
                                class="d-flex justify-content-center"
                                >-</span
                            >
                            <span
                                v-if="data.item.allowEdit"
                                class="d-flex justify-content-center"
                            >
                                <b-dropdown
                                    variant="link"
                                    toggle-class="text-decoration-none"
                                    no-caret
                                    class="d-action-reasons"
                                >
                                    <template v-slot:button-content>
                                        <feather-icon
                                            icon="MoreVerticalIcon"
                                            size="16"
                                            class="d-color align-middle mr-25"
                                        />
                                    </template>
                                    <b-dropdown-item
                                        @click="
                                            handleOpenEditReasonModal(
                                                data.item.id,
                                                data.item.allowEdit
                                            )
                                        "
                                    >
                                        <EditIcon />
                                        <span class="d-itens m-1">
                                            {{ $t("OperationalStatusPage.Edit") }}
                                        </span>
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        @click="
                                            handleOpenRemoveModal(data.item.id, data.item.allowEdit)
                                        "
                                    >
                                        <DeleteIcon />
                                        <span class="d-itens ml-1">
                                            {{ $t("OperationalStatusPage.Delete") }}
                                        </span>
                                    </b-dropdown-item>
                                </b-dropdown>
                            </span>
                        </template>
                        <template v-slot:cell(name)="data">
                            <span :id="`tooltip-resource-name-${data.index}`">
                                {{ data.item.name || "-" }}
                            </span>
                            <b-tooltip
                                custom-class="tooltip-resource-status"
                                triggers="hover blur"
                                :target="`tooltip-resource-name-${data.index}`"
                                positioning="top"
                                placement="top"
                            >
                                <div class="info-tooltip-resource-status">
                                    {{ data.item.name || "-" }}
                                </div>
                            </b-tooltip>
                        </template>
                        <template v-slot:cell(description)="data">
                            <span :id="`tooltip-resource-description-${data.index}`">
                                {{ data.item.description || "-" }}
                            </span>
                            <b-tooltip
                                custom-class="tooltip-resource-status"
                                triggers="hover blur"
                                :target="`tooltip-resource-description-${data.index}`"
                                positioning="top"
                                placement="top"
                            >
                                <div class="info-tooltip-resource-status">
                                    {{ data.item.description || "-" }}
                                </div>
                            </b-tooltip>
                        </template>
                        <template v-slot:cell(statusClassification)="data">
                            <span :id="`tooltip-resource-status-classification-${data.index}`">
                                {{
                                    data.item.statusClassification
                                        ? data.item.statusClassification
                                        : "-"
                                }}
                            </span>
                            <b-tooltip
                                custom-class="tooltip-resource-status"
                                triggers="hover blur"
                                :target="`tooltip-resource-status-classification-${data.index}`"
                                positioning="top"
                                placement="top"
                            >
                                <div class="info-tooltip-resource-status">
                                    {{
                                        data.item.statusClassification
                                            ? data.item.statusClassification
                                            : "-"
                                    }}
                                </div>
                            </b-tooltip>

                            <span> </span>
                        </template>
                        <template v-slot:cell(color)="data">
                            <div class="color-container">
                                <div
                                    :style="getColor(data.item.color)"
                                    class="text-center colors-container rounded d-flex align-items-center justify-content-center shadow"
                                />
                                <div class="d-flex align-items-center">
                                    <span>
                                        {{ data.item.color }}
                                    </span>
                                </div>
                            </div>
                        </template>
                        <template v-slot:cell(efficiency)="data">
                            <span :id="`tooltip-resource-description-${data.index}`">
                                {{ data.item.efficiency || 0 }}%
                            </span>
                        </template>
                        <template v-slot:cell(site)="data">
                            <span :id="`tooltip-resource-site-${data.index}`">
                                {{ data.item.site ? data.item.site : "-" }}
                            </span>
                            <b-tooltip
                                custom-class="tooltip-resource-status"
                                triggers="hover blur"
                                :target="`tooltip-resource-site-${data.index}`"
                                positioning="top"
                                placement="top"
                            >
                                <div class="info-tooltip-resource-status">
                                    {{ data.item.site ? data.item.site : "-" }}
                                </div>
                            </b-tooltip>
                        </template>
                    </table-default>
                </div>
                <div class="demo-spacing-0">
                    <b-alert
                        variant="primary"
                        :show="flagShowOverlay == false && rows.length == 0 ? true : false"
                    >
                        <div class="alert-body">
                            <span>Nenhum resultado encontrado.</span>
                        </div>
                    </b-alert>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {
        BRow,
        BAvatar,
        BBadge,
        BPagination,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BAlert,
        BDropdown,
        BDropdownItem,
        BCard,
        BTooltip,
        VBToggle,
        BButton
    } from "bootstrap-vue";
    import { VueGoodTable } from "vue-good-table";
    import Ripple from "vue-ripple-directive";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import TableDefault from "@/@core/components/table-default/TableDefault.vue";
    import DeleteIcon from "@/assets/images/pages/trash-icon-grey.svg";
    import EditIcon from "@/assets/images/pages/edit-icon-grey.svg";
    import AddEditStatusModal from "./components/ModalAddEditStatus.vue";
    import { mapMutations, mapGetters, mapState } from "vuex";
    import { formatSelectOption } from "@/utils/format";

    export default {
        components: {
            BRow,
            VueGoodTable,
            BAvatar,
            BBadge,
            BPagination,
            BFormGroup,
            BFormInput,
            BFormSelect,
            BAlert,
            BDropdown,
            BDropdownItem,
            BCard,
            ToastificationContent,
            BButton,
            BTooltip,
            TableDefault,
            EditIcon,
            DeleteIcon,
            AddEditStatusModal
        },
        directives: {
            "b-toggle": VBToggle,
            Ripple
        },
        data() {
            return {
                filter: {
                    pageNumber: 1,
                    pageSize: 10,
                    totalItems: 0
                },
                currentSite: this.$cookies.get("userInfo").currentSite,
                classificationList: [
                    {
                        text: "Selecione",
                        value: 0,
                        language: "pt-BR"
                    },

                    {
                        text: "Select",
                        value: 0,
                        language: "en-US"
                    }
                ],
                classificationListLanguage: [],
                fields: [
                    {
                        label: "OperationalStatusPage.Action",
                        key: "action",
                        thClass: "th col-action",
                        tdClass: "td"
                    },
                    {
                        label: "OperationalStatusPage.Color",
                        key: "color",
                        thClass: "th col-color",
                        tdClass: "td"
                    },
                    {
                        label: "OperationalStatusPage.Name",
                        key: "name",
                        sortable: true,
                        thClass: "th col-name",
                        tdClass: "td"
                    },
                    {
                        label: "OperationalStatusPage.Description",
                        key: "description",
                        sortable: true,
                        thClass: "th col-description",
                        tdClass: "td"
                    },
                    {
                        label: "OperationalStatusPage.Stop",
                        key: "statusClassification",
                        sortable: true,
                        thClass: "th col-stop",
                        tdClass: "td"
                    },
                    {
                        label: "OperationalStatusPage.Efficiency",
                        key: "efficiency",
                        sortable: true,
                        thClass: "th col-efficiency",
                        tdClass: "td"
                    },
                    {
                        label: "OperationalStatusPage.Unit",
                        key: "site",
                        sortable: true,
                        thClass: "th col-unit",
                        tdClass: "td"
                    }
                ],
                rows: []
            };
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("operationalStatus", [
                "UPDATE_CONTEXT",
                "RESET_STATES",
                "UPDATE_MODAL_ADD_EDIT_STATUS",
                "UPDATE_MODAL_ADD_EDIT_STATUS_CLASSIFICATION_FIELD",
                "UPDATE_HELPERS_STATUS_NAMES",
                "UPDATE_CONTEXT_CLASSIFICATION_LANG"
            ]),
            updatepageSize(value) {
                this.filter.pageSize = value;
            },
            updatePageNumber(value) {
                this.filter.pageNumber = value;
            },
            handleOpenRemoveModal(statusId, allowEdit) {
                if (!statusId?.toString()) return;

                if (!allowEdit) {
                    this.showToast(
                        this.$t("OperationalStatusPage.Error"),
                        "FrownIcon",
                        this.$t("OperationalStatusPage.MessageDeleteError"),
                        "danger"
                    );

                    return;
                }

                this.$swal({
                    title: this.$t("OperationalStatusPage.Attention"),
                    showCloseButton: true,
                    showCancelButton: true,
                    cancelButtonText: this.$t("OperationalStatusPage.Cancel"),
                    confirmButtonText: this.$t("OperationalStatusPage.Delete"),
                    text: this.$t("OperationalStatusPage.TextAttention"),
                    customClass: {
                        container: "swal-default",
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1"
                    },
                    buttonsStyling: false
                }).then(async (result) => {
                    if (!result.isConfirmed) return;

                    await this.handleDeleteStatus(statusId);
                });
            },
            async deleteStatus(statusId, useLoading = true) {
                const DELETE_STATUS_URL = `/api/myconfig/currently-status/${statusId}`;
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    await this.$http2.delete(DELETE_STATUS_URL);

                    this.showToast(
                        this.$t("OperationalStatusPage.Success"),
                        "SmileIcon",
                        this.$t("OperationalStatusPage.MessageDeleteSuccess"),
                        "success"
                    );

                    return Promise.resolve(null);
                } catch (error) {
                    console.error(error);

                    return Promise.reject(null);
                } finally {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async handleDeleteStatus(statusId) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    await this.deleteStatus(statusId, false);
                    await this.getStatusContext(false);
                    await this.getStatusList(false);
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async handleOpenAddReasonModal() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    await this.getStatusContext(false);
                    this.handleModalClassificationContext();

                    const outOfTurnValue = this.context.statusClassificationLang?.find(
                        (i) => i.id === 2
                    );

                    outOfTurnValue &&
                        this.UPDATE_MODAL_ADD_EDIT_STATUS_CLASSIFICATION_FIELD({
                            ...outOfTurnValue
                        });
                    this.$root.$emit("bv::show::modal", "modal-add-edit-resource");
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            handleModalClassificationContext() {
                const lang = this.$cookies.get("userInfo").language;

                this.UPDATE_CONTEXT_CLASSIFICATION_LANG(
                    this.context.statusClassification?.map((i) => {
                        return {
                            id: i.id,
                            text: lang == "en-US" ? i.language : i.name
                        };
                    }) || []
                );
            },
            async handleOpenEditReasonModal(statusId, allowEdit) {
                if (!statusId?.toString() || !allowEdit) return;

                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                try {
                    await this.getStatusContext(false);
                    this.handleModalClassificationContext();
                    await this.getStatusById(statusId, false);
                    this.$root.$emit("bv::show::modal", "modal-add-edit-resource");
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async getStatusById(statusId, useLoading = true) {
                if (!statusId?.toString()) return;

                const GET_STATUS_BY_ID_URL = `/api/myconfig/currently-status/${statusId}`;
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_STATUS_BY_ID_URL);
                    const { data } = response.data;

                    if (!data) return;

                    const statusClassification =
                        this.context.statusClassificationLang.find(
                            (i) => i.id == data?.statusClassificationId
                        ) ?? null;

                    const site = this.context.sites.find((i) => i.id == data?.siteId) ?? null;

                    this.UPDATE_MODAL_ADD_EDIT_STATUS({
                        ...this.modalAddEditStatus,
                        statusId,
                        language: data?.language ?? "",
                        allowEdit: !!data?.allowEdit,
                        initialName: data?.name ?? null,
                        fields: {
                            name: data?.name ?? "",
                            description: data?.description ?? "",
                            efficiency: data?.efficiency?.toString()
                                ? String(data?.efficiency) + "%"
                                : "",
                            color: data?.color?.replace("#", "") ?? "",
                            colorHex: data?.color ?? "",
                            statusClassification,
                            site
                        }
                    });

                    return Promise.resolve(response);
                } catch (error) {
                    return Promise.reject(error);
                } finally {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async getStatusContext(useLoading = true) {
                const GET_STATUS_CONTEXT_URL = `/api/myconfig/currently-status/context`;

                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_STATUS_CONTEXT_URL);

                    if (!response.data.data) return;

                    const { statusClassification, sites } = response.data.data;

                    const formattedContexts = {
                        statusClassificationLang: [],
                        statusClassification: statusClassification,
                        sites: sites ? [...sites?.map(formatSelectOption)] : []
                    };

                    this.UPDATE_CONTEXT({ ...formattedContexts });

                    return Promise.resolve(response.data.data);
                } catch (error) {
                    return Promise.reject(error);
                } finally {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async getStatusList(useLoading = true) {
                const GET_STATUS_LIST_URL = `/api/myconfig/currently-status`;
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);

                try {
                    const response = await this.$http2.get(GET_STATUS_LIST_URL);

                    if (!response?.data?.data) return;

                    const statusNames = [];

                    const formattedRows = response.data.data.map((i) => {
                        statusNames.push(i.name);

                        const site =
                            this.context.sites?.find((j) => j.id == i.siteId)?.text ?? null;

                        const statusClassification =
                            this.context.statusClassification?.find(
                                (j) => j.id == i.statusClassificationId
                            )?.name ?? null;

                        const { siteId, statusClassificationId, ...restOfStatus } = i;

                        return {
                            ...restOfStatus,
                            site,
                            statusClassification
                        };
                    });

                    this.filter = {
                        pageNumber: 1,
                        pageSize: 10,
                        totalItems: formattedRows.length
                    };
                    this.rows = [...formattedRows];
                    this.UPDATE_HELPERS_STATUS_NAMES([...statusNames]);
                } catch (error) {
                    return Promise.reject(error);
                } finally {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async handleGetStatusList(useLoading = true) {
                useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(true);
                try {
                    await this.getStatusContext(false);
                    await this.getStatusList(false);
                } finally {
                    useLoading && this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            getColor(color) {
                return `background-color:${color}; height: 30px; width: 30px`;
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            },
            convertToPercentage(value) {
                // Multiply the value by 100 and convert it to a string with 2 decimal places
                let percentage = value.toFixed(2);

                // Remove trailing zeros if the number is an integer
                if (percentage.indexOf(".") !== -1) {
                    percentage = parseFloat(percentage).toString();
                }

                // Add the percentage symbol
                return percentage + "%";
            },
            async handleInitialRouteCalls() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);
                try {
                    await this.handleGetStatusList(false);
                } finally {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            }
        },
        computed: {
            ...mapGetters("app", ["flagShowOverlay"]),
            ...mapState("operationalStatus", ["context", "modalAddEditStatus"])
        },
        mounted() {
            this.handleInitialRouteCalls();
        },
        beforeDestroy() {
            this.RESET_STATES();
        }
    };
</script>

<style lang="scss">
    @import "~@core/scss/vue/libs/vue-good-table.scss";
    @import "~@core/scss/vue/libs/vue-sweetalert.scss";

    .card-body {
        padding: 16px;
    }

    .tooltip-resource-status {
        //não adicione padding aqui e só estilize as classes especificas se possível
        margin: 0;

        .tooltip-inner {
            padding: 0;
            max-width: 100%;
            min-width: 1%;
            border-radius: 6px;
            overflow: hidden;

            .info-tooltip-resource-status {
                max-width: 339px;
                padding: 7px 8px;
                text-align: left;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                color: #fff;
                letter-spacing: 0.16px;
                border: 1px solid transparent;
            }
        }

        .arrow {
            &::before {
                border-top-color: #323232;
                border-bottom-color: #323232;
            }
        }
    }

    .overlay-mensage {
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        flex-direction: column;
    }

    @media (max-width: 480px) {
        .tooltip-resource-status {
            .tooltip-inner {
                .info-tooltip-resource-status {
                    max-width: 288px !important;
                }
            }
        }

        #card-resource-status {
            .c-header {
                .btn-new-reason {
                    width: 100%;
                    margin-top: 16px;
                }
            }
        }
    }

    #card-resource-status {
        padding: 16px;
        width: 100%;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

        @media (max-width: 768px) {
            margin-top: 24px;
        }

        .form-control {
            &::placeholder {
                color: #cfc4be;
            }

            &:focus {
                border-color: #974900;
            }
        }

        .c-header {
            .c-title {
                font-weight: 600;
                font-size: 18px;
                line-height: 26px;
                color: #4c4541 !important;
            }

            .btn-new-reason {
                background: #974900 !important;
                border: none;
                padding: 4px 14px;

                span {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 20px;
                    letter-spacing: 0.0125em;
                }
            }
        }

        .table-operational-status {
            overflow-x: hidden;
            margin-top: 0px !important;
            padding-top: 8px !important;
            .custom-search {
                padding: 0;
                margin-bottom: 8px;
            }

            .row {
                margin: 0;
            }

            table {
                thead th {
                    padding: 10.5px 9px !important;
                }

                th:nth-child(1) {
                    padding: 10.5px 7.65px !important;
                }

                th {
                    padding: 11px 9px !important;
                }

                td {
                    padding: 13.5px 10px !important;
                    height: 38px !important;
                    letter-spacing: 0;
                    border: 1px solid #e0e0e0 !important;
                    border-bottom: none !important;
                    border-left: none !important;
                    border-right: none !important;
                }

                tbody tr td,
                thead tr th {
                    outline: none !important;
                    border-left: none !important;
                    border-right: none !important;
                }

                .col-action {
                    padding-left: 0;
                    min-width: 57px !important;
                    max-width: 57px !important;
                    width: 1px !important;
                }

                .col-color {
                    min-width: 150px !important;
                    max-width: 150px !important;
                    width: 150px !important;
                }

                .col-name {
                    min-width: 320px !important;
                    max-width: 320px !important;
                    width: 320px !important;
                }

                .col-description {
                    min-width: 360px !important;
                    width: 100%;
                    max-width: 100%;
                }

                .col-stop {
                    min-width: 201px !important;
                    max-width: 201px !important;
                    width: 201px !important;
                }

                .col-unit {
                    min-width: 201px !important;
                    max-width: 201px !important;
                    width: 201px !important;
                }

                .col-efficiency {
                    min-width: 105px !important;
                    max-width: 105px !important;
                    width: 105px !important;
                }

                thead tr th {
                    background-color: rgba(151, 73, 0, 0.05) !important;
                }

                thead tr th div {
                    text-transform: uppercase;
                    font-size: 12px;
                }

                .d-action-reasons {
                    button {
                        max-width: 28px;
                        max-height: 28px;
                        min-width: 28px;
                        min-height: 28px;
                        padding: 0;

                        svg circle {
                            color: #974900 !important;
                        }
                    }

                    .dropdown-item {
                        &:hover {
                            background-color: #fbeee8;

                            svg path {
                                fill: #974900 !important;
                            }

                            span {
                                color: #974900 !important;
                            }
                        }
                    }

                    button:hover {
                        background-color: #ffdbc4 !important;
                    }

                    svg {
                        margin: 0 !important;
                    }
                }

                .container-block-color {
                    .block-color {
                        width: 25px;
                        height: 25px;
                        background-color: #000;
                        border-radius: 6px;
                        margin-right: 8px;
                    }
                }
            }

            .color-container {
                display: flex;
                gap: 8px;
                flex-wrap: nowrap;
            }
        }
    }
</style>

<style lang="scss" scoped>
    @import "~@core/scss/base/bootstrap-extended/include";
    @import "~@core/scss/base/components/variables-dark";

    .dark-layout {
        div ::v-deep {
            .b-overlay {
                .bg-light {
                    background-color: $theme-dark-body-bg !important;
                }
            }
        }
    }
</style>

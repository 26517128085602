var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-sidebar',{class:['sidebar-add-workcenter-unit'],attrs:{"id":"structure-sidebar-right","right":"","bg-variant":"white","width":"407px"},on:{"hidden":_vm.closeModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"header-sidebar d-flex justify-content-between"},[_c('span',{staticClass:"sidebar-title"},[_vm._v(" "+_vm._s(_vm.$t(_vm.modalTitle))+" ")]),_c('span',{staticClass:"close-icon",on:{"click":function($event){return _vm.closeModal()}}},[_c('CloseIcon')],1)]),_c('div',{staticClass:"separator"})]},proxy:true},{key:"footer",fn:function(){return [_c('b-col',{staticClass:"footer-sidebar",attrs:{"md":"12"}},[_c('div',{staticClass:"d-flex"},[_c('b-button',{staticClass:"btn-cancel btn-footer",on:{"click":function($event){return _vm.closeModal()}}},[_vm._v(" "+_vm._s(_vm.$t("RefuseStatus.Cancel"))+" ")]),_c('b-button',{staticClass:"btn-save btn-footer",on:{"click":function($event){return _vm.addWorkCenter()}}},[_vm._v(" "+_vm._s(_vm.$t("RefuseStatus.Save"))+" ")])],1)])]},proxy:true}]),model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('DepartmentPage.Name'),"label-for":"name","invalid-feedback":_vm.flagNameEqual
                            ? _vm.$t('StructurePage.EqualWorkcenterName')
                            : _vm.$t('RequiredField')}},[_c('b-form-input',{attrs:{"id":"name","placeholder":_vm.$t('ResourceStatus.Placeholder'),"state":_vm.states.name},model:{value:(_vm.nameInput),callback:function ($$v) {_vm.nameInput=$$v},expression:"nameInput"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('ResourceStatus.Code'),"label-for":"code","invalid-feedback":_vm.flagCodeEqual ? _vm.$t('CodeAlreadyExists') : _vm.$t('RequiredField')}},[_c('b-form-textarea',{attrs:{"id":"code","placeholder":_vm.$t('ResourceStatus.Placeholder'),"state":_vm.states.code,"no-resize":""},model:{value:(_vm.code),callback:function ($$v) {_vm.code=$$v},expression:"code"}})],1)],1),_c('b-col',{staticClass:"form-group",attrs:{"md":"12"}},[_c('label',{attrs:{"for":"functions-select"}},[_vm._v(" "+_vm._s(_vm.$t("StructurePage.Function"))+" ")]),_c('SingleSelect',{ref:"multiSelectFunctions",staticClass:"chip-functions",attrs:{"id":"functions-select","placeholder":_vm.$t('Select'),"optionSelected":_vm.functions,"options":_vm.rolesList.map(function (item) { return ({
                            value: item.id,
                            text: this$1.rolesList.find(function (f) { return f.id === item.id; }).name
                        }); }),"searchable":false,"translate":true,"state":_vm.states.functions,"multiselect":true},on:{"input":function (value) { return (_vm.functions = value); }}},[_vm._v(" "+_vm._s(_vm.$t("RequiredField"))+" ")])],1),(_vm.currentCustomFields.length && _vm.isOpen)?_c('div',[_c('div',{staticClass:"topic-title col-md-12"},[_vm._v(_vm._s(_vm.$t("StructurePage.CustomFields")))]),_vm._l((_vm.displayImportantFirst),function(field){return _c('div',{key:field.id},[_c('custom-fields',{attrs:{"field":field,"hasError":_vm.states.customFieldsSitesForceError},on:{"input":function (value) {
                                field.value = value ? value : null;
                            }}})],1)})],2):_vm._e(),(_vm.settingsFields.length && _vm.isOpen)?_c('div',[_c('div',{staticClass:"topic-title col-md-12"},[_vm._v(_vm._s(_vm.$t("StructurePage.Settings")))]),_vm._l((_vm.displayImportantFirstSettings),function(field){return _c('div',{key:field.id,staticClass:"check-button"},[_c('custom-fields',{attrs:{"field":field,"hasError":_vm.states.customFieldsSettingsForceError},on:{"input":function (value) {
                                field.value = value ? value : null;
                            }}})],1)})],2):_vm._e(),_c('b-col',{staticClass:"form-group",attrs:{"md":"12"}},[_c('div',{staticClass:"topic-title"},[_vm._v(_vm._s(_vm.$t("Breadcrumbs.OrganizationalStructure")))]),_c('label',{attrs:{"for":"unit-select"}},[_vm._v(" "+_vm._s(_vm.$t("StructurePage.Unit"))+" ")]),_c('SingleSelect',{attrs:{"id":"unit-select","placeholder":_vm.$t('Select'),"optionSelected":_vm.unitList.find(function (item) { return item.value == _vm.unit; }),"options":_vm.unitList,"searchable":false,"translate":true,"state":_vm.states.unit},on:{"input":function (value) { return (_vm.unit = value ? value.value : value); }}},[_vm._v(" "+_vm._s(_vm.$t("RequiredField"))+" ")]),_c('label',{staticClass:"group-label",attrs:{"for":"group-select"}},[_vm._v(" "+_vm._s(_vm.$t("StructurePage.Group"))+" ")]),_c('SingleSelect',{attrs:{"id":"group-select","placeholder":_vm.$t('Select'),"optionSelected":_vm.groupList.find(function (item) { return item.value == _vm.group; }),"options":_vm.groupList,"searchable":false,"translate":true,"state":_vm.states.group},on:{"input":function (value) { return (_vm.group = value ? value.value : value); }}},[_vm._v(" "+_vm._s(_vm.$t("RequiredField"))+" ")])],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
    <div id="production-orders-page">
        <modal-delete
            :level="currentLevel"
            :name="currentName"
            @confirm="deleteItems"
        />
        <modal-add-edit-order
            :modalTitle="modalTitle"
            :device="device"
            :currentId="currentId"
            :tagsList="tagsList"
            :materialList="materialList"
            :customFields="customFields"
            :customFieldsOperation="customFieldsOperation"
            @updateList="getOrders"
        />
        <modal-add-edit-operation
            :modalTitle="modalTitle"
            :device="device"
            :currentId="currentId"
            :tagsList="tagsList"
            :customFields="customFieldsOperation"
            :workcentersList="workcentersList"
            @updateList="getOrders"
        />
        <modal-resource
            :device="device"
            :modalTitle="currentName"
            :operationName="currentOperationName"
            :currentId="currentId"
            :permissionsList="permissionsList"
            @updateList="getOrders"
        />
        <modal-link-operations
            :modalTitle="currentName"
            :currentId="currentId"
            :device="device"
            @updateList="getOrders"
        />
        <modal-manage-tags
            :device="device"
            @updateTags="getContext"
        />
        <modal-duplicate
            :device="device"
            :modalTitle="modalDuplicateName"
            :currentId="currentId"
            :currentLevel="currentLevel"
            :currentName="currentName"
            :operationCount="operationCount"
            :materialCount="materialCount"
            @updateList="getOrders"
        />
        <modal-link-materials
            :modalTitle="currentName"
            :device="device"
            :isEdit="isEditMaterials"
            :currentId="currentId"
            :currentParentId="currentParentId"
            @updateList="getOrders"
        />
        <div
            class="h-100"
            v-if="dragList.length === 0 && !noItemsFound"
        >
            <div class="no-items h-100">
                <EmptyBoxIcon class="empty-box" />
                <p class="title">{{ $t("OrdersPage.NoOrdersTitle") }}</p>
                <p class="subtitle">
                    {{ $t("OrdersPage.NoOrdersSubtitle") }}
                </p>
                <b-button
                    class="btn-new-orders"
                    @click="permissionsList.find((e) => e.type === 'create') && openModalNewOrder()"
                >
                    <AddIcon class="add-icon" />
                    {{ $t("OrdersPage.NewOrder") }}
                </b-button>
            </div>
        </div>
        <div
            v-else
            class="h-100"
        >
            <div class="header-block">
                <div class="first-line">
                    <div class="title">
                        {{ $t("Breadcrumbs.ProductionOrders") }}
                    </div>
                    <div class="add-orders">
                        <b-button
                            class="btn-tags"
                            @click="
                                permissionsList.find((e) => e.type === 'update') &&
                                UPDATE_MODAL_MANAGE_TAGS_TOGGLE(true)
                            "
                        >
                            <TagIcon class="tag-icon" />
                            Tags
                        </b-button>
                        <b-button
                            class="btn-new-orders"
                            @click="
                                permissionsList.find((e) => e.type === 'create') &&
                                openModalNewOrder()
                            "
                        >
                            <AddIcon class="add-icon" />
                            {{ $t("OrdersPage.NewOrder") }}
                        </b-button>
                    </div>
                </div>
                <div class="second-line">
                    <div class="search-input">
                        <div class="div-custom-input input__orders">
                            <div class="icon-block">
                                <SearchIcon class="search-icon" />
                            </div>
                            <b-form-tags
                                class="input-search-orders"
                                ref="formOrders"
                                v-model="filter.textFilter"
                                duplicate-tag-text=""
                                :placeholder="
                                    filter.textFilter.length === 0
                                        ? $t('SearchPlaceholderDefault')
                                        : ''
                                "
                                @input="getOrders()"
                                remove-on-delete
                                add-on-change
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div class="body-block">
                <div class="h-100">
                    <CollapseDraggable
                        isOrder
                        :list="dragList"
                        :dragList="dragList"
                        :dragListStart="dragListStart"
                        :optionIcons="optionIcons"
                        :levelIcons="levelIcons"
                        :selectedChecks="selectedChecks"
                        :forceRender="forceRender"
                        :permissions="permissionsList"
                        @selectedToZero="UPDATE_SELECTED_CHECKS(0)"
                        @deleteItems="(ids) => deleteItems(ids)"
                        @getEditInfos="
                            (id, level, _, parentId) => getEditInfos(id, level, parentId)
                        "
                        @checkedCounter="checkedCounter"
                        @undoChangesTree="undoChangesTree"
                        @saveActualTree="saveActualTree"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import { BFormTags, BButton } from "bootstrap-vue";
    import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
    import SearchIcon from "@core/assets/icons/search-icon.svg";
    import AddIcon from "@core/assets/icons/add-icon.svg";
    import TagIcon from "@core/assets/icons/tag-icon.svg";
    import InfoIcon from "@core/assets/icons/info-outline-icon.svg";
    import EditIcon from "@core/assets/icons/edit-icon.svg";
    import LinkIcon from "@core/assets/icons/integration-icon.svg";
    import UnLinkIcon from "@core/assets/icons/link-broken-icon.svg";
    import OrderIcon from "@core/assets/icons/order-icon.svg";
    import OperationIcon from "@core/assets/icons/operation-hierarchy-icon.svg";
    import UsedMaterialIcon from "@core/assets/icons/used-material-icon.svg";
    import NewIcon from "@core/assets/icons/new-icon.svg";
    import DuplicateIcon from "@core/assets/icons/duplicate-icon.svg";
    import WorkcenterIcon from "@core/assets/icons/workcenter-hierarchy-icon.svg";
    import DeleteIcon from "@core/assets/icons/delete-icon.svg";
    import OptionsIcon from "@core/assets/icons/options-icon.svg";
    import EmptyBoxIcon from "@core/assets/icons/empty-box-icon.svg";
    import ModalManageTags from "./components/ModalManageTags.vue";
    import ModalDuplicate from "./components/ModalDuplicate.vue";
    import ModalLinkMaterials from "./components/ModalLinkMaterials.vue";
    import ModalLinkOperations from "./components/ModalLinkOperations.vue";
    import ModalResource from "./components/resourcesModals/ModalResource.vue";
    import ModalAddEditOrder from "./components/addOrderModals/ModalAddEditOrder.vue";
    import ModalAddEditOperation from "./components/addEditOperationModals/ModalAddEditOperation.vue";
    import CollapseDraggable from "@/@core/components/collapse-draggable/CollapseDraggable.vue";
    import ModalDelete from "./components/ModalDelete.vue";
    import { processIndustrialParameters, handleFillCustomFields } from "@/utils/customFields";

    export default {
        components: {
            BFormTags,
            BButton,
            ToastificationContent,
            ModalManageTags,
            ModalDuplicate,
            ModalLinkMaterials,
            ModalLinkOperations,
            ModalResource,
            ModalAddEditOrder,
            ModalAddEditOperation,
            CollapseDraggable,
            ModalDelete,
            SearchIcon,
            AddIcon,
            TagIcon,
            InfoIcon,
            EditIcon,
            DeleteIcon,
            OptionsIcon,
            EmptyBoxIcon
        },
        data() {
            return {
                filter: {
                    textFilter: []
                },
                customFields: [],
                customFieldsOperation: [],
                tagsList: [],
                workcentersList: [],
                materialList: [],
                permissionsList: [],
                noItemsFound: false,
                modalTitle: "",
                modalDuplicateName: "",
                isEditMaterials: false,
                currentId: null,
                currentParentId: null,
                currentLevel: null,
                currentName: null,
                currentOperationName: null,
                operationCount: 0,
                materialCount: 0,
                forceRender: 0,
                levelIcons: [
                    {
                        title: "OrdersPage.OrderNumber",
                        techName: "Order",
                        level: 1,
                        icon: OrderIcon
                    },
                    {
                        title: "ProductionProgress.Operation",
                        techName: "Operation",
                        level: 2,
                        icon: OperationIcon
                    },
                    {
                        title: "Material",
                        techName: "UsedMaterial",
                        level: 3,
                        icon: UsedMaterialIcon
                    }
                ],
                optionIcons: [
                    {
                        title: "Info",
                        icon: InfoIcon,
                        click: () => {}
                    },
                    {
                        title: "StructurePage.WorkCenter",
                        icon: WorkcenterIcon,
                        showOnLast: 2,
                        click: (e) => {
                            this.currentOperationName = e.name;
                            this.currentName = e.workcenter;
                            this.currentId = e.id;
                            this.UPDATE_MODAL_RESOURCE_TOGGLE(true);
                        }
                    },
                    {
                        title: "DepartmentPage.Actions",
                        icon: OptionsIcon,
                        click: () => {},
                        actions: [
                            {
                                title: "DepartmentPage.Newa",
                                icon: NewIcon,
                                click: (e) => {
                                    if (this.permissionsList.find((e) => e.type === "create")) {
                                        const levelModal = this.levelIcons.find(
                                            (e) => e.level === this.currentLevel + 1
                                        );
                                        this.modalTitle = `${this.$t(e.title)} ${this.$t(levelModal.title)}`;

                                        const orderId = this.getCurrentRow(this.dragList).id;
                                        this.currentId = orderId;
                                        this.currentParentId = null;

                                        this.UPDATE_MODAL_ADD_EDIT_OPERATION(true);
                                    }
                                }
                            },
                            {
                                title: "MaterialsPage.LinkMaterial",
                                icon: LinkIcon,
                                click: (e) => {
                                    if (this.permissionsList.find((e) => e.type === "update")) {
                                        this.currentName = this.getCurrentRow(this.dragList).name;
                                        this.UPDATE_MODAL_LINK_MATERIALS_TOGGLE(true);
                                    }
                                }
                            },
                            {
                                title: "MaterialsPage.UnlinkMaterial",
                                icon: UnLinkIcon,
                                click: () => {
                                    if (this.permissionsList.find((e) => e.type === "update")) {
                                        this.unLinkMaterial();
                                    }
                                }
                            },
                            {
                                title: "OrdersPage.OptionLinkOperation",
                                icon: LinkIcon,
                                click: (e) => {
                                    if (this.permissionsList.find((e) => e.type === "update")) {
                                        this.currentName = this.getCurrentRow(this.dragList).name;
                                        this.UPDATE_MODAL_LINK_OPERATIONS_TOGGLE(true);
                                    }
                                }
                            },
                            {
                                title: "OrdersPage.UnlinkOperation",
                                icon: UnLinkIcon,
                                click: (e) => {
                                    if (this.permissionsList.find((e) => e.type === "update")) {
                                        const operationId = this.getCurrentRow(this.dragList).id;

                                        const orderId = this.dragList.find((order) =>
                                            order.child.find(
                                                (operation) => operation.id == operationId
                                            )
                                        ).id;

                                        this.unLinkOperation(
                                            !isNaN(orderId) ? orderId : null,
                                            !isNaN(operationId) ? [operationId] : []
                                        );
                                    }
                                }
                            },
                            {
                                title: "DepartmentPage.Edit",
                                icon: EditIcon,
                                click: (e) => {
                                    if (this.permissionsList.find((e) => e.type === "update")) {
                                        const levelModal = this.levelIcons.find(
                                            (e) => e.level === this.currentLevel
                                        );
                                        this.modalTitle = `${this.$t(e.title)} ${this.$t(levelModal.title)}`;
                                        if (levelModal.techName === "Order") {
                                            this.modalTitle = `${this.$t(e.title)} ${this.$t(
                                                "ProductionProgress.Order"
                                            )}`;
                                            this.UPDATE_MODAL_ADD_EDIT_ORDER(true);
                                        }
                                        if (levelModal.techName === "Operation") {
                                            this.UPDATE_MODAL_ADD_EDIT_OPERATION(true);
                                        }
                                        if (levelModal.techName === "UsedMaterial") {
                                            this.isEditMaterials = true;
                                            this.currentName = this.getCurrentRow(
                                                this.dragList
                                            ).name;
                                            this.UPDATE_MODAL_LINK_MATERIALS_TOGGLE(true);
                                        }
                                    }
                                }
                            },
                            {
                                title: "ProfilesPage.Duplicate",
                                icon: DuplicateIcon,
                                click: (e) => {
                                    if (this.permissionsList.find((e) => e.type === "create")) {
                                        const levelModal = this.levelIcons.find(
                                            (e) => e.level === this.currentLevel
                                        );
                                        this.modalTitle = `${this.$t(e.title)} ${this.$t(levelModal.title)}`;
                                        this.currentName = `${this.getCurrentRow(this.dragList).name} (${this.$t(
                                            "MaterialsPage.Duplicated"
                                        )})`;
                                        const currentModalTitle =
                                            levelModal.level === 1
                                                ? "ProductionProgress.Order"
                                                : levelModal.title;
                                        this.modalDuplicateName = `${this.$t("ProfilesPage.Duplicate")} ${this.$t(
                                            currentModalTitle
                                        )}`;
                                        const currentRow = this.getCurrentRow(this.dragList);
                                        this.operationCount = currentRow.operationCount;
                                        this.materialCount = currentRow.materialCount;
                                        this.UPDATE_MODAL_DUPLICATE_TOGGLE(true);
                                    }
                                }
                            },
                            {
                                title: "DepartmentPage.Delete",
                                icon: DeleteIcon,
                                click: () => {
                                    if (this.permissionsList.find((e) => e.type === "delete")) {
                                        this.currentName = this.getCurrentRow(this.dragList).name;
                                        this.UPDATE_MODAL_DELETE(true);
                                    }
                                }
                            }
                        ]
                    }
                ],
                device:
                    window.innerWidth > 768
                        ? "desktop"
                        : window.innerWidth > 480 && window.innerWidth <= 768
                          ? "tablet"
                          : "mobile"
            };
        },
        created() {
            window.addEventListener("resize", this.handleResize);
        },
        destroyed() {
            window.removeEventListener("resize", this.handleResize);
        },
        async mounted() {
            this.UPDATE_FLAG_SHOW_OVERLAY(true);
            await this.getContext();
            await this.getOrders();
            this.activeCarousel();
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("departments", ["UPDATE_MODAL_DELETE_SNACK_BAR"]),
            ...mapMutations("productionOrders", [
                "UPDATE_MODAL_MANAGE_TAGS_TOGGLE",
                "UPDATE_MODAL_DUPLICATE_TOGGLE",
                "UPDATE_MODAL_LINK_MATERIALS_TOGGLE",
                "UPDATE_MODAL_LINK_OPERATIONS_TOGGLE",
                "UPDATE_MODAL_RESOURCE_TOGGLE",
                "UPDATE_MODAL_ADD_EDIT_ORDER",
                "UPDATE_MODAL_ADD_EDIT_OPERATION",
                "UPDATE_DRAG_LIST_START",
                "UPDATE_DRAG_LIST",
                "UPDATE_SELECTED_CHECKS",
                "UPDATE_MODAL_DELETE"
            ]),
            handleResize() {
                this.device =
                    window.innerWidth > 768
                        ? "desktop"
                        : window.innerWidth > 480 && window.innerWidth <= 768
                          ? "tablet"
                          : "mobile";
            },
            activeCarousel() {
                const vm = this;
                $(function () {
                    document
                        .getElementsByClassName("input-search-orders")[0]
                        .setAttribute("tabIndex", "-1");

                    const slider = document.querySelector(".b-form-tags-list");
                    let mouseDown = false;
                    let startX, scrollLeft;

                    let startDragging = function (e) {
                        mouseDown = true;
                        startX = e.pageX - slider.offsetLeft;
                        scrollLeft = slider.scrollLeft;
                    };
                    let stopDragging = function () {
                        mouseDown = false;
                    };

                    slider.addEventListener("mousemove", (e) => {
                        e.preventDefault();
                        if (!mouseDown) {
                            return;
                        }
                        const x = e.pageX - slider.offsetLeft;
                        const scroll = x - startX;
                        slider.scrollLeft = scrollLeft - scroll;
                    });

                    slider.addEventListener("mousedown", startDragging, false);
                    slider.addEventListener("mouseup", stopDragging, false);
                    slider.addEventListener("mouseleave", stopDragging, false);
                });
            },
            getCurrentRow(list) {
                if (!list || list.length === 0) {
                    return null;
                }

                for (const item of list) {
                    if (item.id === this.currentId) {
                        return item;
                    }

                    if (!item.isMaterials && item.child && item.child.length > 0) {
                        const foundItem = this.getCurrentRow(item.child);
                        if (foundItem) {
                            return foundItem;
                        }
                    }
                }

                return null;
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            },
            openModalNewOrder() {
                this.modalTitle = "OrdersPage.NewOrder";
                this.UPDATE_MODAL_ADD_EDIT_ORDER(true);
            },
            addChildWhenUndefined(objArr) {
                objArr.forEach((obj) => {
                    if (!obj.hasOwnProperty("child")) {
                        obj.child = [];
                        obj.isMaterials = true;
                    }
                    this.addChildWhenUndefined(obj.child);
                });
            },
            addLevelByChild(arr, level = 1) {
                arr.map((e) => {
                    e.level = level;
                    if (e.child.length > 0) {
                        e.child.forEach((f) => (f.parentId = e.id));
                    }
                    if (e.child.length) this.addLevelByChild(e.child, level + 1);
                });
            },
            async deleteItems(ids) {
                const payload = ids
                    ? ids.map((e) => ({
                          id: e.id,
                          level: e.level,
                          parentId: e.parentId
                      }))
                    : [
                          {
                              id: this.currentId,
                              level: this.currentLevel,
                              parentId: this.currentParentId
                          }
                      ];
                let toastText =
                    ids && ids[0].level === 1
                        ? this.$t("OrdersPage.OrdersDeleted")
                        : this.$t("DepartmentPage.DeletedItems");
                if (!ids) {
                    const levelToastMessages = {
                        1: this.$t("OrdersPage.OrderDeleted"),
                        2: this.$t("MaterialsPage.OperationDeleted")
                    };

                    toastText = levelToastMessages[this.currentLevel] || "";
                }

                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                await this.$http2
                    .delete(`/api/myconfig/order`, { data: payload })
                    .then(() => {
                        this.showToast(
                            this.$t("IntegrationPage.success"),
                            "SmileIcon",
                            toastText,
                            "success"
                        );
                        this.forceRender = this.forceRender + 1;
                        this.UPDATE_MODAL_DELETE(false);
                        this.UPDATE_MODAL_DELETE_SNACK_BAR(false);
                        this.UPDATE_SELECTED_CHECKS(0);
                        this.getOrders();
                    })
                    .catch((error) => {
                        error.response.data.errors.map((e) => {
                            this.showToast(this.$t("Error"), "FrownIcon", e.message, "danger");
                        });
                    })
                    .finally(() => {
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            },
            getEditInfos(id, level, parentId) {
                this.currentId = id;
                this.currentLevel = level;
                this.currentParentId = parentId;
            },
            checkedCounter(counter) {
                this.UPDATE_SELECTED_CHECKS(counter);
            },
            async getContext() {
                await this.$http2.get("/api/myconfig/order/context").then((response) => {
                    const materialsArray = response.data.data.materials.map((e) => {
                        const formattedIndustrialParameter = processIndustrialParameters(
                            e.industrialParameter
                        );

                        handleFillCustomFields(formattedIndustrialParameter, e.industrialParameter);

                        return {
                            ...e,
                            industrialParameter: formattedIndustrialParameter.sort((a, b) => {
                                if (a.important && !b.important) {
                                    return -1;
                                }
                                if (!a.important && b.important) {
                                    return 1;
                                }
                                return 0;
                            }),
                            value: e.id,
                            text: `${e.code} • ${e.name}`
                        };
                    });

                    const sortedArray = materialsArray.sort((a, b) => {
                        if (a.name && b.name) {
                        } else if (!a.name) {
                            return 1;
                        } else if (!b.name) {
                            return -1;
                        }

                        return a.name.localeCompare(b.name);
                    });

                    this.materialList = [...sortedArray];

                    this.workcentersList = response.data.data.workcenters.map((e) => ({
                        value: e.id,
                        text: e.name
                    }));
                    this.tagsList = response.data.data.tags
                        .map((e) => ({
                            value: e.id,
                            text: e.name
                        }))
                        .sort((a, b) => a.text.localeCompare(b.text));
                    this.permissionsList = response.data.data.permissions;

                    this.customFields = processIndustrialParameters(
                        response.data.data.industrialParametersOrder
                    );

                    this.customFieldsOperation = processIndustrialParameters(
                        response.data.data.industrialParametersOperation
                    );
                });
            },
            async getOrders() {
                if (this.permissionsList.find((e) => e.type === "show")) {
                    this.UPDATE_FLAG_SHOW_OVERLAY(true);
                    const textFilterQuery = this.filter.textFilter;
                    await this.$http2
                        .get(`/api/myconfig/order?textFilter=${textFilterQuery}`)
                        .then((response) => {
                            this.noItemsFound = !!textFilterQuery.length;
                            const updatedArr = response.data.data;
                            const sortedArray = updatedArr.sort((a, b) => {
                                if (!a.materialCode) return 1;
                                if (!b.materialCode) return -1;
                                return a.materialCode.localeCompare(b.materialCode, "en", {
                                    numeric: true,
                                    sensitivity: "base"
                                });
                            });

                            this.forceRender = this.forceRender + 1;
                            this.addChildWhenUndefined(sortedArray);
                            this.addLevelByChild(sortedArray);
                            this.UPDATE_DRAG_LIST(sortedArray);
                            this.UPDATE_SELECTED_CHECKS(0);
                            this.saveActualTree();
                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        });
                } else {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                }
            },
            async unLinkOperation(orderId, ids) {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                await this.$http2
                    .delete(`/api/myconfig/order/${orderId}/remove-operations`, { data: ids })
                    .then(() => {
                        this.getOrders();
                        this.showToast(
                            this.$t("OrdersPage.Success"),
                            "SmileIcon",
                            this.$t("OrdersPage.OperationUnlinked"),
                            "success"
                        );
                    })
                    .finally(() => {
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            },
            async unLinkMaterial() {
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                const currentRow = this.getCurrentRow(this.dragList);

                await this.$http2
                    .delete(
                        `/api/myconfig/order/operation/${currentRow.parentId}/remove-materials`,
                        {
                            data: [currentRow.id]
                        }
                    )
                    .then(() => {
                        this.getOrders();
                        this.showToast(
                            this.$t("OrdersPage.Success"),
                            "SmileIcon",
                            this.$t("MaterialsPage.MaterialsUnlinked"),
                            "success"
                        );
                    })
                    .finally(() => {
                        this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    });
            },
            undoChangesTree() {
                this.UPDATE_DRAG_LIST(JSON.parse(JSON.stringify(this.dragListStart)));
            },
            saveActualTree() {
                this.UPDATE_DRAG_LIST_START(JSON.parse(JSON.stringify(this.dragList)));
            }
        },
        computed: {
            ...mapState("productionOrders", {
                dragList: "dragList",
                dragListStart: "dragListStart",
                selectedChecks: "selectedChecks",
                isModalLinkMaterialsOpen: "isModalLinkMaterialsOpen"
            })
        },
        watch: {
            isModalLinkMaterialsOpen(v) {
                if (!v) this.isEditMaterials = false;
            }
        }
    };
</script>
<style lang="scss">
    #production-orders-page {
        padding: 16px 0;
        border-radius: 6px;
        background: #fff;
        box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);

        @media (max-width: 768px) {
            margin-top: 24px;
        }

        &:has(.no-items) {
            height: calc(100vh - 136px);
        }

        .no-items {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 6px;
            margin: 0 16px;
            border: 1px solid #cfc4be;

            .empty-box {
                width: 80px;
                height: 80px;
            }

            .title {
                font-size: 16px;
                font-weight: 600;
                line-height: 26px;
                color: #4c4541;
                margin: 0;
            }

            .subtitle {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: #998f8a;
                padding: 4px 0 12px;
                margin: 0;
            }

            .btn-new-orders {
                border-color: #974900 !important;
                background-color: #974900 !important;
                display: flex;
                align-items: center;
                justify-content: center;
                gap: 8px;
                padding: 8px 21px;
                line-height: 20px;
                letter-spacing: 0.175px;

                .add-icon {
                    width: 14px;
                    height: 14px;
                    fill: #fff;
                }
            }

            @media (max-width: 480px) {
                .empty-box {
                    width: 40px;
                    height: 40px;
                }

                .title {
                    font-size: 14px;
                }

                .subtitle {
                    font-size: 12px;
                    text-align: center;
                    padding: 4px 48px 12px;
                    line-height: 16px;
                }

                .btn-new-orders {
                    padding: 3px 14px;
                    color: #fff;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 20px;
                    letter-spacing: 0.15px;

                    .add-icon {
                        width: 12px;
                        height: 12px;
                    }
                }
            }
        }

        .header-block {
            padding: 0 16px;
            .first-line {
                display: flex;
                justify-content: space-between;
                gap: 16px;

                .title {
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                }
                .add-orders {
                    display: flex;
                    gap: 16px;

                    .btn-new-orders {
                        border-color: #974900 !important;
                        background-color: #974900 !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 8px;
                        width: 100% !important;
                        min-width: 136px;
                        padding: 3px 14px !important;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.0125em;

                        .add-icon {
                            width: 12px;
                            height: 12px;
                            fill: #fff;
                        }
                    }

                    .btn-tags {
                        border-color: #974900 !important;
                        background-color: #fff !important;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        gap: 4px;
                        min-width: 73px;
                        padding: 3px 14px !important;
                        font-size: 12px;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.0125em;
                        color: #974900 !important;
                    }

                    @media (max-width: 480px) {
                        flex-direction: column;
                        gap: 8px;
                    }
                }

                @media (max-width: 480px) {
                    flex-direction: column;
                }
            }
            .second-line {
                .search-input {
                    display: flex;
                    align-items: center;
                    padding: 16px 0 8px;

                    .div-custom-input {
                        display: flex;
                        align-items: center;
                        border-radius: 4px;
                        width: 100%;
                        overflow: hidden;
                        border: 1px solid #cfc4be;
                        .icon-block {
                            display: flex;
                            align-items: center;
                            padding-left: 14px;
                            height: 14px;
                            .search-icon {
                                width: 14px;
                                height: 14px;
                                fill: #cfc4be;
                            }
                        }
                        .input-search-orders {
                            width: 100% !important;
                            border: none;
                            overflow: hidden;

                            &.focus {
                                box-shadow: none;
                            }
                            &.b-form-tags {
                                padding: 0 !important;
                                display: flex;
                                align-items: center !important;
                                .b-form-tags-list {
                                    width: 100% !important;
                                    margin-left: 14px !important;
                                    margin-top: 0;
                                    height: 100% !important;
                                    flex-wrap: nowrap !important;
                                    display: flex !important;
                                    align-items: center !important;
                                    overflow: scroll;
                                    overflow-y: hidden;
                                    cursor: grabbing;
                                    cursor: -webkit-grabbing;

                                    &::-webkit-scrollbar {
                                        display: none !important;
                                    }
                                    .b-form-tags-field {
                                        margin: 9px 0;
                                    }
                                }
                            }

                            .b-form-tags-button {
                                display: none;
                            }

                            .b-form-tag {
                                background-color: #974900;
                                margin-right: 0.6rem;
                                padding: 2px 8px !important;
                                font-size: 13px;
                            }
                        }
                    }
                }
            }
        }
        .body-block {
            &:has(.block-no-results) {
                height: calc(100% - 90px);
            }
        }
        &:has(.body-block .block-no-results) {
            height: calc(100vh - 135px);
        }
        &:has(.body-block .no-items) {
            height: calc(100vh - 135px);
        }
    }
</style>

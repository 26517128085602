import Vue from "vue";
import VueRouter from "vue-router";

import { isUserLoggedIn } from "@/auth/utils";

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: "/",
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/",
            redirect: `/:prefix/dashboard`,
            name: "home"
        },
        {
            path: "/:prefix/dashboard",
            name: "my-config",
            component: () => import("@/views/pages/pageTest/test.vue"),
            meta: {}
        },
        {
            path: "/:prefix/company-settings/resources",
            name: "resources",
            component: () => import("@/views/pages/resources/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.CompanySettings",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Resources",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/company-settings/departments",
            name: "departments",
            component: () => import("@/views/pages/departments/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.CompanySettings",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Departments",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/company-settings/organizational-structure",
            name: "organizational-structure",
            component: () => import("@/views/pages/organizationalStructure/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.CompanySettings",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.OrganizationalStructure",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/company-settings/security/profiles",
            name: "profiles",
            component: () => import("@/views/pages/profile/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.CompanySettings",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Security",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Profile",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/company-settings/security/people",
            name: "people",
            component: () => import("@/views/pages/people/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.CompanySettings",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Security",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.People",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/manufacturing/materials",
            name: "materials",
            component: () => import("@/views/pages/materials/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.Manufacturing",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Materials",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/manufacturing/production-orders",
            name: "orders",
            component: () => import("@/views/pages/productionOrders/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.Manufacturing",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.ProductionOrders",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/manufacturing/operational-status",
            name: "operational-status",
            component: () => import("@/views/pages/operationalStatus/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.Manufacturing",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.OperationalStatus",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/manufacturing/rejection-reasons",
            name: "refuse-status",
            component: () => import("@/views/pages/rejectionReasons/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.Manufacturing",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.RejectionReasons",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/manufacturing/calendar/standard",
            name: "standard",
            component: () => import("@/views/pages/calendar/standard/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.Manufacturing",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Calendar",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Standard",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/manufacturing/calendar/create",
            name: "newCalendar",
            component: () => import("@/views/pages/calendar/actionCalendar/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.Manufacturing",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Calendar",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Standard",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "DepartmentPage.Create",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/manufacturing/calendar/edit",
            name: "editCalendar",
            component: () => import("@/views/pages/calendar/actionCalendar/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.Manufacturing",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Calendar",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.Standard",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "DepartmentPage.Edit",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/:prefix/company-settings/data-fields",
            name: "data-fields",
            component: () => import("@/views/pages/dataFields/index.vue"),
            meta: {
                breadcrumb: [
                    {
                        text: "Breadcrumbs.CompanySettings",
                        active: false,
                        icon: ""
                    },
                    {
                        text: "Breadcrumbs.DataFields",
                        active: true,
                        icon: ""
                    }
                ]
            }
        },
        {
            path: "/logout",
            name: "logout",
            component: () => import("@/views/pages/logout/logout.vue"),
            meta: {
                layout: "full",
                action: "read",
                resource: "basic"
            }
        },
        {
            path: "/error-404",
            name: "error-404",
            component: () => import("@/views/error/Error404.vue"),
            meta: {
                layout: "full",
                action: "read",
                resource: "basic"
            }
        },
        {
            path: "/:prefix/not-authorized",
            name: "not-authorized",
            component: () => import("@/views/pages/miscellaneous/NotAuthorized.vue"),
            meta: {
                layout: "full",
                action: "read",
                resource: "basic"
            }
        },
        {
            path: "/:prefix/authenticate-required",
            name: "authenticate-required",
            component: () => import("@/views/pages/miscellaneous/AuthenticateRequired.vue"),
            meta: {
                layout: "full",
                action: "read",
                resource: "basic"
            }
        },
        {
            path: "*",
            redirect: "error-404"
        }
    ]
});

router.beforeEach((to, _, next) => {
    const { name, path } = to;
    const isLoggedIn = isUserLoggedIn();
    const authenticationPages = ["login", "forgot"];
    let authenticationPage = "";

    const isAuthenticationPage = authenticationPages.some((page) => {
        if (path.includes(page)) {
            authenticationPage = page;
            return true;
        }
    });

    if (name === "home" && isAuthenticationPage) return next({ name: authenticationPage });

    if (!name) return next({ name: "home" });
    if (isLoggedIn && isAuthenticationPage) return next({ name: "home" });

    return next();
});

router.afterEach(() => {
    // Remove initial loading
    const appLoading = document.getElementById("loading-bg");
    if (appLoading) {
        appLoading.style.display = "none";
    }
});

export default router;

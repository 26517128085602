<template>
    <div class="d-flex">
        <modal-new-group
            :entityId="entityId"
            :groupList="filteredGroupList"
            @confirm="newGroupCreated"
        />
        <modal-list-options
            :device="device"
            :isCheckbox="format === 25"
            @onCancel="onCancel"
            @confirm="onSave"
        />
        <b-sidebar
            id="data-field-sidebar-right"
            :class="[
                'sidebar-data-field',
                isModalOptionsListOpen && device !== 'mobile' ? '--modal-openned' : '--modal-closed'
            ]"
            right
            bg-variant="white"
            :width="device === 'desktop' ? '407px' : device === 'tablet' ? '50vw' : '100vw'"
            v-model="isOpen"
        >
            <template #header>
                <div class="header-sidebar d-flex justify-content-between">
                    <span class="sidebar-title">
                        {{ `${$t(modalTitle)} ${$t("DataFieldsPage.DataField")}` }}
                    </span>
                    <span
                        class="close-icon"
                        @click="closeModal()"
                    >
                        <CloseIcon />
                    </span>
                </div>
                <div class="separator"></div>
            </template>
            <template>
                <div>
                    <b-col md="12">
                        <b-form-group
                            :label="$t('DepartmentPage.Name')"
                            label-for="name"
                            :invalid-feedback="
                                flagNameEqual
                                    ? $t('DataFieldsPage.EqualFieldName')
                                    : $t('RequiredField')
                            "
                        >
                            <b-form-input
                                id="name"
                                v-model="name"
                                :placeholder="$t('ResourceStatus.Placeholder')"
                                :state="states.name"
                                lazy-formatter
                                :formatter="
                                    () => {
                                        states.name = name !== '';
                                        return name;
                                    }
                                "
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <b-form-group
                            :label="$t('DepartmentPage.Description')"
                            label-for="description"
                            :invalid-feedback="$t('RequiredField')"
                        >
                            <b-form-textarea
                                id="description"
                                v-model="description"
                                :placeholder="$t('ResourceStatus.Placeholder')"
                                :state="states.description"
                                no-resize
                            />
                        </b-form-group>
                    </b-col>
                    <b-col md="12">
                        <label for="group-select">
                            {{ $t("StructurePage.Group") }}
                        </label>
                        <SingleSelect
                            id="group-select"
                            :placeholder="$t('Select')"
                            :optionSelected="filteredGroupList.find((item) => item.value == group)"
                            :options="filteredGroupList"
                            :searchable="false"
                            :translate="true"
                            :state="states.group"
                            :footerText="$t('StructurePage.NewGroup')"
                            showFooter
                            @footerClick="newGroup()"
                            @input="(value) => (group = value ? value.value : value)"
                        >
                            {{ $t("RequiredField") }}
                        </SingleSelect>
                    </b-col>
                    <b-col md="12">
                        <label for="format-select">
                            {{ $t("DataFieldsPage.Format") }}
                        </label>
                        <SingleSelect
                            id="format-select"
                            :placeholder="$t('Select')"
                            :optionSelected="formatList.find((item) => item.id == format)"
                            :options="formatList"
                            :searchable="false"
                            :state="states.format"
                            :disabled="modalTitle.includes('Edit')"
                            @input="(value) => (format = value ? value.value : value)"
                        >
                            {{ $t("RequiredField") }}
                        </SingleSelect>
                    </b-col>
                    <b-col
                        v-if="showSettingsButton && device === 'mobile'"
                        md="12"
                    >
                        <b-button
                            class="btn-config"
                            @click="openListOptions()"
                        >
                            {{ $t("DataFieldsPage.ConfigureOptions") }}
                        </b-button>
                    </b-col>
                    <div class="custom-switch-button">
                        <div class="d-flex align-items-center">
                            <b-form-checkbox
                                id="required-checkbox"
                                v-model="required"
                                name="default-time-switch"
                                switch
                                class="check-button p-0"
                                @change="(e) => (required = e)"
                            />
                            <div class="text-allow">{{ $t("DataFieldsPage.MandatoryField") }}</div>
                            <span id="required-check">
                                <InfoIcon class="info-icon" />
                            </span>
                        </div>
                    </div>
                    <Tooltip
                        target="required-check"
                        :text="$t('DataFieldsPage.RequiredText')"
                        placement="top"
                    />
                    <div class="custom-switch-button">
                        <div class="d-flex align-items-center">
                            <b-form-checkbox
                                id="important-checkbox"
                                v-model="important"
                                name="default-time-switch"
                                switch
                                class="check-button p-0"
                                @change="(e) => (important = e)"
                            />
                            <div class="text-allow">{{ $t("DataFieldsPage.ImportantField") }}</div>
                            <span id="important-check">
                                <InfoIcon class="info-icon" />
                            </span>
                        </div>
                    </div>
                    <Tooltip
                        target="important-check"
                        :text="$t('DataFieldsPage.ImportantText')"
                        placement="top"
                    />

                    <div v-if="entityId === appointmentId">
                        <div class="where-to-show">
                            {{ $t("DataFieldsPage.WhereToShow") }}
                            <span id="where-to-show">
                                <InfoIcon class="info-icon" />
                            </span>
                        </div>
                        <Tooltip
                            target="where-to-show"
                            :text="$t('DataFieldsPage.WhereToShowText')"
                            placement="top"
                        />
                        <div
                            v-show="hasCheckedError"
                            class="required-check"
                        >
                            {{ $t("DataFieldsPage.SelectOne") }}
                        </div>
                        <div v-if="forceRenderList">
                            <div
                                v-for="option in exhibitionChecked"
                                :key="option.id"
                                :class="[
                                    'checkbox-block-options',
                                    hasCheckedError ? '--has-error' : ''
                                ]"
                            >
                                <div
                                    :class="[
                                        'checkbox-container-option',
                                        option.checked ? 'checked' : '',
                                        '__no-action-default'
                                    ]"
                                    @click="toggleCheckbox(option)"
                                >
                                    <span class="icon-checkbox"><CheckIcon /></span>
                                </div>
                                <div class="option-name">
                                    {{ $t(`DataFieldsPage.${option.name}`) }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </template>

            <template #footer>
                <div class="separator"></div>
                <b-col
                    md="12"
                    :class="['footer-sidebar', !isModalOptionsListOpen ? 'visible' : 'invisible']"
                >
                    <div :class="['d-flex', 'buttons']">
                        <b-button
                            :class="['btn-cancel', 'btn-footer']"
                            @click="onCancel()"
                        >
                            {{ $t("RefuseStatus.Cancel") }}
                        </b-button>
                        <b-button
                            class="btn-save btn-footer"
                            @click="onSave()"
                        >
                            {{ $t("RefuseStatus.Save") }}
                        </b-button>
                    </div>
                </b-col>
            </template>
        </b-sidebar>
    </div>
</template>

<script>
    import { mapState, mapMutations } from "vuex";
    import {
        BFormGroup,
        BFormInput,
        BFormTextarea,
        BFormCheckbox,
        BButton,
        BCol,
        BSidebar
    } from "bootstrap-vue";
    import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
    import Tooltip from "@/@core/layouts/layout-horizontal/components/Menu/Tooltip.vue";
    import ModalListOptions from "./ModalListOptions.vue";
    import ModalNewGroup from "./ModalNewGroup.vue";

    export default {
        data() {
            return {
                isOpen: false,
                forceRenderList: true,
                showSettingsButton: false,
                flagNameEqual: false,
                payloadGroup: null,
                exhibitionChecked: [],
                filteredGroupList: [],
                isToValidate: false,
                name: "",
                description: "",
                group: null,
                selectedGroup: null,
                format: null,
                required: false,
                important: false,
                hasCheckedError: false,
                states: { name: null, format: null }
            };
        },
        components: {
            BFormGroup,
            BFormInput,
            BFormTextarea,
            BFormCheckbox,
            BButton,
            BCol,
            BSidebar,
            ModalNewGroup,
            Tooltip,
            ModalListOptions,
            CloseIcon: () => import("@core/assets/icons/close-icon.svg"),
            CheckIcon: () => import("@core/assets/icons/check-icon.svg"),
            InfoIcon: () => import("@core/assets/icons/info-option-icon.svg"),
            SingleSelect: () => import("@core/components/multiselect/SingleSelect.vue")
        },
        props: {
            modalTitle: {
                type: String,
                default: ""
            },
            currentId: {
                type: Number,
                default: null
            },
            appointmentId: {
                type: Number,
                default: null
            },
            groupList: {
                type: Array,
                default: []
            },
            exhibitionLocation: {
                type: Array,
                default: []
            },
            device: {
                type: String,
                default: "desktop"
            },
            entityId: {
                type: Number,
                default: null
            },
            formatList: {
                type: Array,
                default: []
            }
        },
        mounted() {
            this.UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE(false);
        },
        methods: {
            ...mapMutations("app", ["UPDATE_FLAG_SHOW_OVERLAY"]),
            ...mapMutations("dataFields", [
                "UPDATE_MODAL_OPTIONS_LIST_TOGGLE",
                "UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE",
                "UPDATE_MODAL_NEW_GROUP_TOGGLE",
                "UPDATE_OPTIONS_LIST"
            ]),
            onCancel() {
                this.isToValidate = false;
                this.clearValidations();
                this.clearFields();
                this.allCheckedToFalse();
                this.closeModal();
            },
            closeModal() {
                this.format = null;
                this.UPDATE_OPTIONS_LIST([{ text: "" }, { text: "" }, { text: "" }]);
                this.UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE(false);
                this.UPDATE_MODAL_OPTIONS_LIST_TOGGLE(false);
            },
            newGroup() {
                this.UPDATE_MODAL_NEW_GROUP_TOGGLE(true);
            },
            showToast(title, icon, text, variant) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: title,
                        icon: icon,
                        text: text,
                        variant
                    }
                });
            },
            clearFields() {
                this.name = "";
                this.description = "";
                this.group = null;
                this.required = false;
                this.important = false;
                this.exhibitionChecked.map((e) => (e.checked = false));
            },
            clearValidations() {
                this.hasCheckedError = false;
                this.states = {
                    name: null,
                    format: null
                };
            },
            openListOptions() {
                this.UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE(false);
                this.UPDATE_MODAL_OPTIONS_LIST_TOGGLE(true);
            },
            allCheckedToFalse() {
                this.exhibitionChecked.map((e) => {
                    e.checked = false;
                });
            },
            toggleCheckbox(elem) {
                if (!elem.checked) {
                    elem["checked"] = true;
                    this.hasCheckedError = false;
                } else {
                    elem["checked"] = false;
                }
                this.forceRenderListToggle();
            },
            forceRenderListToggle() {
                this.forceRenderList = false;
                this.$nextTick().then(() => {
                    this.forceRenderList = true;
                });
            },
            fieldsValidate() {
                this.clearValidations();

                const showOptionsList =
                    this.format === 17 || this.format === 18 || this.format === 25;
                const options = this.optionsList.find((e) => e.text === "") ? true : false;

                if (
                    this.device === "mobile" &&
                    showOptionsList &&
                    (this.optionsList.length === 0 || options)
                ) {
                    this.UPDATE_MODAL_OPTIONS_LIST_TOGGLE(true);
                    this.UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE(false);
                    return false;
                }

                const hasCheckedItems =
                    this.entityId === this.appointmentId
                        ? this.exhibitionChecked.find((e) => e.checked)
                        : true;
                const formatValid = (this.states.format = this.format !== null);
                const validName = (this.states.name =
                    this.name && this.flagNameEqual === false ? true : false);

                if (!hasCheckedItems) {
                    this.hasCheckedError = true;
                }

                return hasCheckedItems && formatValid && validName;
            },
            async getInfos() {
                this.exhibitionChecked = this.exhibitionLocation;
                if (this.modalTitle.includes("Edit")) {
                    this.UPDATE_FLAG_SHOW_OVERLAY(true);
                    await this.$http2
                        .get(`/api/myconfig/industrial-parameter/${this.currentId}/details`)
                        .then((response) => {
                            this.name = response.data.data.name;
                            this.description = response.data.data.description;
                            this.important = response.data.data.important;
                            this.required = response.data.data.required;
                            this.group = response.data.data.industrialParameterGroupId;
                            this.format = response.data.data.industrialStructParameterTypeId;
                            this.exhibitionChecked = this.exhibitionLocation.map((item) => ({
                                ...item,
                                checked: response.data.data.exhibitionLocationIds.includes(item.id)
                                    ? true
                                    : false
                            }));
                            this.UPDATE_OPTIONS_LIST(
                                response.data.data.industrialParameterOption.map((e) => ({
                                    id: e.id,
                                    text: e.name
                                }))
                            );

                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        });
                }
            },
            newGroupCreated(payload) {
                this.payloadGroup = payload;

                const { name, ...restOfPayload } = payload;

                this.filteredGroupList.push({
                    text: name,
                    value: this.generateUniqueValue(),
                    origin: "front",
                    groupPayload: {
                        ...payload
                    }
                });

                this.selectedGroup = this.filteredGroupList.find(
                    (e) => e.text === payload.name
                ).value;
            },
            generateUniqueValue() {
                const existingValues = this.groupList.map((item) => item.value);
                let newValue;

                do {
                    newValue = Math.floor(Math.random() * 100) + 1;
                } while (existingValues.includes(newValue));

                return newValue;
            },
            async onSave() {
                this.flagNameEqual = false;
                this.isToValidate = true;

                const isValid = this.fieldsValidate();
                if (!isValid) return;
                this.UPDATE_FLAG_SHOW_OVERLAY(true);

                let groupCreatedId = null;

                const selectedGroup = this.filteredGroupList?.find((i) => i.value == this.group);

                if (selectedGroup?.origin === "front") {
                    await this.$http2
                        .post(
                            "/api/myconfig/industrial-parameter/group",
                            selectedGroup?.groupPayload ?? {}
                        )
                        .then((response) => {
                            groupCreatedId = response.data.data.id;
                        })
                        .catch((error) => {
                            this.showToast(this.$t("Error"), "FrownIcon", e.message, "danger");
                            this.fieldsValidate();
                        });
                }

                if (selectedGroup?.origin === "front" && !groupCreatedId?.toString()) {
                    this.UPDATE_FLAG_SHOW_OVERLAY(false);
                    return;
                }

                if (this.modalTitle.includes("Edit")) {
                    const payload = {
                        id: this.currentId,
                        name: this.name,
                        description: this.description,
                        groupId: selectedGroup?.origin === "front" ? groupCreatedId : this.group,
                        required: this.required,
                        important: this.important,
                        exhibitionLocation: this.exhibitionChecked
                            .map((e) => {
                                if (e.checked === true) return e.id;
                            })
                            .filter((e) => e),
                        industrialStructParameterOption: this.optionsList
                            .map((e) => ({ id: e.id ? e.id : null, name: e.text }))
                            .filter((text) => text !== "")
                    };

                    await this.$http2
                        .put(`/api/myconfig/industrial-parameter/${this.currentId}`, payload)
                        .then((response) => {
                            this.UPDATE_MODAL_OPTIONS_LIST_TOGGLE(false);
                            this.UPDATE_OPTIONS_LIST([{ text: "" }, { text: "" }, { text: "" }]);
                            this.showToast(
                                this.$t("IntegrationPage.success"),
                                "SmileIcon",
                                this.$t("DataFieldsPage.FieldEdited"),
                                "success"
                            );
                            this.$emit("updateList");
                            this.closeModal();
                            this.payloadGroup = null;
                            this.format = null;
                        })
                        .catch((error) => {
                            error.response.data.errors.map((e) => {
                                if (e.id === 99) {
                                    this.flagNameEqual = true;
                                } else {
                                    this.showToast(
                                        this.$t("Error"),
                                        "FrownIcon",
                                        e.message,
                                        "danger"
                                    );
                                }
                            });
                            this.fieldsValidate();
                            this.payloadGroup = savedPayloadGroup;
                        })
                        .finally(() => {
                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        });
                } else {
                    const payload = {
                        name: this.name,
                        description: this.description,
                        groupId: selectedGroup?.origin === "front" ? groupCreatedId : this.group,
                        industrialStructParameterTypeId: this.format,
                        required: this.required,
                        important: this.important,
                        industrialEntityId: this.entityId,
                        exhibitionLocation: this.exhibitionChecked
                            .map((e) => {
                                if (e.checked === true) return e.id;
                            })
                            .filter((e) => e),
                        industrialStructParameterOption: this.optionsList
                            .map((e) => ({ id: null, name: e.text }))
                            .filter((text) => text !== "")
                    };

                    await this.$http2
                        .post("/api/myconfig/industrial-parameter", payload)
                        .then((response) => {
                            this.UPDATE_MODAL_OPTIONS_LIST_TOGGLE(false);
                            this.UPDATE_OPTIONS_LIST([{ text: "" }, { text: "" }, { text: "" }]);
                            this.showToast(
                                this.$t("IntegrationPage.success"),
                                "SmileIcon",
                                this.entityId !== this.appointmentId
                                    ? this.$t("DataFieldsPage.CustomFieldCreated")
                                    : this.$t("DataFieldsPage.FieldCreated"),
                                "success"
                            );
                            this.$emit("updateList");
                            this.closeModal();
                            this.payloadGroup = null;
                            this.format = null;
                        })
                        .catch((error) => {
                            error.response.data.errors.map((e) => {
                                if (e.id === 99) {
                                    this.flagNameEqual = true;
                                } else {
                                    this.showToast(
                                        this.$t("Error"),
                                        "FrownIcon",
                                        e.message,
                                        "danger"
                                    );
                                }
                            });
                            this.fieldsValidate();
                            this.payloadGroup = savedPayloadGroup;
                        })
                        .finally(() => {
                            this.UPDATE_FLAG_SHOW_OVERLAY(false);
                        });
                }
            }
        },
        computed: {
            ...mapState("dataFields", {
                isModalAddDataFieldOpen: "isModalAddDataFieldOpen",
                isModalOptionsListOpen: "isModalOptionsListOpen",
                optionsList: "optionsList"
            })
        },
        watch: {
            isModalAddDataFieldOpen(v) {
                this.isOpen = v;
                this.clearFields();
                this.clearValidations();
                if (v) {
                    this.getInfos();
                }
                if (!v && this.device !== "mobile") {
                    this.payloadGroup = null;
                    this.$emit("updateList");
                }
            },
            format(v) {
                const showOptionsList = v === 17 || v === 18 || v === 25;
                const showSettingsButton = this.device === "mobile" && showOptionsList;
                this.UPDATE_MODAL_OPTIONS_LIST_TOGGLE(showOptionsList);
                if (this.device === "mobile") {
                    v === null
                        ? this.UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE(false)
                        : this.UPDATE_MODAL_ADD_DATA_FIELD_TOGGLE(!showOptionsList);
                }
                this.showSettingsButton = showSettingsButton;
            },
            selectedGroup(v) {
                if (v) this.group = v;
            },
            exhibitionLocation(v) {
                this.exhibitionChecked = v;
            },
            groupList(v) {
                this.filteredGroupList = v.filter((e) => e.industrialEntityId === this.entityId);
            }
        }
    };
</script>

<style lang="scss">
    .sidebar-data-field {
        &.--modal-openned {
            .b-sidebar.b-sidebar-right {
                right: 407px;
                transition: all 0.5s ease-out;
                @media (max-width: 814px) {
                    left: 0;
                    width: 50vw !important;
                }
                @media (max-width: 480px) {
                    left: 0;
                    width: 100vw !important;
                }
            }
        }
        .custom-select {
            cursor: pointer;
            padding: 4px 34px 4px 8px;
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%23CFC4BE' stroke='%23CFC4BE' stroke-width='0.5'/%3E%3C/svg%3E") !important;
            &[disabled] {
                border-radius: 5px;
                border: 1px solid #7e7570;
                background-color: #eee;
                background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='8' height='6' viewBox='0 0 8 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.17975 3.78813L7.05862 0.855236L7.64775 1.44457L4.00127 5.14393L0.352313 1.44451L0.941458 0.855158L3.823 3.7882L4.00141 3.96981L4.17975 3.78813Z' fill='%237E7570' stroke='%237E7570' stroke-width='0.5'/%3E%3C/svg%3E") !important;
                color: #7e7570 !important;
            }
        }

        .b-sidebar-header {
            display: contents;

            .header-sidebar {
                width: 100%;
                padding: 16px;

                .sidebar-title {
                    display: flex;
                    align-items: center;
                    color: #4c4541;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;

                    #info-option {
                        margin-left: 8px;
                        cursor: pointer;
                    }

                    @media (max-width: 480px) {
                        font-size: 16px;
                    }
                }

                .close-icon {
                    border: 1px solid #974900;
                    border-radius: 5px;
                    width: 28px;
                    height: 28px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    cursor: pointer;

                    svg {
                        width: 12px;
                        height: 12px;
                        path {
                            fill: #974900;
                        }
                    }

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }
            }

            .separator {
                width: 100%;
                height: 1px;
                background: #cfc4be;
            }
        }

        .b-sidebar {
            box-shadow: 0px 4px 24px 0px rgba(58, 46, 38, 0.1);
            transition: all 0.5s ease-out;
            height: 100% !important;
            .b-sidebar-body {
                padding: 16px 0;

                .single-select {
                    padding-bottom: 16px;
                }

                .btn-config {
                    width: 100%;
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    padding: 8px 21px;
                    margin-bottom: 16px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                .custom-switch-button {
                    padding: 0 16px 16px;

                    .custom-switch {
                        display: flex;
                    }

                    .custom-control-label {
                        margin: 0;
                        line-height: 20px;
                    }

                    .custom-control-input ~ .custom-control-label::before {
                        background-color: #e2e2e2;
                        height: 20px;
                    }

                    .custom-control-input:checked ~ .custom-control-label::before {
                        background-color: #974900;
                    }

                    .custom-control-input:not(:disabled):active ~ .custom-control-label::before {
                        background-color: #e2e2e2;
                        border-color: #e2e2e2;
                    }
                    .custom-switch .custom-control-label:after {
                        top: 3px;
                    }

                    .text-allow {
                        color: #4c4541;
                        font-size: 14px;
                        font-weight: 400;
                        line-height: 20px;
                        padding-right: 8px;
                    }

                    .info-icon {
                        cursor: pointer;
                        width: 16px;
                        height: 16px;
                        fill: #998f8a;
                    }
                }

                .where-to-show {
                    color: #4c4541;
                    font-size: 16px;
                    font-weight: 600;
                    padding: 0 16px 16px;

                    .info-icon {
                        cursor: pointer;
                        width: 16px;
                        height: 16px;
                        fill: #998f8a;
                    }
                }
                .required-check {
                    padding: 16px;
                    display: flex;
                    align-items: center;
                    border-radius: 6px;
                    border: 1px solid #b71c1c;
                    background: #ffebee;
                    margin: 0 16px 16px;
                    color: #b71c1c;
                    font-size: 14px;
                    font-weight: 400;
                }
                .checkbox-block-options {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 6.5px 16px;
                    border-bottom: 1px solid #eee;
                    .checkbox-container-option {
                        display: flex !important;
                        background-color: #fff;
                        border: 1px solid #cfc4be;
                        border-radius: 3px;
                        cursor: pointer;
                        width: 18px;
                        height: 18px;

                        .icon-checkbox {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            margin-top: 2px;
                            margin-left: 2px;
                            svg {
                                width: 12px;
                                height: 12px;
                            }
                        }

                        &.checked {
                            background-color: #974900;
                            display: block;
                        }
                    }

                    .option-name {
                        color: #998f8a;
                        font-size: 14px;
                        font-weight: 600;
                    }

                    &.--has-error {
                        .checkbox-container-option {
                            border-color: #d32f2f;
                            background-color: #ffebee;
                        }
                    }
                }

                .btn-add-option {
                    display: flex;
                    gap: 6px;
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid transparent !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                        box-shadow: none;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                .form-group {
                    .form-control.is-invalid,
                    .form-control.is-valid {
                        background-image: none !important;
                    }
                    .form-control {
                        &::placeholder {
                            color: #cfc4be;
                        }
                        &:focus {
                            border-color: #974900;
                        }
                    }
                }

                &::-webkit-scrollbar {
                    display: none !important;
                }
            }
        }

        .separator {
            width: 100%;
            height: 1px;
            background: #cfc4be;
        }

        .footer-sidebar {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            padding: 16px;

            .buttons,
            .edit-buttons {
                gap: 8px;
            }

            @media (max-width: 480px) {
                justify-content: center;
                .buttons {
                    width: 100%;

                    .btn {
                        width: 50%;
                    }
                }

                .btn-footer {
                    font-size: 12px !important;
                    padding: 3px 21px !important;
                }
            }

            .btn-footer {
                box-shadow: none;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                border-radius: 5px;

                &:hover {
                    box-shadow: none;
                }

                &.btn-cancel {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-previous,
                &.btn-cancel.btn-cancel-outline {
                    background-color: transparent !important;
                    color: #974900 !important;
                    border: 1px solid #974900 !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #ffede2 !important;
                    }

                    &:active {
                        background-color: #ffdbc4 !important;
                    }
                }

                &.btn-save {
                    border: 1px solid #974900 !important;
                    background-color: #974900 !important;
                    color: #ffff !important;
                    padding: 8px 21px;

                    &:hover {
                        background-color: #9f5714 !important;
                    }

                    &:active {
                        background-color: #a45f1f !important;
                    }
                }
            }
        }
    }
</style>
